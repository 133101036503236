import React from 'react';
import { FormLayout, Section, Heading } from '@piggybank/core';
import Page from '../../components/Page';

const NotFound = () => (
  <Page>
    <FormLayout>
      <Section>
        <Heading level={1}>404&#39;d!</Heading>
      </Section>
    </FormLayout>
  </Page>
);

NotFound.displayName = 'NotFound';

export default NotFound;
