import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Section,
  Heading,
  Paragraph,
  Link,
  Loading,
  IconStatusInformationOnlight,
  FormLayout,
  OrderedList,
  ListItem
} from '@piggybank/core';
import {
  Fieldset,
  Form,
  IconTile,
  TileGroup,
  Field,
  Checkbox,
  FieldFeedback,
} from '@piggybank/form';
import { WithI18nProgressIndicator } from 'components/WithI18nPiggybank';
import { ButtonWithBack } from 'components/ButtonWithBack';
import { declaration as declarationList } from 'referenceData';
import { userFetch, rePrevious } from 'utils/util';
import { getUserSessionDataByKey, setUserSessionDataByKey } from 'storage/userStorage';
import './custom.css';
import { checkBrowser } from "../../../utils/browser";
import email from 'assets/image/email.svg';
import call from 'assets/image/call.svg';
import Post from 'assets/image/Post.png';
import SMS from 'assets/image/SMS.png';
import { useTagging, linkTagging, globalViewTaggingParams, loadTagging } from 'tealiumTrack';

const iconSet = [
  email,
  Post,
  call,
  SMS
];

const TAG_PAGE_URL = '/forms/declaration';
const TAG_PAGE_NAME = 'pws:forms:declaration';

const Declaration = () => {
  const initialValues = getUserSessionDataByKey('declaration');
  const { isPremier, enableCreditCard, lang } = getUserSessionDataByKey('global');
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const browser = checkBrowser();
  const history = useHistory();
  const clickLoading = () => {
    setLoading(true);
    loadTagging({
      ...globalViewTaggingParams,
      'page_url': '/forms/processing',
      'page_language': lang,
      'page_name': 'pws:forms:processing',
      'page_subcategory': 'processing',
      'raw_datalayer': '5745v27',
      'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
      'funnel_step': '8',
      'funnel_step_name': 'processing',
      'application_event': 'submitted'
    });
    userFetch()
      .then(() => {
        setLoading(false);
        history.push('/happens');
      }).catch(() => {
        setLoading(false);
        history.push('/technicals');
      })
  }

  const openPDF = (values) => {
    setUserSessionDataByKey('declaration', values);
    if (!browser.isUCBrowser && !browser.isQQBrowser && !browser.isIEBrowser) {
      window.open(t('ICO.DECLARATION.PDF1'));
    } else {
      window.open(t('ICO.DECLARATION.PDF1'), !browser.isIEBrowser ? '_self' : '_blank');
    }

    linkTagging({
      'page_url': TAG_PAGE_URL,
      'page_language': lang,
      'event_category': 'content',
      'event_action': 'onsite',
      'event_content': 'terms and conditions',
      'page_name': TAG_PAGE_NAME,
      'raw_datalayer': '5745v25'
    });
  };

  useTagging({
    'page_url': TAG_PAGE_URL,
    'page_language': lang,
    'page_name': TAG_PAGE_NAME,
    'page_subcategory': 'declaration',
    'raw_datalayer': '5745v22',
    'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
    'funnel_step': '7',
    'funnel_step_name': 'declaration'
  });
  return (
    <>
      <Loading show={loading} getApplicationNode={() => document.getElementById('root')} >
        <Heading level={3}>
          {t('ICO.HAPPENS.PROCESSING')}
        </Heading>
        <Paragraph marginBottom={0}>
          {t('ICO.HAPPENS.BACK')}
        </Paragraph>
      </Loading>
      <Form
        initialValues={initialValues}
        onSubmit={({ values }) => {
          setUserSessionDataByKey('declaration', values);
          clickLoading()
        }}
        onChange={rePrevious}
      >
        {({ values, setFieldValue }) => {
          const { preferredChannels, dontwant } = values;
          return (
            <FormLayout>
              <WithI18nProgressIndicator current={isPremier && enableCreditCard ? 7 : 6} />
              <Section>
                <Heading level={1}>
                  {t('ICO.DECLARATION.DECLARATIONS')}
                </Heading>
              </Section>
              <Section>
                <Paragraph className='Declaration-left'>
                  {t('ICO.DECLARATION.DECLARE')}
                </Paragraph>
                <OrderedList>
                  {/***1***/}
                  <ListItem>
                    {t('ICO.DECLARATION.DECLAREALLLISTA')}
                    {isPremier ? t('ICO.TYPE.PREMIER_TITLE') : t('ICO.TYPE.ONE_TITLE')}
                    {t('ICO.DECLARATION.DECLAREALLLISTA1')}
                  </ListItem>
                  {/***2***/}
                  <ListItem>
                    {t('ICO.DECLARATION.DECLAREALLLISTB')}
                  </ListItem>
                  {/***3***/}
                  <ListItem>
                    {t('ICO.DECLARATION.DECLAREALLLISTC')}
                    <Link
                      buttonPadding={false}
                      elementType="a"
                      external
                      onClick={() => openPDF(values)}
                      strong={false}
                      textMap={{
                        newWindow: ''
                      }}
                    >
                      {t('ICO.DECLARATION.DECLAREALLLISTCLIKE')}
                    </Link>
                  </ListItem>
                  {/***Premier 4 only***/}
                  {isPremier && <ListItem>
                    {t('ICO.DECLARATION.DATA')}
                    <Link
                      buttonPadding={false}
                      elementType="a"
                      external
                      href={t('ICO.DECLARATION.PDF3')}
                      strong={false}
                      textMap={{
                        newWindow: ''
                      }}
                    >
                      {t('ICO.DECLARATION.DATALIKE')}
                    </Link>
                    {t('ICO.DECLARATION.DATA9')}
                    <Link
                      buttonPadding={false}
                      elementType="a"
                      external
                      href={t('ICO.DECLARATION.PDF4')}
                      strong={false}
                      textMap={{
                        newWindow: ''
                      }}
                    >
                      {t('ICO.DECLARATION.DATA2')}
                    </Link>
                    {t('ICO.DECLARATION.DATA3')}
                    <Link
                      buttonPadding={false}
                      elementType="a"
                      external
                      href={t('ICO.DECLARATION.PDF5')}
                      strong={false}
                      textMap={{
                        newWindow: ''
                      }}
                    >
                      {t('ICO.DECLARATION.DATA4')}
                    </Link>
                    {t('ICO.DECLARATION.DATA5')}
                  </ListItem>
                  }
                  {/***One 4, Premier 5***/}
                  <ListItem>
                    <Paragraph>{t('ICO.DECLARATION.DECLARE_O4P5')}</Paragraph>
                    {t('ICO.DECLARATION.CHANNELS')}
                    <OrderedList type="lower-alpha">
                      <ListItem>{t('ICO.DECLARATION.CHANNELS1')}</ListItem>
                      <ListItem>{t('ICO.DECLARATION.CHANNELS2')}</ListItem>
                      <ListItem>{t('ICO.DECLARATION.CHANNELS3')}</ListItem>
                      <ListItem>{t('ICO.DECLARATION.CHANNELS4')}</ListItem>
                    </OrderedList>
                    {t('ICO.DECLARATION.CHANNELS_REMIND')}<Link
                      buttonPadding={false}
                      elementType="a"
                      external
                      strong={false}
                      textMap={{ newWindow: '' }}
                      href={'https://' + t('ICO.DECLARATION.CHANNELS_REMIND_LINK')
                      }>{t('ICO.DECLARATION.CHANNELS_REMIND_LINK')}</Link>{lang === 'en' ? '.' : '。'}
                  </ListItem>
                  {/***One 5, Premier 6***/}
                  <ListItem>
                    {t('ICO.DECLARATION.DATA6')}
                    <Link
                      buttonPadding={false}
                      elementType="a"
                      external
                      href={t('ICO.DECLARATION.PDF2')}
                      strong={false}
                      textMap={{
                        newWindow: ''
                      }}
                      onClick={() => {
                        linkTagging({
                          'page_url': TAG_PAGE_URL,
                          'page_language': lang,
                          'event_category': 'content',
                          'event_action': 'onsite',
                          'event_content': 'data privacy notice',
                          'page_name': TAG_PAGE_NAME,
                          'raw_datalayer': '5745v26'
                        });
                      }}
                    >
                      {t('ICO.DECLARATION.DATA7')}
                    </Link>
                    {t('ICO.DECLARATION.DATA8')}
                  </ListItem>
                </OrderedList>

                <Paragraph style={{ display: 'flex' }}>
                  <span
                    style={{
                      color: 'var(--sapphire)',
                      marginTop: '3px'
                    }}
                  >
                    <IconStatusInformationOnlight />
                  </span>
                  <span style={{ marginLeft: '7px' }}>{t('ICO.DECLARATION.MAKESURE')}</span>
                </Paragraph>
                <Heading level={2} accentBar>
                  {t('ICO.DECLARATION.DIRECT')}
                </Heading>
                <Paragraph>{t('ICO.DECLARATION.HOW')}</Paragraph>
                {!dontwant &&
                  <Fieldset
                    name='preferredChannels'
                    onChange={(next, { value, ...rest }) => {
                      setFieldValue('preferredChannels', value);
                      linkTagging({
                        'page_url': TAG_PAGE_URL,
                        'page_language': lang,
                        'event_category': 'content',
                        'event_action': 'checkbox',
                        'event_content': `direct marketing: ${value.join(',')}`,
                        'page_name': TAG_PAGE_NAME,
                        'raw_datalayer': '5745v23'
                      });
                      next({ value, ...rest });
                    }}
                  >
                    {declarationList.map((item, i) => (
                      <TileGroup key={i} marginBottom={4}>
                        <IconTile value={item.value} icon={() => {
                          return <img alt='' src={iconSet[i]} style={{ width: 24, height: 24 }} />
                        }}>
                          {item[lang]}
                        </IconTile>
                      </TileGroup>
                    ))}
                  </Fieldset>}
                <Field name="dontwant"
                  onChange={(next, { value, ...rest }) => {
                    if (value) {
                      linkTagging({
                        'page_url': TAG_PAGE_URL,
                        'page_language': lang,
                        'event_category': 'content',
                        'event_action': 'checkbox',
                        'event_content': 'I prefer not to receive marketing or promotional materials',
                        'page_name': TAG_PAGE_NAME,
                        'raw_datalayer': '5745v24'
                      });
                    }
                    next({ value, ...rest });
                  }}
                >
                  <Checkbox disabled={preferredChannels.length}>
                    {t('ICO.DECLARATION.DONTWANT')}
                  </Checkbox>
                  <FieldFeedback />
                </Field>
              </Section>
              <Section>
                <Paragraph marginBottom={5}>
                  {t('ICO.DECLARATION.BANKEARLIER')}
                </Paragraph>
                <Paragraph>
                  {t('ICO.DECLARATION.MARKETING')}
                </Paragraph>
              </Section>
              <ButtonWithBack
                isSubmit={true}
                isSubmitDisabled={!dontwant && !preferredChannels.length}
                onClick={() => {
                  setLoading(true);
                }} />
            </FormLayout>
          )
        }}
      </Form >
    </>
  );
}

export default Declaration;
