

export const relativeData = {
    zh_CN: {
        relative: [
            {
                value: 'Parent, incl step-parent or adoptive parent',
                label: '父母，包括继父母或领养父母'
            },
            {
                value: 'Grandparent or great grandparent',
                label: '祖父母或外祖父母、或曾祖父母或外曾祖父母'
            },
            {
                value: 'Siblings',
                label: '兄弟或姐妹'
            },
            {
                value: 'Spouse',
                label: '配偶'
            },
            {
                value: 'Party to union of concubinage',
                label: '夫妾关系的一方'
            },
            {
                value: 'Cohabitee',
                label: '同居伴侣'
            },
            {
                value: "Spouse's parent, incl step-parent or adoptive parent",
                label: '配偶的父母，包括继父母或领养父母'
            },
            {
                value: "Spouse's siblings",
                label: '配偶的兄弟姐妹'
            },
            {
                value: 'Child, incl step child or adopted child',
                label: '子女，包括继子女或领养子女'
            },
            {
                value: 'Grandchild or great grandchild',
                label: '孙、外孙、曾孙或外曾孙'
            },
            {
                value: 'Other family member',
                label: '其他家族成员'
            },
        ]
    },
    zh_HK: {
        relative: [
            {
                value: 'Parent, incl step-parent or adoptive parent',
                label: '父母，包括繼父母或領養父母'
            },
            {
                value: 'Grandparent or great grandparent',
                label: '祖父母或外祖父母、或曾祖父母或外曾祖父母'
            },
            {
                value: 'Siblings',
                label: '兄弟或姐妹'
            },
            {
                value: 'Spouse',
                label: '配偶'
            },
            {
                value: 'Party to union of concubinage',
                label: '夫妾關係的一方'
            },
            {
                value: 'Cohabitee',
                label: '同居伴侶'
            },
            {
                value: "Spouse's parent, incl step-parent or adoptive parent",
                label: '配偶的父母，包括繼父母或領養父母'
            },
            {
                value: "Spouse's siblings",
                label: '配偶的兄弟姊妹'
            },
            {
                value: 'Child, incl step child or adopted child',
                label: '子女，包括繼子女或領養子女'
            },
            {
                value: 'Grandchild or great grandchild',
                label: '孫、外孫、曾孫或外曾孫'
            },
            {
                value: 'Other family member',
                label: '其他家族成員'
            },
        ]
    },
    en: {
        relative: [
            {
                value: 'Parent, incl step-parent or adoptive parent',
                label: 'Parent, incl step-parent or adoptive parent',
            },
            {
                value: 'Grandparent or great grandparent',
                label: 'Grandparent or great grandparent'
            },
            {
                value: 'Siblings',
                label: 'Siblings'
            },
            {
                value: 'Spouse',
                label: 'Spouse'
            },
            {
                value: 'Party to union of concubinage',
                label: 'Party to union of concubinage'
            },
            {
                value: 'Cohabitee',
                label: 'Cohabitee'
            },
            {
                value: "Spouse's parent, incl step-parent or adoptive parent",
                label: "Spouse's parent, incl step-parent or adoptive parent"
            },
            {
                value: "Spouse's siblings",
                label: "Spouse's siblings"
            },
            {
                value: 'Child, incl step child or adopted child',
                label: 'Child, incl step child or adopted child'
            },
            {
                value: 'Grandchild or great grandchild',
                label: 'Grandchild or great grandchild'
            },
            {
                value: 'Other family member',
                label: 'Other family member'
            },
        ]
    }
}