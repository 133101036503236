import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProductBasket from '../../components/ProdectBasket';
import Page from "../../components/Page";
import {
    Section,
    Heading,
    Paragraph,
    IconStatusInformationOnlight,
    FormLayout,
    Link,
    Loading
} from '@piggybank/core';
import { linkTagging, useTagging } from 'tealiumTrack';
import { timeDiffMaintenance } from "../../utils/date";

const TAG_CONTENT = 'service temporarily unavailable';

const Maintenance = () => {
    const { t, i18n } = useTranslation();
    const lan = i18n.language;
    const maintenanceData = window.maintenanceContext;
    const M = timeDiffMaintenance(maintenanceData?.startDate, maintenanceData?.endDate);
    const startDate = M.startFormatStr;
    const endDate = M.endFormatStr;
    const [loading] = useState(false);
    const isPremier = sessionStorage.getItem('AC_TYPE') === 'PREMIER';
    useEffect(() => {
        const timerId = setInterval(() => {
            const getMaintenance = timeDiffMaintenance(maintenanceData?.startDate, maintenanceData?.endDate);
            if (!getMaintenance.status) {
                window.location.href = '/ico-webform/';
            }
        }, 2000);

        return () => clearInterval(timerId);
    });

    useTagging({
        'page_url': '/forms/service-temporarily-unavailable',
        'page_language': lan,
        'event_category': 'error',
        'event_subcategory': 'maintenance',
        'event_action': 'system',
        'event_content': TAG_CONTENT,
        'page_type': 'error',
        'page_name': 'pws:forms:service temporarily unavailable',
        'page_subcategory': TAG_CONTENT,
        'raw_datalayer': '5745v29',
        'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
        'funnel_step_name': TAG_CONTENT
    });

    return (
        <>
            <Loading show={loading} getApplicationNode={() => document.getElementById('root')}>
                <Heading level={3}>
                    {t('ICO.HAPPENS.PROCESSING')}
                </Heading>
                <Paragraph marginBottom={0}>
                    {t('ICO.HAPPENS.BACK')}
                </Paragraph>
            </Loading>
            <Page hasMargin={0}>
                <ProductBasket />
                <FormLayout>
                    <Section marginBottom={3}>
                        <div
                            style={{
                                color: 'var(--sapphire)'
                            }}
                        >
                            <IconStatusInformationOnlight width="2.5em" />
                        </div>
                        <Heading level={1}>
                            {t('ICO.MAINTENANCE.TITLE')}
                        </Heading>
                        <Paragraph>
                            {t(maintenanceData.description[lan], {startDate, endDate})}
                        </Paragraph>
                    </Section>
                    <Section>
                        <Heading
                            accentBar
                            level={2}
                        > {t('ICO.HAPPENS.CAN')}
                        </Heading>
                        <Paragraph>
                            {t('ICO.HAPPENS.US')}
                        </Paragraph>
                        <Paragraph>
                            {t('ICO.HAPPENS.CAN1')}
                        </Paragraph>
                        <Paragraph>
                            <Link
                                href="mailto:subject&body= mail body"
                                iconSlotLeft={<span style={{ color: 'var(--red)' }}></span>}
                                onClick={() => {
                                    linkTagging({
                                        'page_url': '/forms/service-temporarily-unavailable',
                                        'page_language': lan,
                                        'event_category': 'content',
                                        'event_action': 'onsite',
                                        'event_content': 'international onboarding',
                                        'page_name': 'pws:forms:service temporarily unavailable',
                                        'raw_datalayer': '5745v30',
                                    });
                                }}
                            >{t('ICO.HAPPENS.INTER')}</Link>
                        </Paragraph>
                        <Paragraph>
                            {t('ICO.HAPPENS.FEW1')}
                        </Paragraph>
                    </Section>
                </FormLayout>
            </Page>
        </>
    );
}
Maintenance.displayName = 'Maintenance';
export default Maintenance;
