import React, { useEffect, useState } from 'react';
import { extendUserSessionData, getUserSessionData, getUserSessionDataByKey } from 'storage/userStorage';
import { initFormData } from 'storage/page';
import TimeoutDialog from 'components/TimeoutDialog';
import { useHistory } from 'react-router-dom';
import { rePrevious } from 'utils/util';
import { timeDiffMaintenance } from "../../utils/date";

const ProviderTime = ({ children }) => {
    const [show, setShow] = useState(false);
    const history = useHistory();

    const retimer = () => {
        const maintenanceData = window.maintenanceContext;
        const getMaintenance = maintenanceData && timeDiffMaintenance(maintenanceData?.startDate, maintenanceData?.endDate);
        if ((JSON.stringify(getUserSessionData()) === '{}' || !getUserSessionDataByKey('global').lang) && !getMaintenance?.status) {
            if (history.location.pathname === '/') {
                extendUserSessionData(initFormData);
            } else {
                history.replace('/')
            }
        }
        rePrevious();
    }

    useEffect(() => {
        retimer();

        const unlisten = history.listen(() => {
            retimer();
        })

        const calculator = setInterval(() => {
            if (JSON.stringify(getUserSessionData()) === '{}' || !getUserSessionDataByKey('global').lang) {
                return;
            }
            const previous = getUserSessionDataByKey('previous');
            const now = +new Date();
            if (now - previous > 590000) {
                setShow(true);
            }
        }, 2000);

        return () => {
            clearInterval(calculator);
            unlisten();
        }
    }, [history]);

    const retime = () => {
        setShow(false);
        rePrevious();
    }
    return (
        <>
            {children}
            {show && <TimeoutDialog continueApply={retime} />}
        </>
    )
};

export default ProviderTime;
