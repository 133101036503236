import React from "react";
import {
    FormLayout,
    Heading,
    HorizontalRule,
    Section,
    UnorderedList,
    ListItem,
    Paragraph
} from "@piggybank/core";
import ProductBasket from "../../../components/ProdectBasket";
import {useTranslation} from "react-i18next";
import Page from "../../../components/Page";
import {useHistory} from "react-router-dom";
import { ButtonWithBack } from "components/ButtonWithBack";
import { useTagging } from "tealiumTrack";
import { getUserSessionDataByKey } from "storage/userStorage";

const Welcome = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { isPremier, lang } = getUserSessionDataByKey('global');

    useTagging({
        'page_url': '/forms/start-your-application',
        'page_type': 'application',
        'page_language': lang,
        'page_name': 'pws:forms:start your application',
        'page_subcategory': 'start your application',
        'raw_datalayer': '5745v4',
        'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
        'funnel_step': '1',
        'funnel_step_name': 'start your application',
        'application_event': 'started'
    });

    return (
        <Page hasMargin={0}>
            <ProductBasket/>
            <FormLayout>
                <Heading level={1}>{t('ICO.WELCOME.TITLE')}</Heading>
                <HorizontalRule/>
                <Section>
                    <Heading level={2} accentBar>{t('ICO.WELCOME.ITEM')}</Heading>
                    <UnorderedList>
                        <ListItem>{t('ICO.WELCOME.ITEM_TXT1')}</ListItem>
                        <ListItem>{t('ICO.WELCOME.ITEM_TXT2')}</ListItem>
                    </UnorderedList>
                </Section>
                <Section>
                    <Heading level={2} accentBar>{t('ICO.WELCOME.NODE')}</Heading>
                    <Paragraph>{t('ICO.WELCOME.NODE_TXT')}</Paragraph>
                </Section>
                <ButtonWithBack onClick={() => {
                    history.push('/info/personal');
                }}/>
            </FormLayout>
        </Page>
    );
};

export default Welcome;
