import React from 'react';
import * as yup from 'yup';
import {
  Section,
  Heading,
  Paragraph,
  HorizontalRule,
  Reveal,
  Callout,
  VisuallyHidden,
  FormLayout,
} from '@piggybank/core';
import {
  Form,
  FormFeedback,
  Field,
  Fieldset,
  Checkbox,
  FieldFeedback,
  Label,
  TextArea,
  Hint,
  Select,
  TextInput,
  YesNoRadio,
  CurrencyInput,
  AddAnother,
} from '@piggybank/form';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { accountPurpose as useAccountList, Country, funding } from 'referenceData';
import { WithI18nProgressIndicator } from 'components/WithI18nPiggybank';
import { useHistory } from 'react-router-dom';
import './custom.css';
import { ButtonWithBack } from 'components/ButtonWithBack';
import { setUserSessionDataByKey, getUserSessionDataByKey } from 'storage/userStorage';
import { rePrevious, formatFormCheckboxArray } from 'utils/util';
import { linkTagging, validationTagging, useTagging } from 'tealiumTrack';

const SPECIAL_ERROR = 'ICO.COMMON.SPECIAL_ERROR';
const TAG_PAGE_URL = '/forms/using-your-account';
const TAG_PAGE_NAME = 'pws:forms:using your account';

const schema = yup.object().shape({
  selectAccountPurpose: yup.object().test(
    'selectAccountPurpose',
    () => i18n.t('ICO.USING.USE_ACCOUNT_ERROR_MESSAGE'),
    values => {
      for (let key in values) {
        if (values[key]) {
          return true;
        }
      }
      return false;
    }
  ),
  reasonForAccountOpening: yup.string().when('selectAccountPurpose', {
    is: val => val.OTHER,
    then: yup.string()
      .required(() => i18n.t('ICO.USING.USE_ACCOUNT_ERROR_MESSAGE_ENTER'))
      .max(60, ({ max }) => i18n.t('ICO.USING.USE_ACCOUNT_ERROR_MESSAGE_LT', { count: max }))
      .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR))
  }),
  regularFundsDetailsObj: yup.object().test(
    'regularFundsDetailsObj',
    () => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE'),
    values => {
      for (let key in values) {
        if (values[key].value) {
          return true;
        }
      }
      return false;
    }
  ).shape({
    You: yup.object().shape({
      children: yup.object().when('value', {
        is: true,
        then: yup.object().test(
          'regularFundsDetailsObj.You',
          () => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE_YOU'),
          values => {
            for (let key in values) {
              if (values[key].value) {
                return true;
              }
            }
            return false;
          }
        )
      })
    }),
    'A third party': yup.object().shape({
      children: yup.object().when('value', {
        is: true,
        then: yup.object().test(
          'regularFundsDetailsObj.third',
          () => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE_THIRD'),
          values => {
            for (let key in values) {
              if (values[key].value) {
                return true;
              }
            }
            return false;
          }
        ).shape({
          'TRANSFER_FROM_3RD_PARTY_ACCOUNT': yup.object().shape({
            depositAmount: yup.string().when('country', {
              is: val => val.length > 0,
              then: yup.string()
                .required(() => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE_AMOUNT'))
                .test(
                  'regularFundsDetailsObj.depositAmount',
                  () => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE_AMOUNT_ST'),
                  val => !val || val > 0
                )
            }),
            country: yup.array().of(
              yup.string().required(() => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE_COUNTRY'))
            )
          }),
          'ANOTHER_SOURCE': yup.object().shape({
            other: yup.string().when('value', {
              is: true,
              then: yup.string()
                .required(() => i18n.t('ICO.USING.THIRD_AMOUNT_ERROR_MESSAGE'))
                .max(300, ({ max }) => i18n.t('ICO.USING.THIRD_AMOUNT_ERROR_MESSAGE_LT', { count: max }))
                .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR)),
            })
          })
        })
      })
    })
  }),
  sourceSame: yup
    .string()
    .matches(/(yes|no)/)
    .required(() => i18n.t('ICO.USING.SOURCE_SAME_ERROR_MESSAGE')),

  firstDepositDetailsObj: yup.object().when('sourceSame', {
    is: val => val === 'no',
    then: yup.object().test(
      'firstDepositDetailsObj',
      () => i18n.t('ICO.USING.FIRST_DEPOSIT_SOURCE_ERROR_MESSAGE'),
      values => {
        for (let key in values) {
          if (values[key].value) {
            return true;
          }
        }
        return false;
      }
    ).shape({
      You: yup.object().shape({
        children: yup.object().when('value', {
          is: true,
          then: yup.object().test(
            'firstDepositDetailsObj.own',
            () => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE_YOU'),
            values => {
              for (let key in values) {
                if (values[key].value) {
                  return true;
                }
              }
              return false;
            }
          )
        })
      }),
      'A third party': yup.object().shape({
        children: yup.object().when('value', {
          is: true,
          then: yup.object().test(
            'firstDepositDetailsObj.third',
            () => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE_THIRD'),
            values => {
              for (let key in values) {
                if (values[key].value) {
                  return true;
                }
              }
              return false;
            }
          ).shape({
            'TRANSFER_FROM_3RD_PARTY_ACCOUNT': yup.object().shape({
              depositAmount: yup.string().when('country', {
                is: val => val.length > 0,
                then: yup.string()
                  .required(() => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE_AMOUNT'))
                  .test(
                    'firstDepositDetailsObj.depositAmount',
                    () => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE_AMOUNT_ST'),
                    val => !val || val > 0
                  )
              }),
              country: yup.array().of(
                yup.string().required(() => i18n.t('ICO.USING.MONTHLY_FUNDS_ERROR_MESSAGE_COUNTRY'))
              )
            }),
            'ANOTHER_SOURCE': yup.object().shape({
              other: yup.string().when('value', {
                is: true,
                then: yup.string()
                  .required(() => i18n.t('ICO.USING.THIRD_AMOUNT_ERROR_MESSAGE'))
                  .max(300, ({ max }) => i18n.t('ICO.USING.THIRD_AMOUNT_ERROR_MESSAGE_LT', { count: max }))
                  .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR))
              })
            })
          })
        })
      })
    }),
  }),
  receivingMoney: yup.string()
    .matches(/(yes|no)/)
    .required(() => i18n.t('ICO.USING.RECEIVING_MONEY_ERROR_MESSAGE')),
  approximateDepositAmount: yup.string().when('receivingMoney', {
    is: val => val === 'yes',
    then: yup.string()
      .required(() => i18n.t('ICO.USING.MONTHLY_PAYMENTS_ERROR_MESSAGE'))
      .test(
        'approximateDepositAmount',
        () => i18n.t('ICO.USING.MONTHLY_PAYMENTS_ERROR_MESSAGE_ST'),
        val => !val || val > 0
      )
  }),
});

const fundsItems = {
  FUNDS: 'regularFundsDetailsObj',
  FIRST: 'firstDepositDetailsObj'
}

const UsingAccount = () => {
  const initialValues = getUserSessionDataByKey('using');
  const { isPremier, enableCreditCard } = getUserSessionDataByKey('global');
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const lan = i18n.language;
  const countryList = Country[lan];

  useTagging({
    'page_url': TAG_PAGE_URL,
    'page_language': lan,
    'page_name': TAG_PAGE_NAME,
    'page_subcategory': 'using your account',
    'raw_datalayer': '5745v17',
    'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
    'funnel_step': '5',
    'funnel_step_name': 'using your account'
  });

  const taggingParams = {
    'page_url': TAG_PAGE_URL,
    'page_language': lan,
    'event_category': 'error',
    'event_action': 'field validation',
    'page_name': TAG_PAGE_NAME,
    'raw_datalayer': '5745v19'
  };

  const getUseAccountList = (value) => {
    let label = '';
    switch (value) {
      case 'HOUSEHOLD_EXPENSES':
        label = `${t('ICO.USING.USE_ACCOUNT1')}`
        break;
      case 'PERSONAL_EXPENSES':
        label = `${t('ICO.USING.USE_ACCOUNT2')}`
        break;
      case 'PERSONAL_PURCHASES':
        label = `${t('ICO.USING.USE_ACCOUNT3')}`
        break;
      case 'MAJOR_PURCHASE':
        label = `${t('ICO.USING.USE_ACCOUNT4')}`
        break;
      default:
        break;
    }
    return label;
  }

  const FundsSource = (
    path, values, setFieldValue, i18nText) => (<Fieldset name={path}>
      <Label>{t(i18nText)}</Label>
      <Hint>{t('ICO.COMMON.SELECT_REMIND')}</Hint>
      {
        funding.map((item) => (
          <div key={`${path}.${item.value} `}>
            <Field
              marginBottom={0}
              name={`${path}.${item.value}.value`}
              onChange={(next, { value, ...rest }) => {
                if (!value) {
                  const childrenItem = values[path][item.value].children;
                  for (let key in childrenItem) {
                    childrenItem[key].value = false;
                    if (childrenItem[key].country) {
                      childrenItem[key].country.splice(0);
                      let currentFundsItem = fundsItems.FIRST;
                      if (path === fundsItems.FIRST) {
                        currentFundsItem = fundsItems.FUNDS;
                      }
                      setFieldValue(`${currentFundsItem}.A third party.children.TRANSFER_FROM_3RD_PARTY_ACCOUNT.disabled`, false);
                    }
                    if (childrenItem[key].other) {
                      childrenItem[key].other = ''
                    }
                  }
                }
                next({ value, ...rest });
              }}
            >
              <Checkbox>{item[lan]}</Checkbox>
            </Field>
            {
              values[path][item.value]['value'] && <Reveal className='customReveal' accentBar marginTop={3}>
                <Fieldset name={`${path}.${item.value}.children`}>
                  {
                    item.children && item.children.map((child) => (
                      <div className={values[path][item.value]['children'][child.value]['disabled'] ? 'disabledFund' : ''} key={`${path}.${item.value}.children.${child.value}.value`}>
                        <Field
                          className="usingRevea2"
                          marginBottom={0}
                          name={`${path}.${item.value}.children.${child.value}.value`}
                          onChange={(next, { value, ...rest }) => {
                            if (child.isCountry) {
                              const childCountry = values[path][item.value].children[child.value].country;
                              if (value) {
                                childCountry.push('');
                                setFieldValue(`${path}.${item.value}.children.${child.value}.depositAmount`, '');
                                // https://wpb-jira.systems.uk.hsbc/browse/ORIG-8666
                                let currentFundsItem = fundsItems.FIRST;
                                if (path === fundsItems.FIRST) {
                                  currentFundsItem = fundsItems.FUNDS;
                                }
                                setFieldValue(`${currentFundsItem}.${item.value}.children.${child.value}.disabled`, true);

                              } else {
                                childCountry.splice(0);
                                // https://wpb-jira.systems.uk.hsbc/browse/ORIG-8666
                                let currentFundsItem = fundsItems.FIRST;
                                if (path === fundsItems.FIRST) {
                                  currentFundsItem = fundsItems.FUNDS;
                                }
                                setFieldValue(`${currentFundsItem}.${item.value}.children.${child.value}.disabled`, false);
                              }
                            }

                            if (child.isOther && !value) {
                              setFieldValue(`${path}.${item.value}.children.${child.value}.other`, '');
                            }
                            next({ value, ...rest });
                          }}
                        >
                          <div><Checkbox disabled={values[path][item.value]['children'][child.value]['disabled']}>{child[lan]}</Checkbox></div>
                        </Field>
                        {
                          (child.isCountry && values[path][item.value]['children'][child.value]['value'] === true) && (
                            <>
                              <AddAnother
                                name={`${path}.${item.value}.children.${child.value}.country`}
                                itemInitialValue={''}
                                max={7}
                                min={1}
                                renderAddButtonText={({ length }) => (
                                  <>
                                    {t('ICO.USING.ADD_COUNTRY')}<VisuallyHidden> {length + 1}</VisuallyHidden>
                                  </>
                                )}
                                renderRemoveButtonText={() => {
                                  return t('ICO.PERSONAL.ADD_ANOTHER_NATIONALITY_REMOVE');
                                }}
                              >
                                {({ path }) => (
                                  <Field name={path} marginBottom={0}>
                                    <Label>{t('ICO.USING.WILL_RECEIVING_FUNDS')}</Label>
                                    <Select
                                      fullWidth
                                      options={countryList}
                                      placeholder={t('ICO.PERSONAL.COUNTRY_OF_BIRTH_PLACEHOLDER')}
                                    />
                                    <FieldFeedback />
                                  </Field>
                                )}
                              </AddAnother>
                              <Field name={`${path}.${item.value}.children.${child.value}.depositAmount`}
                                onChange={(next, { value, ...rest }) => {
                                  value = value.replace(/^0$/g, '');
                                  next({ value, ...rest });
                                }}
                              >
                                <Label>{t('ICO.USING.THIRD_AMOUNT')}</Label>
                                <CurrencyInput maxLength={7} symbol={t('ICO.FINANCIAL.HKD1')} />
                                <FieldFeedback />
                              </Field>
                            </>
                          )}
                        {
                          child.isOther && values[path][item.value]['children'][child.value]['value'] === true &&
                          (<Field name={`${path}.${item.value}.children.${child.value}.other`}>
                            <Label>{t('ICO.USING.WILL_RECEIVING_FUNDS_OTHER')}</Label>
                            <TextInput />
                            <FieldFeedback />
                          </Field>)
                        }
                      </div>
                    ))
                  }
                  <FieldFeedback />
                </Fieldset>
              </Reveal>
            }
          </div>
        ))}
      <FieldFeedback />
    </Fieldset>);

  return (
    <>
      <FormLayout>
        <WithI18nProgressIndicator current={4} />
        <Section>
          <Heading level={1}>{t('ICO.USING.TITLE')}</Heading>
          <Paragraph lead>{t('ICO.USING.SUB_TITLE')}</Paragraph>
          <HorizontalRule />
        </Section>
        <Form
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={({ values }) => {
            setUserSessionDataByKey('using', values);
            history.push(isPremier && enableCreditCard ? '/info/creditCard' : '/info/review');
          }}
          onChange={rePrevious}
        >
          {({ values, isSubmitting, isValid, errors, setFieldValue }) => {
            if (isSubmitting && !isValid && JSON.stringify(errors) !== '{}') {
              validationTagging({ errors, taggingParams });
            }
            return (
              <>
                <FormFeedback textMap={{ title: t('ICO.COMMON.CORRECT'), of: '/' }} />
                <Section>
                  <Heading level={2} accentBar>
                    {t('ICO.USING.ANTICIPATED_USAGE_SECTION')}
                  </Heading>
                  <Fieldset
                    name="selectAccountPurpose"
                  >
                    <Label>{t('ICO.USING.USE_ACCOUNT')}</Label>
                    <Hint>{t('ICO.COMMON.SELECT_REMIND')}</Hint>
                    {useAccountList.map((item) => (
                      <Field
                        key={`selectAccountPurpose.${item.value}`}
                        name={`selectAccountPurpose.${item.value}`}
                        marginBottom={0}
                        onChange={(next, { value, ...rest }) => {
                          if (item.value === 'OTHER' && !value) {
                            setFieldValue('reasonForAccountOpening', '');
                          }

                          const selected = formatFormCheckboxArray(values.selectAccountPurpose);
                          if (value) {
                            selected.push(item.value);
                          } else {
                            let index = selected.indexOf(item.value);
                            selected.splice(index, 1);
                          }
                          linkTagging({
                            'page_url': TAG_PAGE_URL,
                            'page_language': lan,
                            'event_category': 'content',
                            'event_action': 'checkbox',
                            'event_content': `anticipated account usage: ${selected.join(',')}`,
                            'page_name': TAG_PAGE_NAME,
                            'raw_datalayer': '5745v18'
                          });
                          next({ value, ...rest });
                        }}>
                        <Checkbox>{item[lan]}
                          <Paragraph hint marginBottom={0}>{getUseAccountList(item.value)}</Paragraph>
                        </Checkbox>
                      </Field>
                    ))}
                    <FieldFeedback marginTop={1} />
                  </Fieldset>
                  {
                    values.selectAccountPurpose.OTHER && (
                      <Reveal accentBar marginBottom={2}>
                        <Field name="reasonForAccountOpening">
                          <Label>{t('ICO.USING.USE_ACCOUNT_OTHER')}</Label>
                          <TextArea />
                          <FieldFeedback />
                        </Field>
                      </Reveal>)
                  }
                  <Heading level={2} accentBar>
                    {t('ICO.USING.ACCOUNT_FUNDING_SECTION')}
                  </Heading>
                  {FundsSource(fundsItems.FUNDS, values, setFieldValue, 'ICO.USING.MONTHLY_FUNDS')}
                  <Heading level={2} accentBar>
                    {t('ICO.USING.FIRST_DEPOSIT_SECTION')}
                  </Heading>
                  <Fieldset
                    name="sourceSame"
                    onChange={(next, { value, ...rest }) => {
                      if (value === 'yes') {
                        const firstDepositDetailsObj = values.firstDepositDetailsObj;
                        for (let key in firstDepositDetailsObj) {
                          firstDepositDetailsObj[key].value = false;
                          const childrenItem = firstDepositDetailsObj[key].children;
                          for (let child in childrenItem) {
                            childrenItem[child].value = false;
                            if (childrenItem[child].country) {
                              childrenItem[child].country.splice(0);
                              setFieldValue(`regularFundsDetailsObj.A third party.children.TRANSFER_FROM_3RD_PARTY_ACCOUNT.disabled`, false);
                            }
                            if (childrenItem[child].other) {
                              childrenItem[child].other = ''
                            }
                          }
                        }
                      }
                      next({ value, ...rest });
                    }}
                  >
                    <Label>
                      {t('ICO.USING.SOURCE_SAME')}
                    </Label>
                    <YesNoRadio textMap={{ yes: t('ICO.COMMON.YES'), no: t('ICO.COMMON.NO') }} />
                    <FieldFeedback marginTop={1} />
                  </Fieldset>
                  {
                    values.sourceSame === 'no' && FundsSource(fundsItems.FIRST, values, setFieldValue, 'ICO.USING.FIRST_DEPOSIT_SOURCE')
                  }
                  <Fieldset name="receivingMoney"
                    onChange={(next, { value, ...rest }) => {
                      if (value === 'no') {
                        setFieldValue('approximateDepositAmount', '');
                      }
                      next({ value, ...rest });
                    }}>
                    <Heading level={2} accentBar>
                      {t('ICO.USING.PARTIES_SECTION')}
                    </Heading>
                    <Label>{t('ICO.USING.RECEIVING_MONEY')}</Label>
                    <Paragraph hint>{t('ICO.USING.RECEIVING_MONEYS')}</Paragraph>
                    <YesNoRadio textMap={{ yes: t('ICO.COMMON.YES'), no: t('ICO.COMMON.NO') }} />
                    <FieldFeedback marginTop={1} />
                  </Fieldset>
                  {
                    values.receivingMoney === 'yes' && <Reveal accentBar marginBottom={0}>
                      <Field name='approximateDepositAmount'
                        onChange={(next, { value, ...rest }) => {
                          value = value.replace(/^0$/g, '');
                          next({ value, ...rest });
                        }}
                      >
                        <Label>{t('ICO.USING.MONTHLY_PAYMENTS')}</Label>
                        <CurrencyInput maxLength={7} symbol={t('ICO.FINANCIAL.HKD1')} />
                        <FieldFeedback />
                      </Field>
                      <Callout type="information">
                        <Paragraph marginBottom={0}>{t('ICO.USING.PARTIES_NOTICE')}</Paragraph>
                      </Callout>
                    </Reveal>
                  }
                </Section>
                <ButtonWithBack />
              </>
            )
          }}
        </Form>
      </FormLayout>
    </>
  );
}

export default UsingAccount;
