
import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Guide from './pages/Guide';
import AccountType from './pages/Guide/pages/AccountType';
import Welcome from './pages/Guide/pages/Welcome';
import Info from './pages/Info';
import NotFound from './pages/NotFound';
import Happens from 'pages/Happens';
import NotificationPage from 'pages/NotificationPage';
import Timeout from 'pages/Timeout';
import Technicals from 'pages/Technicals';
import IndustryView from 'pages/IndustryView';
import ProviderTime from 'components/ProviderTime';
import Maintenance from 'pages/Maintenance';
import { timeDiffMaintenance } from "./utils/date";
import { getUrlParams } from "./utils/url";
import { retrieveMockData } from '__mock__/mock';

const location = window.location;
/* NOSONAR */
if (process.env.MOCK === 'on'){
  if (getUrlParams('dummy', location.search || location.hash.replace('#/','')) === 'on') {
    sessionStorage.setItem('dummy', 'on');
    retrieveMockData();
  }
}

const App = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const acTypeStr = 'ac' + 'countType';
  const queryAcType = sessionStorage.getItem('AC_TYPE') || getUrlParams(acTypeStr, location.search || location.hash.replace('#/','')) || 'ONE';
  if (queryAcType) {
      sessionStorage.setItem('AC_TYPE', queryAcType);
  }

  useEffect(() => {
    fetch('./config/maintenance.json',
      {
        method: 'GET',
        mode: 'cors',
      }).then(response => {
        const headers = new Headers();
        headers.set('Strict-Transport-Security', 'max-age=31536000; includeSubDomains');
        const newResponse = new Response(response.body, {
          status: response.status,
          statusText: response.statusText,
          headers: headers
        });
        return newResponse;
      }).then(async (responseWithSTS) => {
        const data = await responseWithSTS.json() || {};
        window.maintenanceContext = data;
        const getMaintenance = timeDiffMaintenance(data.startDate, data.endDate);
        setIsMaintenance(getMaintenance.status);
      });
  }, []);

  return (
    <Router basename={process.env.publicPath}>
      {!isMaintenance &&
        <ProviderTime>
          <Switch>
            <Route exact path="/" component={Guide} />
            <Route path="/AccountType" component={AccountType} />
            <Route path="/Welcome" component={Welcome} />
            <Route path="/info" component={Info} />
            <Route path="/industryView" component={IndustryView} />
            <Route path="/happens" component={Happens} />
            <Route path="/error" component={NotificationPage} />
            <Route path="/timeout" component={Timeout} />
            <Route path="/technicals" component={Technicals} />
            <Route component={NotFound} />
          </Switch>
        </ProviderTime>
      }
      {
        isMaintenance &&
        <Switch>
          <Route path="/maintenance" component={Maintenance} />
          <Redirect to="/maintenance" />
        </Switch>
      }
    </Router>
  );
}

export default App;
