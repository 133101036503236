export default {
    en: [
        {value: 'PASSPORT', label: 'Passport'},
        {value: 'EXIT_ENTRY_PERMIT', label: 'Exit/Entry permit to Hong Kong and Macau'}
    ],
    zh_CN: [
        {value: 'PASSPORT', label: '护照'},
        {value: 'EXIT_ENTRY_PERMIT', label: '往来港澳通行证'}
    ],
    zh_HK: [
        {value: 'PASSPORT', label: '護照'},
        {value: 'EXIT_ENTRY_PERMIT', label: '往來港澳通行證'}
    ]
}
