import { pinYinCode } from './pinYinCode';

const ucfirst = (l1) => {
  if (l1.length > 0) {
    const first = l1.slice(0, 1).toUpperCase();
    const spare = l1.slice(1, l1.length);
    return first + spare;
  }
  return '';
};

const arraySearch = (l1, index, PinYin) => {
  for (const name in PinYin) {
    if (PinYin[name].indexOf(l1) !== -1) {
      if (index === 0) {
        return ucfirst(name);
      } else {
        return name;
      }
    }
  }
  return false;
};

const changeToPinYinService = (l1) => {
  const PinYin = pinYinCode.root;
  const l2 = l1.length;
  let I1 = '';
  const reg = new RegExp('[a-zA-Z0-9- ]');
  for (let i = 0; i < l2; i++) {
    const val = l1.slice(i, 1);
    const name = arraySearch(val, i, PinYin);
    if (reg.test(val)) {
      I1 += val;
    } else if (name !== false) {
      I1 += name;
    }
  }
  return I1;
};

const isChinese = (chars) => {
  const reg = new RegExp('^[\u2E80-\uFE4F]+$', 'g');
  return reg.test(chars);
};

const convertToPinyin = (val) => {
  let pinyin = '';
  const resultParts = [];
  for (let i = 0; i < val.length; i++) {
    resultParts.push(val.charAt(i));
  }

  for (let k = 0; k < resultParts.length; k++) {
    if (k < resultParts.length - 1) {
      if (isChinese(resultParts[k]) || (!isChinese(resultParts[k]) && isChinese(resultParts[k + 1]))) {
        pinyin += changeToPinYinService(resultParts[k]).toUpperCase() + '';
      } else {
        pinyin += changeToPinYinService(resultParts[k]).toUpperCase();
      }
    } else {
      pinyin += changeToPinYinService(resultParts[k]).toUpperCase();
    }
  }
  pinyin = pinyin.replace(/\s+/g, ' ');
  return pinyin;
};

const convertToHalfAngle = (str) => {
  let tmp = '';
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) === 12288) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
      continue;
    }
    if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
    } else {
      tmp += String.fromCharCode(str.charCodeAt(i));
    }
  }
  return tmp;
};

export const convertChineseToPinYin = (ChineseString) => {
  if (typeof ChineseString !== 'string') {
    return '';
  }

  let pinyin = '';
  const reg = /^[·×￥~！#%？：/\u2014\u2026\u201C\u201D\u2018\u2019\u2E80-\uFE4F]+$/;
  for (let i = 0; i < ChineseString.length; i++) {
    const nextStringChar = ChineseString[i + 1];
    if (reg.test(ChineseString[i])) {
      pinyin = `${pinyin}${convertToPinyin(ChineseString[i])} `;
    } else {
      pinyin = pinyin + ChineseString[i];
      if (nextStringChar !== undefined && reg.test(nextStringChar)) {
        pinyin = `${pinyin} `;
      }
    }
  }
  pinyin = convertToHalfAngle(pinyin);
  return pinyin.trim();
};

export const getCountrLikeData = (obj) => {
  const groupObj = {};
  const rs = [];
  const handmapgroup = { '金': 'J', '糖': 'T', '零': 'L', '樓': 'L', '理': 'L', '殮': 'L' };
  Object.keys(obj).forEach((key) => {
    const v = obj[key];
    const [firstchart] = v;
    const s = convertChineseToPinYin(firstchart);
    let groupId = s[0];
    if (groupId === undefined) {
      groupId = handmapgroup[firstchart];
    }
    if (!groupObj[groupId]) {
      groupObj[groupId] = [];
    }
    groupObj[groupId].push({ value: key, label: v });
  });
  Object.keys(groupObj).forEach((groupId) => {
    rs.push({ groupId, ctryList: groupObj[groupId] });
  });
  rs.sort((a, b) => {
    return a.groupId.charCodeAt(0) - b.groupId.charCodeAt(0);
  });
  return rs;
}

export const getAllValue = (list, value) => {
  const findResult = list.find(item => item.value === value);
  return findResult ? findResult.label : '';
}

export const getSearchCountryList = (countryList, keyWord, lang) => {
  const searchArr = [];
  const keyWordReg = lang === 'en' ? /[^A-Za-z .]/g : /[^\u4300-\u9fa5 ]/g;
  keyWord = keyWord.replace(keyWordReg, '');

  if (!keyWord) {
    return countryList;
  }
  countryList.forEach(items => {
    items.ctryList.forEach(ele => {
      if (lang === 'en') {
        const reg = new RegExp(keyWord, 'i');
        if (ele.label.match(reg) && ele.label.match(reg) !== 'undefined' && ele.label.match(reg) !== null) {
          searchArr.push(ele);
        }
      } else {
        if (ele.label.search(keyWord) >= 0) {
          searchArr.push(ele);
        }
      }
    });
  });
  return searchArr;
}
