import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Link, ButtonRow, IconChevronLeftThick } from '@piggybank/core';
import { useHistory } from 'react-router-dom';
import './buttonWithBack.css';

const ButtonWithBack = ({ isSubmit, isSubmitDisabled, onClick, customText }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const goBack = () => {
        history.goBack();
    }

    return (
        <div className='buttonWithbank'>
            <ButtonRow>
            <Button elementType="button" disabled={isSubmitDisabled} onClick={onClick}>{isSubmit ? t('ICO.COMMON.SUBMIT') : customText || t('ICO.COMMON.CONTINUE')}</Button>
                <Link
                    buttonPadding
                    elementType="button"
                    hoverUnderline
                    iconSlotLeft={<span style={{ color: 'var(--red)' }}><IconChevronLeftThick inline /></span>}
                    strong={false}
                    type="button"
                    onClick={goBack}>
                    {t('ICO.COMMON.BACK')}
                </Link>
            </ButtonRow>
        </div>
    );
}

export default ButtonWithBack;
