export default [
    { value: 'HOUSEHOLD_EXPENSES', en: 'Household expenses', zh_CN: '家庭消费', zh_HK: '家庭消費' },
    { value: 'PERSONAL_EXPENSES', en: 'Personal expenses', zh_CN: '个人消费', zh_HK: '個人消費' },
    { value: 'PERSONAL_PURCHASES', en: 'Major personal purchases', zh_CN: '个人大额支出', zh_HK: '個人大額支出' },
    { value: 'MAJOR_PURCHASE', en: 'Major purchases', zh_CN: '大额支出', zh_HK: '大額支出' },
    { value: 'CREDITS', en: 'Regular mandated credits', zh_CN: '定期授权存款', zh_HK: '定期授權存款' },
    { value: 'DEBITS', en: 'Regular mandated debits', zh_CN: '定期授权扣款', zh_HK: '定期授權扣款' },
    { value: 'SAVINGS', en: 'Savings', zh_CN: '储蓄', zh_HK: '儲蓄' },
    { value: 'EDUCATION', en: "Tuition and other expenses for your children’s education", zh_CN: '子女学费和教育费用', zh_HK: '子女學費及教育費用' },
    { value: 'OTHER', en: 'Other purposes', zh_CN: '其他目的', zh_HK: '其他目的' },
]