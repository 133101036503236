import React from 'react';
import { Field, Checkbox, FieldFeedback, Form, ReviewNavigate, FormFeedback } from "@piggybank/form";
import {
    Heading,
    FormLayout,
    PlaybackList,
    PlaybackKey,
    PlaybackValue,
    UnorderedList,
    ListItem,
    Paragraph,
} from "@piggybank/core";
import Title from "../../../components/Title/Title";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getUserSessionDataByKey } from "../../../storage/userStorage";
import { WithI18nProgressIndicator } from "../../../components/WithI18nPiggybank";
import { ButtonWithBack } from "../../../components/ButtonWithBack";
import { Gender, DataTitle, IdType, Country, CartAddress, accountPurpose as useAccountList, ReasonForOpening as ReasonForOpeningList, funding } from "referenceData";
import { getAllValue } from 'utils/customerNameFormat';
import { financialDetailsData } from '../../../referenceData/financialDetailsData/financialDetailsData';
import { cardData } from '../../../referenceData/card/card';
import { relativeData } from '../../../referenceData/card/relative';
import './custom.css';
import * as yup from "yup";
import i18n from "i18next";
import { linkTagging, useTagging } from 'tealiumTrack';

const REVIEW_EDIT = 'ICO.REVIEW.EDIT';

const schema = yup.object().shape({
    agreeRB: yup.boolean().oneOf([true], () => i18n.t('ICO.CREDIT.LTA_CHECKBOX_ERROR'))
});

const Review = () => {
    const { lang, isPremier, enableCreditCard } = getUserSessionDataByKey('global');
    const personalData = getUserSessionDataByKey('personal');
    const addressData = getUserSessionDataByKey('address');
    const financialData = getUserSessionDataByKey('financial');
    const usingData = getUserSessionDataByKey('using');
    const creditCardData = getUserSessionDataByKey('creditCard');
    const reviewData = getUserSessionDataByKey('review');
    const { t } = useTranslation();
    const { employmentStatusList, employmentRoleList, occupation } = financialDetailsData[`${i18n.language}`];
    const { relative } = relativeData[`${i18n.language}`];
    const { cardList, settlmentList } = cardData[`${i18n.language}`];
    const history = useHistory();
    const tinList = [
        {
            value: "REASON1",
            label: `${t('ICO.FINANCIAL.ISSUE')}`
        },

        {
            value: "REASON2",
            label: `${t('ICO.FINANCIAL.ALLOWTIN')}`
        },
        {
            value: "EXPLANATION1",
            label: `${t('ICO.FINANCIAL.WAITINGS')}`
        },
        {
            value: "EXPLANATION2",
            label: `${t('ICO.FINANCIAL.DONTTIN')}`
        },
    ];

    const creditCardTypeList = [
        {
            value: "HSBC Premier MasterCard",
            label: `${t('ICO.CREDIT.CARDTYPE1')}`
        },

        {
            value: "Visa Signature",
            label: `${t('ICO.CREDIT.CARDTYPE2')}`
        },
    ];
    const getDataLabelByValue = (data, filterKey) => {
        if (!data.length || !filterKey) {
            return '';
        }
        const filterData = data.filter((value) => {
            return value.value === filterKey;
        });
        return filterData[0].label;
    }

    const getDataList = (data, filterKey) => {
        // console.log(data, filterKey)
        if (!data.length || !filterKey) {
            return '';
        }
        const filterData = data.filter((value) => {
            return value.value === filterKey;
        });
        return filterData[0];
    }

    const getYesOrNo = (value, isContrary = false) => {
        return (value === 'yes' && !isContrary) || (value === 'no' && isContrary)
            ? t('ICO.COMMON.YES')
            : t('ICO.COMMON.NO')
    }

    const formatAddressToFullString = (addressArr) => {
        if (lang === 'en') {
            return `${addressArr.addressLine3}, ${addressArr.addressLine2}, ${addressArr.addressLine1} ${getDataLabelByValue(Country[lang], addressArr.addressCountry)}`;
        } else {
            return `${getDataLabelByValue(Country[lang], addressArr.addressCountry)}, ${addressArr.addressLine1}, ${addressArr.addressLine2}, ${addressArr.addressLine3}`;
        }
    }

    const getUseArrarText = (selectedOption, options) => {
        if (!selectedOption) {
            return ''
        }

        let text = ''
        for (const item of options) {
            if (selectedOption[item.value]) {
                text += ` ${item[lang]},`;
            }
        }
        return text.slice(0, text.length - 1);
    }

    const arrayCountryToText = (countrys) => {
        let text = '';
        for (const item of countrys) {
            text += ` ${getDataLabelByValue(Country[lang], item)},`
        }
        return text.slice(0, text.length - 1)
    }

    const getReasonsForOpeningAccount = (reasonsForOpeningAccount) => {
        const SelectReasonsForOpeningAccount = ReasonForOpeningList.find(item => item.value === reasonsForOpeningAccount);
        if (reasonsForOpeningAccount !== 'SOMETHING_ELSE') {
            return SelectReasonsForOpeningAccount[lang];
        }

        return `${SelectReasonsForOpeningAccount[lang]} - ${addressData.otherReasonForOpeningAccountInAnotherCountryText}`;
    }

    useTagging({
        'page_url': '/forms/review',
        'page_language': lang,
        'page_name': 'pws:forms:review',
        'page_subcategory': 'review',
        'raw_datalayer': '5745v20',
        'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
        'funnel_step': '6',
        'funnel_step_name': 'review'
    });

    function getText(selectFunding) {
        let text = '';
        for (const { children } of funding) {
            for (const item of children) {
                if (selectFunding[item.value]) {
                    text += ` ${item[lang]},`
                }
            }
        }
        return text;
    }

    function setFrom3dr(from3dr, children, childKey) {
        if (childKey === 'TRANSFER_FROM_3RD_PARTY_ACCOUNT') {
            return [arrayCountryToText(children[childKey].country), children[childKey].depositAmount]
        }
        return from3dr;
    }

    function setOtherText(children, childKey) {
        if (childKey === 'ANOTHER_SOURCE') {
            return children[childKey].other;
        }

        return ''
    }


    const getFundingText = (fundingObj, i18ntext) => {
        const selectFunding = {};
        let from3dr = [];
        let otherText = '';
        for (const key in fundingObj) {
            if (!fundingObj[key].value) {
                continue;
            }
            const children = fundingObj[key].children;
            for (const childKey in children) {
                if (!children[childKey].value) {
                    continue;
                }
                selectFunding[childKey] = true;
                from3dr = setFrom3dr(from3dr, children, childKey);
                otherText = setOtherText(children, childKey);
            }
        }

        const text = getText(selectFunding);

        return (
            <>
                <PlaybackKey>{i18ntext}</PlaybackKey>
                <PlaybackValue>{text.slice(0, text.length - 1)}</PlaybackValue>
                {otherText &&
                    <>
                        <PlaybackKey>{t('ICO.USING.WILL_RECEIVING_FUNDS_OTHER')}</PlaybackKey>
                        <PlaybackValue>{otherText}</PlaybackValue>
                    </>
                }
                {from3dr.length === 2 &&
                    <>
                        <PlaybackKey>{t('ICO.USING.WILL_RECEIVING_FUNDS')}</PlaybackKey>
                        <PlaybackValue>{from3dr[0]}</PlaybackValue>
                        <PlaybackKey>{t('ICO.USING.THIRD_AMOUNT')}</PlaybackKey>
                        <PlaybackValue>{t('ICO.FINANCIAL.HKD1')} {from3dr[1]}</PlaybackValue>
                    </>
                }
            </>
        )
    }

    const PAGENAMES = {
        personal: 'Personal information',
        address: 'your address',
        financial: 'financial details',
        using: 'account usage',
        creditCard: 'credit card'
    }

    const onEdit = pageName => {
        linkTagging({
            'page_url': '/forms/review',
            'page_language': lang,
            'event_category': 'content',
            'event_action': 'button',
            'event_content': `edit: ${PAGENAMES[pageName]}`,
            'page_name': 'pws:forms:review',
            'raw_datalayer': '5745v21'
        });

        history.push(`/info/${pageName}`);
    }

    return (
        <FormLayout>
            <WithI18nProgressIndicator current={isPremier && enableCreditCard ? 6 : 5} />
            <Title heading={t('ICO.REVIEW.TITLE')} legend={t('ICO.REVIEW.DESC')} />
            <FormFeedback textMap={{ title: t('ICO.COMMON.CORRECT'), of: '/' }} />
            <ReviewNavigate title={t('ICO.PERSONAL.TITLE')} onClick={() => {
                onEdit('personal');
            }} textMap={{
                editButton: t(REVIEW_EDIT)
            }}>
                <PlaybackList type="review" className="review">
                    <Heading level={3}>{t('ICO.PERSONAL.CONTACT_DETAILS')}</Heading>
                    <PlaybackKey>{t('ICO.PERSONAL.EMAIL_ADDRESS')}</PlaybackKey>
                    <PlaybackValue>{personalData.email}</PlaybackValue>
                    <PlaybackKey>{t('ICO.PERSONAL.PHONE_NUMBER')}</PlaybackKey>
                    <PlaybackValue>{`+${personalData.phoneNumber?.extendedValues?.countryCallingCode} ${personalData.phoneNumber?.extendedValues?.phone}`}</PlaybackValue>
                    <Heading level={3}>{t('ICO.PERSONAL.ABOUT_YOU')}</Heading>
                    <PlaybackKey>{t('ICO.PERSONAL.GENDER')}</PlaybackKey>
                    <PlaybackValue>{getDataLabelByValue(Gender[lang], personalData.gender)}</PlaybackValue>
                    <PlaybackKey>{t('ICO.PERSONAL.ABOUT_YOU_TITLE')}</PlaybackKey>
                    <PlaybackValue>{getDataLabelByValue(DataTitle[lang], personalData.title)}</PlaybackValue>
                    <PlaybackKey>{t('ICO.PERSONAL.FIRST_NAME')}</PlaybackKey>
                    <PlaybackValue>{personalData.firstName}</PlaybackValue>
                    <PlaybackKey>{t('ICO.PERSONAL.LAST_NAME')}</PlaybackKey>
                    <PlaybackValue>{personalData.lastName}</PlaybackValue>

                    <PlaybackKey>{t('ICO.PERSONAL.DATE_OF_BIRTH')}</PlaybackKey>
                    <PlaybackValue>{personalData.dateOfBirth}</PlaybackValue>
                    <PlaybackKey>{t('ICO.PERSONAL.COUNTRY_OF_BIRTH')}</PlaybackKey>
                    <PlaybackValue>{getDataLabelByValue(Country[lang], personalData.countryOfBirth)}</PlaybackValue>
                    <Heading level={3}>{t('ICO.PERSONAL.IDENTITY_DETAILS')}</Heading>
                    <PlaybackKey>{t('ICO.PERSONAL.PERMIT_TYPE')}</PlaybackKey>
                    <PlaybackValue>{getDataLabelByValue(IdType[lang], personalData.documentType)}</PlaybackValue>
                    {personalData.documentType === 'PASSPORT' &&
                        <>
                            <PlaybackKey>{t('ICO.PERSONAL.PERMIT_COUNTRY')}</PlaybackKey>
                            <PlaybackValue>{getDataLabelByValue(Country[lang], personalData.placeOfIssue)}</PlaybackValue>
                        </>
                    }
                    <PlaybackKey>{t('ICO.PERSONAL.PERMIT_NUMBER')}</PlaybackKey>
                    <PlaybackValue>{personalData.permitNumber}</PlaybackValue>
                    <PlaybackKey>{t('ICO.PERSONAL.NATIONALITY_QUESTION')}</PlaybackKey>
                    <PlaybackValue>{getYesOrNo(personalData.hasOtherNationality, true)}</PlaybackValue>
                    {personalData.hasOtherNationality === 'no' && personalData.nationality2 !== '' &&
                        <>
                            <PlaybackKey>{t('ICO.REVIEW.OTHER_NATIONALITY')}1</PlaybackKey>
                            <PlaybackValue>{getDataLabelByValue(Country[lang], personalData.nationality2)}</PlaybackValue>
                        </>
                    }
                    {personalData.hasOtherNationality === 'no' && personalData.nationality3 !== '' &&
                        <>
                            <PlaybackKey>{t('ICO.REVIEW.OTHER_NATIONALITY')}2</PlaybackKey>
                            <PlaybackValue>{getDataLabelByValue(Country[lang], personalData.nationality3)}</PlaybackValue>
                        </>
                    }
                </PlaybackList>
            </ReviewNavigate>
            <ReviewNavigate title={t('ICO.ADDRESS.TITLE')} onClick={() => {
                onEdit('address');
            }} textMap={{
                editButton: t(REVIEW_EDIT)
            }}>
                <PlaybackList type="review" className="review">
                    <Heading level={3}>{t('ICO.ADDRESS.CURRENT_ADRESS_TITLE')}</Heading>
                    <PlaybackKey>{t('ICO.ADDRESS.CURRENT_ADRESS_TITLE')}</PlaybackKey>
                    <PlaybackValue>{formatAddressToFullString(addressData.currentResidentialAddress)}</PlaybackValue>
                    <Heading level={3}>{t('ICO.ADDRESS.CORRESPONDENCE_TITLE')}</Heading>
                    <PlaybackKey>{t('ICO.ADDRESS.CORRESPONDENCE_SUB_TITLE')}</PlaybackKey>
                    <PlaybackValue>{getYesOrNo(addressData.correspondenceAddressDifferent)}</PlaybackValue>
                    {addressData.correspondenceAddressDifferent === 'no' &&
                        <>
                            <PlaybackKey>{t('ICO.ADDRESS.CORRESPONDENCE_TITLE')}</PlaybackKey>
                            <PlaybackValue>{formatAddressToFullString(addressData.correspondenceAddress[0])}</PlaybackValue>
                        </>
                    }
                    <Heading level={3}>{t('ICO.ADDRESS.CARDADDRESS_TITLE')}</Heading>
                    <PlaybackKey>{t('ICO.ADDRESS.CARDADDRESS_SUB_TITLE')}</PlaybackKey>
                    <PlaybackValue>{getDataList(CartAddress, addressData.cartAddress)[lang]}</PlaybackValue>
                    {addressData.cartAddress === '2' &&
                        <>
                            <PlaybackKey>{t('ICO.ADDRESS.ATMCARD')}</PlaybackKey>
                            <PlaybackValue>{addressData.atmCountry}</PlaybackValue>
                        </>
                    }
                    {addressData.currentResidentialAddress.addressCountry !== '' && addressData.currentResidentialAddress.addressCountry !== 'Hong Kong SAR' && (
                        <>
                            <Heading level={3}>{t('ICO.ADDRESS.APPLICATION_TITLE')}</Heading>
                            <PlaybackKey>{t('ICO.ADDRESS.APPLICATION_SUB_TITLE')}</PlaybackKey>
                            <PlaybackValue>{getReasonsForOpeningAccount(addressData.selectReasonsForOpeningAccount)}</PlaybackValue>
                        </>
                    )
                    }
                </PlaybackList>
            </ReviewNavigate>
            <ReviewNavigate title={t('ICO.FINANCIAL.DETAILS')} onClick={() => {
                onEdit('financial');
            }} textMap={{
                editButton: t(REVIEW_EDIT)
            }}>
                <PlaybackList type="review" className="review">
                    <Heading level={3}>{t('ICO.FINANCIAL.EMPLOYMENT')}</Heading>
                    <PlaybackKey>{t('ICO.FINANCIAL.EMPLOYMENTSTATUS')}</PlaybackKey>
                    <PlaybackValue>{getAllValue(employmentStatusList, financialData.employmentStatus)}</PlaybackValue>
                    {financialData.allDisplayStatus &&
                        <>
                            <PlaybackKey>{t('ICO.FINANCIAL.EMPLOYMENTROLE')}</PlaybackKey>
                            <PlaybackValue>{getAllValue(employmentRoleList, financialData.employmentRole)}</PlaybackValue>
                            <PlaybackKey>{t('ICO.FINANCIAL.OCCUPATION')}</PlaybackKey>
                            <PlaybackValue>{getAllValue(occupation, financialData.occupation)}</PlaybackValue>
                            <PlaybackKey>{t('ICO.FINANCIAL.INDUSTRY')}</PlaybackKey>
                            <PlaybackValue>{financialDetailsData[lang].industryList[financialData.industry?.value]}</PlaybackValue>
                            {financialData.fullStatus === 1 &&
                                <>
                                    <PlaybackKey>{t('ICO.FINANCIAL.EMPLOYERSNAME')}</PlaybackKey>
                                    <PlaybackValue>{financialData.employerName}</PlaybackValue>
                                </>
                            }

                            {financialData.fullStatus === 3 &&
                                <>
                                    <PlaybackKey>{t('ICO.FINANCIAL.YOURADDRESS')}</PlaybackKey>
                                    <PlaybackValue>{financialData.own}</PlaybackValue>
                                </>
                            }
                            <PlaybackKey>{t('ICO.FINANCIAL.COUNTRY')}</PlaybackKey>
                            <PlaybackValue>{getDataLabelByValue(Country[lang], financialData.addressCountry)}</PlaybackValue>
                            <Heading level={3}>{t('ICO.FINANCIAL.INCOME')}</Heading>
                            {financialData.fullStatus !== 3 &&
                                <>
                                    <PlaybackKey>{t('ICO.FINANCIAL.HKD')}</PlaybackKey>
                                    <PlaybackValue>{financialData.gross}</PlaybackValue>
                                </>
                            }
                            {financialData.fullStatus === 3 &&
                                <>
                                    <PlaybackKey>{t('ICO.FINANCIAL.AVERAGES')}</PlaybackKey>
                                    <PlaybackValue>{financialData.average}</PlaybackValue>
                                </>
                            }
                            {financialData.fullStatus !== 1 &&
                                <>
                                    <PlaybackKey>{t('ICO.FINANCIAL.ANNUAL')}</PlaybackKey>
                                    <PlaybackValue>{financialData.amount}</PlaybackValue>
                                </>
                            }
                        </>
                    }
                    <Heading level={3}>{t('ICO.FINANCIAL.TAX')}</Heading>
                    {financialData.additionalNationalities.map((item, index) => (
                        <div key={index}>
                            <PlaybackKey>{t('ICO.FINANCIAL.PURPOSES')}</PlaybackKey>
                            <PlaybackValue>{getDataLabelByValue(Country[lang], item.countryOfTaxResidency)}</PlaybackValue>
                            {!item.agree &&
                                <>
                                    <PlaybackKey>{t('ICO.FINANCIAL.ENTERTAX')}</PlaybackKey>
                                    <PlaybackValue>{item.taxDocumentIdNumber}</PlaybackValue>
                                </>
                            }
                            {item.agree &&
                                <>
                                    <PlaybackKey>{t('ICO.FINANCIAL.TELL')}</PlaybackKey>
                                    <PlaybackValue>{getAllValue(tinList, item.reasonForNoTIN)}</PlaybackValue>
                                </>
                            }
                            {item.reasonForNoTIN === 'EXPLANATION2' &&
                                <>
                                    <PlaybackKey>{t('ICO.FINANCIAL.ARRLY')}</PlaybackKey>
                                    <PlaybackValue>{item.explainForNotEligibleApply}</PlaybackValue>
                                </>
                            }
                        </div>
                    ))}
                </PlaybackList>
            </ReviewNavigate>
            <ReviewNavigate title={t('ICO.USING.TITLE')} onClick={() => {
                onEdit('using');
            }} textMap={{
                editButton: t(REVIEW_EDIT)
            }}>
                <PlaybackList type="review" className="review">
                    <Heading level={3}>{t('ICO.USING.ANTICIPATED_USAGE_SECTION')}</Heading>
                    <PlaybackKey>{t('ICO.USING.USE_ACCOUNT')}</PlaybackKey>
                    <PlaybackValue>{getUseArrarText(usingData.selectAccountPurpose, useAccountList)}</PlaybackValue>
                    {usingData.selectAccountPurpose.OTHER && (
                        <>
                            <PlaybackKey>{t('ICO.USING.USE_ACCOUNT_OTHER')}</PlaybackKey>
                            <PlaybackValue>{usingData.reasonForAccountOpening}</PlaybackValue>
                        </>
                    )}
                    <Heading level={3}>{t('ICO.USING.ACCOUNT_FUNDING_SECTION')}</Heading>
                    {getFundingText(usingData.regularFundsDetailsObj, t('ICO.USING.MONTHLY_FUNDS'))}
                    <Heading level={3}>{t('ICO.USING.FIRST_DEPOSIT_SECTION')}</Heading>
                    <PlaybackKey>{t('ICO.USING.SOURCE_SAME')}</PlaybackKey>
                    <PlaybackValue>{getYesOrNo(usingData.sourceSame)}</PlaybackValue>
                    {
                        usingData.sourceSame === 'no' && getFundingText(usingData.firstDepositDetailsObj, t('ICO.USING.FIRST_DEPOSIT_SOURCE'))

                    }
                    <Heading level={3}>{t('ICO.USING.PARTIES_SECTION')}</Heading>
                    <PlaybackKey>{t('ICO.USING.RECEIVING_MONEY')}{t('ICO.USING.RECEIVING_MONEYS')}</PlaybackKey>
                    <PlaybackValue>{getYesOrNo(usingData.receivingMoney)}</PlaybackValue>
                    {
                        usingData.receivingMoney === 'yes' && <><PlaybackKey>{t('ICO.USING.MONTHLY_PAYMENTS')}</PlaybackKey>
                            <PlaybackValue>{t('ICO.FINANCIAL.HKD1')} {usingData.approximateDepositAmount}</PlaybackValue></>
                    }
                </PlaybackList>
            </ReviewNavigate>
            {
                isPremier && enableCreditCard && creditCardData.isApplyCreditCard === 'yes' &&
                <ReviewNavigate title={t('ICO.CREDIT.CARD')} onClick={() => {
                    onEdit('creditCard');
                }} textMap={{
                    editButton: t(REVIEW_EDIT)
                }}>
                    <PlaybackList type="review" className="review">
                        <Heading level={3}>{t('ICO.REVIEW.APP')}</Heading>
                        <PlaybackKey>{t('ICO.CREDIT.APPLICATION_Q1')}</PlaybackKey>
                        <PlaybackValue>{getYesOrNo(creditCardData.isApplyCreditCard)}</PlaybackValue>
                        {creditCardData.isApplyCreditCard === 'yes' &&
                            <>
                                <PlaybackKey>{t('ICO.CREDIT.APPLICATION_Q2')}</PlaybackKey>
                                <PlaybackValue>{getAllValue(creditCardTypeList, creditCardData.creditCardType)}</PlaybackValue>
                            </>
                        }
                        <Heading level={3}>{t('ICO.CREDIT.USING')}</Heading>
                        <PlaybackKey>{t('ICO.CREDIT.SETTLEMENT')}</PlaybackKey>
                        <PlaybackValue>{getYesOrNo(creditCardData.settlment)}</PlaybackValue>
                        {creditCardData.settlment === 'yes' &&
                            <>
                                <PlaybackKey>{t('ICO.CREDIT.PAYMENT')}</PlaybackKey>
                                <PlaybackValue>{getAllValue(cardList, creditCardData.settlmentValue)}</PlaybackValue>
                            </>
                        }
                        {creditCardData.settlmentValue &&
                            <>
                                <PlaybackKey>{t('ICO.CREDIT.PAYMENT1')}</PlaybackKey>
                                <PlaybackValue>{getAllValue(settlmentList, creditCardData.facillityValue)}</PlaybackValue>
                            </>
                        }
                        {creditCardData.facillityValue === '% of statement balance' &&
                            <>
                                <PlaybackKey>{t('ICO.CREDIT.PAYMENT2')}</PlaybackKey>
                                <PlaybackValue>{creditCardData.balanceValue}%</PlaybackValue>
                            </>
                        }
                        <>
                            <PlaybackKey>{t('ICO.CREDIT.FACILLITY')}</PlaybackKey>
                            <PlaybackValue>{getYesOrNo(creditCardData.facillity)}</PlaybackValue>
                        </>
                        <>
                            <PlaybackKey>{t('ICO.CREDIT.ACCOUNAT')}</PlaybackKey>
                            <PlaybackValue>{getYesOrNo(creditCardData.accounat)}</PlaybackValue>
                        </>
                        <>
                            <PlaybackKey>{t('ICO.REVIEW.TERMS1')}</PlaybackKey>
                            <PlaybackValue>{creditCardData.statementPreferencesOptions === '1' ? t('ICO.REVIEW.TERMS1_1') : t('ICO.REVIEW.TERMS1_2')}</PlaybackValue>
                        </>
                        <>
                            <PlaybackKey>{t('ICO.REVIEW.TERMS')}</PlaybackKey>
                            <PlaybackValue>{creditCardData.grpTermsAndConditions === '1' ? t('ICO.REVIEW.TERMS_1') : t('ICO.REVIEW.TERMS_2')}</PlaybackValue>
                        </>
                        <Heading level={3}>{t('ICO.REVIEW.APP1')}</Heading>
                        <PlaybackKey>{t('ICO.CREDIT.CURRENT5')}</PlaybackKey>
                        <PlaybackValue>{getYesOrNo(creditCardData.current3)}</PlaybackValue>
                        <Heading level={3}>{t('ICO.REVIEW.APP2')}</Heading>
                        <PlaybackKey>{t('ICO.CREDIT.RELATIVE2')}</PlaybackKey>
                        {creditCardData.current3 === 'yes' &&
                            <PlaybackValue>{creditCardData.relative2.one && t('ICO.CREDIT.YOU')}<br />{creditCardData.relative2.two && t('ICO.CREDIT.TAS')}</PlaybackValue>
                        }
                        {creditCardData.relative2.one &&
                            <>
                                <PlaybackKey>{t('ICO.CREDIT.RELATIVE3')}</PlaybackKey>
                                <PlaybackValue>{creditCardData.relative3}</PlaybackValue>
                                <PlaybackKey>{t('ICO.CREDIT.RELATIVE4')}</PlaybackKey>
                                <PlaybackValue>{creditCardData.relative4}</PlaybackValue>
                            </>
                        }
                        {creditCardData.relative2.two &&
                            <>
                                <PlaybackKey>{t('ICO.CREDIT.RELATIVE1')}</PlaybackKey>
                                <PlaybackValue>{creditCardData.relative}</PlaybackValue>
                                <PlaybackKey>{t('ICO.CREDIT.RELATIVE')}</PlaybackKey>
                                <PlaybackValue>{getAllValue(relative, creditCardData.relative1)}</PlaybackValue>
                            </>
                        }
                        <Heading level={2} accentBar>{t('ICO.CREDIT.RB')}</Heading>
                        <UnorderedList>
                            <ListItem>{t('ICO.CREDIT.RB_1')}</ListItem>
                            <ListItem>{t('ICO.CREDIT.RB_2')}</ListItem>
                            <ListItem>{t('ICO.CREDIT.RB_3')}</ListItem>
                        </UnorderedList>
                        <Paragraph>{t('ICO.CREDIT.RB_4')}</Paragraph>
                    </PlaybackList>
                </ReviewNavigate>
            }
            {
                isPremier && enableCreditCard && creditCardData.isApplyCreditCard === 'no' &&
                <ReviewNavigate title={t('ICO.CREDIT.CARD')} onClick={() => {
                    history.push('/info/CreditCard');
                }} textMap={{
                    editButton: t(REVIEW_EDIT)
                }}>
                    <PlaybackList type="review" className="review">
                        <Heading level={3}>{t('ICO.REVIEW.APP')}</Heading>
                        <PlaybackKey>{t('ICO.CREDIT.APPLICATION_Q1')}</PlaybackKey>
                        <PlaybackValue>{getYesOrNo(creditCardData.isApplyCreditCard)}</PlaybackValue>
                    </PlaybackList>
                </ReviewNavigate>
            }
            <Form
                initialValues={reviewData}
                validationSchema={schema}
            >
                {({ values }) => (
                    <>
                        {isPremier && enableCreditCard && creditCardData.isApplyCreditCard === 'yes' &&
                            <Field name="agreeRB" marginBottom={5}>
                                <Checkbox>{t('ICO.CREDIT.RB_CONFIRM')}</Checkbox>
                                <FieldFeedback />
                            </Field>
                        }
                        <ButtonWithBack onClick={() => {
                            (values.agreeRB || !isPremier || creditCardData.isApplyCreditCard === 'no') && history.push('/info/declaration')
                        }} />
                    </>
                )}
            </Form>
        </FormLayout >
    )
}

export default Review;
