import React from "react";
import {
    Section,
    Heading,
    HorizontalRule
} from "@piggybank/core";
import {Legend} from '@piggybank/form';

const Title = ({heading, legend}) => (

    <Section marginBottom={6}>
        <Heading level={1}>{heading}</Heading>
        <Legend marginBottom={4}>{legend}</Legend>
        <HorizontalRule/>
    </Section>
);

export default Title;
