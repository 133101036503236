import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Page from "../../components/Page";
import {
  Section,
  Heading,
  Paragraph,
  IconStatusConfirmationOnlight,
  FormLayout,
  HorizontalRule,
} from '@piggybank/core';
import { clearUserSessionData, extendUserSessionData, getUserSessionDataByKey } from 'storage/userStorage';
import { initFormData } from 'storage/page';
import { useTagging } from 'tealiumTrack';

const Happens = () => {
  const { t } = useTranslation();
  const { isPremier, lang } = getUserSessionDataByKey('global');
  useEffect(() => {
    clearUserSessionData();
    extendUserSessionData(initFormData);
  }, []);

  useTagging({
    'page_url': '/forms/application-complete',
    'page_language': lang,
    'page_name': 'pws:forms:application complete',
    'page_subcategory': 'application complete',
    'raw_datalayer': '5745v28',
    'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
    'funnel_step': '9',
    'funnel_step_name': 'application complete',
    'application_event': 'completed',
    'application_decision': 'completed'
  });

  return (
    <Page hasMargin={0}>
      <FormLayout>
        <Section marginBottom={3}>
          <div
            style={{
              color: 'var(--jade)'
            }}
          >
            <IconStatusConfirmationOnlight width="2.5em" />
          </div>
          <Heading level={1}>
            {t('ICO.HAPPENS.APPLICATION')}
          </Heading>
          <HorizontalRule />
        </Section>
        <Section>
          <Heading
            accentBar
            level={2}
          > {t('ICO.HAPPENS.NEXT')}
          </Heading>
          <Paragraph>
            {t('ICO.HAPPENS.PROCESS')}
          </Paragraph>
          <Paragraph>
            {t('ICO.HAPPENS.PROCESS1')}
          </Paragraph>
          <Paragraph>
            {t('ICO.HAPPENS.PROCESS2')}
          </Paragraph>
        </Section>
      </FormLayout>
    </Page>
  );
}
Happens.displayName = 'Happens';
export default Happens;
