export const checkBrowser = () => {
    const userAgent = navigator.userAgent;
    return {
        // iPhone && UCBrowser
        isUCBrowser: userAgent.indexOf('iPhone') > -1 && userAgent.indexOf('UCBrowser') > -1,
        // iPhone && QQBrowser
        isQQBrowser: userAgent.indexOf('iPhone') > -1 && userAgent.indexOf('QQBrowser') > -1,
        // >= IE11
        isIEBrowser: !!window.ActiveXObject || "ActiveXObject" in window
    }
}
