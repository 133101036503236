import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressIndicator } from '@piggybank/form';
import { getUserSessionDataByKey } from "../../storage/userStorage";

const WithI18nProgressIndicator = ({ current: c }) => {
  const { t } = useTranslation();
  const {isPremier, enableCreditCard} = getUserSessionDataByKey('global');
  return (
    <ProgressIndicator
      current={c}
      total={isPremier && enableCreditCard ? 7 : 6}
      renderStepText={({ current, total }) => t('ICO.COMMON.PROGRESS_TITLE', { current, total })}
    />
  )
};

export default WithI18nProgressIndicator;
