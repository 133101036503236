import React, { useEffect } from 'react';
import Title from "../../../components/Title/Title";
import { useHistory, useLocation } from 'react-router-dom';
import {
    Section,
    FormLayout,
    Heading,
    Paragraph,
    LinkTile,
    IconChevronRight
} from '@piggybank/core';
import { useTranslation } from "react-i18next";
import { setUserSessionDataByKey, extendUserSessionData, getUserSessionDataByKey, getUserSessionData } from 'storage/userStorage';
import { initFormData } from 'storage/page';
import { useTagging } from 'tealiumTrack';

const Language = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const tempAccountType = sessionStorage.getItem('AC_TYPE');
    const accountType =
        ((tempAccountType === 'PREMIER' ||
            location.pathname === '/toHKP' ||
            location.hash === '#toHKP'
        ) && 'HSBC Premier') ||
        'HSBC One';
    const isPremier = accountType === 'HSBC Premier';
    useEffect(() => {
        if (JSON.stringify(getUserSessionData()) === '{}' || !getUserSessionDataByKey('global').lang || !getUserSessionDataByKey('address')) {
            extendUserSessionData(initFormData);
        }
    })

    // tagging section
    useTagging({
        'page_url': '/forms/language-selection',
        'page_type': 'setting',
        'page_name': 'pws:forms:language selection',
        'page_subcategory': 'language selection',
        'raw_datalayer': '5745v1',
        'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
    });

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);

        setUserSessionDataByKey('global', {
            lang: lang,
            path: location.pathname,
            query: [{ accountType: tempAccountType }],
            accountType: accountType,
            isPremier: isPremier,
            enableCreditCard: true   // whether to enable the credit card page, default: true
        });

        history.push({ pathname: '/AccountType', query: { accountType: accountType } });
    };

    return (
        <FormLayout>
            <Title heading={t('ICO.LANGUAGE.TITLE')} legend={t('ICO.LANGUAGE.SUB_TITLE')} />
            <Section>
                <Heading accentBar level={2}>{t('ICO.LANGUAGE.SELECT_LANGUAGE')}</Heading>
                <Paragraph marginBottom={5}>{t('ICO.LANGUAGE.SELECT_LANGUAGE_DESC')}</Paragraph>
                <LinkTile
                    onClick={() => changeLanguage('zh_CN')}
                    displayStatus
                    opened={false}
                    heading={t('ICO.LANGUAGE.ZH_CN')}
                    iconSlot={<IconChevronRight inline />}
                    marginBottom={3}
                    textMap={
                        {
                            opened: '',
                            newWindow: '',
                            pleaseOpen: t('ICO.LANGUAGE.ZH_CN_TIP')
                        }
                    } />
                <LinkTile
                    onClick={() => {
                        changeLanguage('zh_HK')
                    }}
                    displayStatus
                    opened={false}
                    heading={t('ICO.LANGUAGE.ZH_HK')}
                    iconSlot={<IconChevronRight inline />}
                    marginBottom={3}
                    textMap={
                        {
                            opened: '',
                            newWindow: '',
                            pleaseOpen: t('ICO.LANGUAGE.ZH_HK_TIP')
                        }
                    } />
                <LinkTile
                    onClick={() => changeLanguage('en')}
                    displayStatus
                    opened={false}
                    heading={t('ICO.LANGUAGE.EN')}
                    iconSlot={<IconChevronRight inline />}
                    marginBottom={3}
                    textMap={
                        {
                            opened: '',
                            newWindow: '',
                            pleaseOpen: t('ICO.LANGUAGE.EN_TIP')
                        }
                    } />
            </Section>
        </FormLayout>
    );
}

export default Language;
