export default {
    en: [
        {value: 'Mr', label: 'Mr'},
        {value: 'Mrs', label: 'Mrs'},
        {value: 'Ms', label: 'Ms'},
        {value: 'Miss', label: 'Miss'},
        {value: 'Others', label: 'Dr'}
    ],
    zh_CN: [
        {value: 'Mr', label: '先生'},
        {value: 'Mrs', label: '太太'},
        {value: 'Ms', label: '女士'},
        {value: 'Miss', label: '小姐'},
        {value: 'Others', label: '博士'}
    ],
    zh_HK: [
        {value: 'Mr', label: '先生'},
        {value: 'Mrs', label: '太太'},
        {value: 'Ms', label: '女士'},
        {value: 'Miss', label: '小姐'},
        {value: 'Others', label: '博士'}
    ]
}
