export const timeDifferenceLessOneYear = (d1) => {
    const d1Time = new Date(d1).setMonth(new Date(d1).getMonth() + 13);
    return +new Date() - d1Time < 0;
}


export const timeAtLeast18Years = (d1) => {
    const d1Time = new Date(d1).setFullYear(new Date(d1).getFullYear() + 18);
    return +new Date() - d1Time <= 0;
}

export const timeDiffMaintenance = (startDate, endDate) => {
    const HKTimeZone = -8 * 60; //Time zone in Hong Kong (GMT+8), Minutes
    const diffTimestamp = (HKTimeZone - new Date().getTimezoneOffset()) * 60 * 1000;
    const localTimestamp = new Date().valueOf();
    const startTimestamp = new Date(startDate.replace(/-/g, '/')).valueOf() + diffTimestamp;
    const endTimestamp = new Date(endDate.replace(/-/g, '/')).valueOf() + diffTimestamp;

    return {
        status: localTimestamp - startTimestamp > 0 && endTimestamp - localTimestamp > 0,
        startFormatStr: format(startTimestamp),
        endFormatStr: format(endTimestamp)
    };
}

export const format = (dateStr) => {
    const date = new Date(dateStr);
    const YY = date.getFullYear();
    const MM = 9 > date.getMonth() ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const DD = 10 > date.getDate() ? '0' + date.getDate() : date.getDate()
    const HH = 10 > date.getHours() ? '0' + date.getHours() : date.getHours();
    const mm = 10 > date.getMinutes() ? '0' + date.getMinutes() : date.getMinutes();
    const ss = 10 > date.getSeconds() ? '0' + date.getSeconds() : date.getSeconds();
    return `${[YY, MM, DD].join('-')} ${[HH, mm, ss].join(':')}`;
}
