import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { getUserSessionDataByKey } from 'storage/userStorage';

const fallbackLng = ['en', 'zh_CN', 'zh_HK'];
let lng = '';

if (getUserSessionDataByKey('global')?.lang) {
    lng = getUserSessionDataByKey('global').lang;
} else {
    lng = window?.navigator?.language ? window.navigator.language.replace('-', '_') : 'en';
    if (/_/.test(lng)) {
        const lngs = lng.split('_');
        lng = `${lngs[0]}_${lngs[1].toLocaleUpperCase()}`;
    }
    if (!fallbackLng.includes(lng)) {
        lng = 'en';
    }
}

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng,
        fallbackLng,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: './locales/{{lng}}.json'
        }
    });

export default i18n;
