import { getUserSessionDataByKey } from "storage/userStorage";

const fallbackLng = ['en', 'zh_CN', 'zh_HK'];
let lng = '';
if (getUserSessionDataByKey('global')?.lang) {
    lng = getUserSessionDataByKey('global').lang;
} else {
    lng = window?.navigator?.language ? window.navigator.language.replace('-', '_') : 'en';

    if (!fallbackLng.includes(lng)) {
        lng = 'en';
    }
}

const ispremier = sessionStorage.getItem('AC_TYPE') || /accountType=PREMIER/.test(window.location.hash);

export default {
    'site_section': 'pws',
    'page_language': lng,
    'page_customer_group': 'general',
    'page_security_level': '0',
    'page_category': 'forms',
    'customer_group': ispremier ? 'premier' : 'one',
}
