import { loadScript } from "utils/load";

export const loadTagging = (args, isLink) => {
    let scriptUrl = isLink ? './tealiumTrack/link.js' : './tealiumTrack/view.js';
    let params = '';
    for (const key in args) {
        if (Reflect.has(args, key) && args[key]) {
            params += `&${key}=${args[key].replace(/%/g, 'SIGN')}`;
        }
    }
    scriptUrl = `${scriptUrl}?${params.slice(1)}`;
    loadScript(scriptUrl);
}
