import { accountPurpose as useAccountList, funding as fundingList } from 'referenceData';

const selectAccountPurpose = {};
for (const item of useAccountList) {
    selectAccountPurpose[item.value] = false;
}

const fundingObj = {};
for (const item of fundingList) {
    const children = {};
    for (const child of item.children) {
        children[child.value] = {
            value: false
        }

        if (child.isCountry) {
            children[child.value].country = [];
            children[child.value].disabled = false;
        }

        if (child.isOther) {
            children[child.value].other = '';
        }
    }

    fundingObj[item.value] = {
        value: false,
        children
    };
}

export default {
    using: {
        selectAccountPurpose,
        regularFundsDetailsObj: fundingObj,
        reasonForAccountOpening: '',
        sourceSame: '',
        firstDepositDetailsObj: fundingObj,
        firstDepositAmount: '',
        receivingMoney: '',
        approximateDepositAmount: ''
    },
}
