import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductBasket from '../../components/ProdectBasket';
import Page from "../../components/Page";
import {
  Section,
  Heading,
  Paragraph,
  IconStatusInformationOnlight,
  FormLayout,
  Button,
  Link,
  Loading
} from '@piggybank/core';
import { userFetch } from 'utils/util';
import { clearUserSessionData, extendUserSessionData, getUserSessionDataByKey } from 'storage/userStorage';
import { useHistory } from 'react-router-dom';
import { initFormData } from 'storage/page';
import { useTagging, linkTagging, globalViewTaggingParams, loadTagging } from 'tealiumTrack';

const TAG_PAGE_URL = '/forms/technical-problem';
const TAG_CONTENT = 'technical problem';
const TAG_CONTENT2 = 'technical problem 2';
const TAG_PAGE_NAME = 'pws:forms:technical problem';


const Technicals = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [tryCount, setTryCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { isPremier, lang } = getUserSessionDataByKey('global');

  const tryAgain = () => {
    setLoading(true);

    loadTagging({
      ...globalViewTaggingParams,
      'page_url': '/forms/processing',
      'page_language': lang,
      'page_name': 'pws:forms:processing',
      'page_subcategory': 'processing',
      'raw_datalayer': '5745v27',
      'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
      'funnel_step': '8',
      'funnel_step_name': 'processing',
      'application_event': 'submitted'
    });

    linkTagging({
      'page_url': TAG_PAGE_URL,
      'page_language': lang,
      'event_category': 'content',
      'event_action': 'button',
      'event_content': 'retry',
      'page_name': TAG_PAGE_NAME,
      'raw_datalayer': '5745v32'
    });

    userFetch()
      .then(() => {
        setLoading(false);
        history.push('/happens');
      }).catch(() => {
        setLoading(false);
        setTryCount(tryCount + 1);
        if (tryCount > 0) {
          clearUserSessionData();
          extendUserSessionData(initFormData);
        }
      })
  }

  useTagging({
    'page_url': tryCount === 2 ? '/forms/technical-problem-2' : TAG_PAGE_URL,
    'page_language': lang,
    'event_category': 'error',
    'event_subcategory': `error ${tryCount}`,
    'event_action': 'system',
    'event_content': tryCount === 2 ? TAG_CONTENT2 : TAG_CONTENT,
    'page_type': 'error',
    'page_name': tryCount === 2 ? 'pws:forms:technical problem 2' : TAG_PAGE_NAME,
    'page_subcategory': tryCount === 2 ? TAG_CONTENT2 : TAG_CONTENT,
    'raw_datalayer': tryCount === 2 ? '5745v34' : '5745v31',
    'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
    'funnel_step_name': tryCount === 2 ? TAG_CONTENT2 : TAG_CONTENT
  }, [tryCount]);
  return (
    <>
      <Loading show={loading} getApplicationNode={() => document.getElementById('root')} >
        <Heading level={3}>
          {t('ICO.HAPPENS.PROCESSING')}
        </Heading>
        <Paragraph marginBottom={0}>
          {t('ICO.HAPPENS.BACK')}
        </Paragraph>
      </Loading>
      <Page hasMargin={0}>
        <ProductBasket />
        <FormLayout>
          <Section marginBottom={3}>
            <div
              style={{
                color: 'var(--sapphire)'
              }}
            >
              <IconStatusInformationOnlight width="2.5em" />
            </div>
            <Heading level={1}>
              {t('ICO.HAPPENS.FACK')}
            </Heading>
            <Paragraph>
              {t('ICO.HAPPENS.FEW')}
            </Paragraph>
          </Section>
          <Section>
            <Heading
              accentBar
              level={2}
            > {t('ICO.HAPPENS.CAN')}
            </Heading>
            <Paragraph>
              {t('ICO.HAPPENS.US')}
            </Paragraph>
            <Paragraph>
              {t('ICO.HAPPENS.CAN1')}
            </Paragraph>
            <Paragraph>
              <Link
              href="mailto:subject&body= mail body"
              iconSlotLeft={<span style={{ color: 'var(--red)' }}></span>}
              onClick={() => {
                linkTagging({
                  'page_url': tryCount === 2 ? '/forms/technical-problem-2' : TAG_PAGE_URL,
                  'page_language': lang,
                  'event_category': 'content',
                  'event_action': 'onsite',
                  'event_content': 'international onboarding',
                  'page_name': tryCount === 2 ? 'pws:forms:technical problem 2' : TAG_PAGE_NAME,
                  'raw_datalayer': tryCount === 2 ? '5745v35' : '5745v33',
                });
              }}
              >{t('ICO.HAPPENS.INTER')}</Link>
            </Paragraph>
            <Paragraph>
              {t('ICO.HAPPENS.FEW1')}
            </Paragraph>
          </Section>
          {
            tryCount < 2 && <Button onClick={tryAgain}>{t('ICO.HAPPENS.TRY')}</Button>
          }

        </FormLayout>
      </Page>
    </>
  );
}
Technicals.displayName = 'Technicals';
export default Technicals;
