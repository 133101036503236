import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductBasket from '../../components/ProdectBasket';
import Page from "../../components/Page";
import {
  Section,
  Heading,
  Paragraph,
  IconStatusInformationOnlight,
  FormLayout,
  Link,
} from '@piggybank/core';

const NotificationPage = () => {
  const { t } = useTranslation();
  return (
    <Page hasMargin={0}>
      <ProductBasket />
      <FormLayout>
        <Section marginBottom={3}>
          <div
            style={{
              color: 'var(--sapphire)'
            }}
          >
            <IconStatusInformationOnlight width="2.5em" />
          </div>
          <Heading level={1}>
            {t('ICO.COMMON.SORRY')}
          </Heading>
        </Section>
        <Section>
          <Paragraph>
            {t('ICO.HAPPENS.UNAVAILABLE')}
          </Paragraph>
          <Heading
            accentBar
            level={2}
          > {t('ICO.HAPPENS.CAN')}
          </Heading>
          <Paragraph>
            {t('ICO.HAPPENS.MAKE')}
          </Paragraph>
          <p style={{ fontWeight: 'bold' }}>
            {t('ICO.HAPPENS.CENTER')}
          </p>
          <p style={{ marginBottom: '1px' }}>
            {t('ICO.HAPPENS.IPHONE')}
          </p>
          <Paragraph>
            <Link href="mailto:subject&body= mail body" iconSlotLeft={<span style={{ color: 'var(--red)' }}></span>} >{t('ICO.HAPPENS.INTER')}</Link>
          </Paragraph>
        </Section>
      </FormLayout>
    </Page>
  );
}

export default NotificationPage;
