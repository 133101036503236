export const userSessionDataKey = 'user-session-data';

export const verifyLegalKey = key => !!key && typeof key === 'string';

export const setUserSessionData = (data = null) => sessionStorage.setItem(userSessionDataKey, JSON.stringify(data));

export const setUserSessionDataByKey = (key, value = '') => {
  if (!verifyLegalKey(key)) {
    return;
  }

  const userData = {
    ...getUserSessionData(),
    [key]: value
  };

  setUserSessionData(userData);
};

export const extendUserSessionData = (data = {}) => {
  const userData = {
    ...getUserSessionData(),
    ...data
  };
  setUserSessionData(userData);
};

export const getUserSessionData = () => JSON.parse(sessionStorage.getItem(userSessionDataKey) || '{}');

export const getUserSessionDataByKey = key => {
  if (!verifyLegalKey(key)) {
    return;
  }

  return getUserSessionData()[key] || {};
};

export const clearUserSessionData = () => sessionStorage.removeItem(userSessionDataKey);
