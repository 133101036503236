export default {
    "zh_CN": {
        "CN": "中国内地",
        "HK": "香港特别行政区",
        "MO": "澳门特别行政区",
        "AD": "安道尔",
        "AF": "阿富汗",
        "AG": "安提瓜和巴布达",
        "AI": "安圭拉岛",
        "AL": "阿尔巴尼亚",
        "AM": "亚美尼亚",
        "AO": "安哥拉",
        "AR": "阿根廷",
        "AS": "美属萨摩亚群岛",
        "AT": "奥地利",
        "AU": "澳大利亚",
        "AW": "阿鲁巴岛",
        "AX": "奥兰群岛",
        "AZ": "阿塞拜疆",
        "DZ": "阿尔及利亚",
        "BA": "波斯尼亚和黑塞哥维那",
        "BB": "巴巴多斯",
        "BD": "孟加拉国",
        "BE": "比利时",
        "BF": "布基纳法索",
        "BG": "保加利亚",
        "BH": "巴林",
        "BI": "布隆迪",
        "BJ": "贝宁",
        "BM": "百慕大群岛",
        "BN": "文莱达鲁萨兰国",
        "BO": "玻利维亚",
        "BQ": "博奈尔岛、圣尤斯特歇斯岛和萨巴岛",
        "BR": "巴西",
        "BS": "巴哈马",
        "BT": "不丹",
        "BV": "布维岛",
        "BW": "博茨瓦纳",
        "BY": "白俄罗斯",
        "BZ": "伯利兹",
        "AQ": "南极洲",
        "IO": "英属印度洋领地",
        "VG": "英属维尔京群岛",
        "CA": "加拿大",
        "CC": "科科斯（基林）群岛",
        "CD": "刚果民主共和国",
        "CF": "中非共和国",
        "CG": "刚果",
        "CI": "科特迪亚",
        "CK": "库克群岛",
        "CL": "智利",
        "CM": "喀麦隆",
        "CO": "哥伦比亚",
        "CR": "哥斯达黎加",
        "CU": "古巴",
        "CV": "佛得角",
        "CW": "库拉索岛",
        "CX": "圣诞岛",
        "CY": "塞浦路斯",
        "CZ": "捷克共和国",
        "HR": "克罗地亚",
        "KH": "柬埔寨",
        "KM": "科摩罗",
        "KY": "开曼群岛",
        "TD": "乍得",
        "DJ": "吉布提",
        "DK": "丹麦",
        "DM": "多米尼克",
        "DO": "多米尼加共和国",
        "EC": "厄瓜多尔",
        "EE": "爱沙尼亚",
        "EG": "埃及",
        "ER": "厄立特里亚",
        "ET": "埃塞俄比亚",
        "GQ": "赤道几内亚",
        "SV": "萨尔瓦多",
        "FI": "芬兰",
        "FJ": "斐济",
        "FK": "福克兰群岛（马尔维纳斯群岛）",
        "FO": "法罗群岛",
        "FR": "法国",
        "GF": "法属圭亚那",
        "PF": "法属玻里尼西亚",
        "TF": "法属南半球领地",
        "GA": "加蓬",
        "GD": "格林纳达",
        "GE": "格鲁吉亚",
        "GG": "根西岛",
        "GH": "加纳",
        "GI": "直布罗陀",
        "GL": "格陵兰",
        "GM": "冈比亚",
        "GN": "几内亚",
        "GP": "瓜德罗普岛",
        "GR": "希腊",
        "GT": "危地马拉",
        "GU": "关岛",
        "GW": "几内亚比绍",
        "GY": "圭亚那",
        "DE": "德国",
        "HM": "赫德和麦克唐纳群岛",
        "HN": "洪都拉斯",
        "HT": "海地",
        "HU": "匈牙利",
        "ID": "印度尼西亚",
        "IE": "爱尔兰共和国",
        "IL": "以色列",
        "IM": "英属曼岛",
        "IN": "印度",
        "IQ": "伊拉克",
        "IR": "伊朗（伊斯兰共和国）",
        "IS": "冰岛",
        "IT": "意大利",
        "JE": "泽西岛",
        "JM": "牙买加",
        "JO": "约旦",
        "JP": "日本",
        "KE": "肯尼亚",
        "KG": "吉尔吉斯斯坦",
        "KI": "基里巴斯共和国",
        "KP": "朝鲜民主主义人民共和国（北）",
        "KR": "大韩民国（南）",
        "KW": "科威特",
        "KZ": "哈萨克斯坦",
        "LA": "老挝人民民主共和国",
        "LB": "黎巴嫩",
        "LI": "列支敦斯登",
        "LR": "利比里亚",
        "LS": "莱索托",
        "LT": "立陶宛",
        "LU": "卢森堡",
        "LV": "拉脱维亚",
        "LY": "利比亚",
        "MA": "摩洛哥",
        "MC": "摩纳哥",
        "MD": "摩尔多瓦共和国",
        "ME": "黑山",
        "MG": "马达加斯加",
        "MH": "马绍尔群岛",
        "MK": "前南斯拉夫马其顿共和国",
        "ML": "马里",
        "MM": "缅甸",
        "MN": "蒙古",
        "MQ": "马提尼克",
        "MR": "毛里塔尼亚",
        "MS": "蒙特塞拉特",
        "MT": "马耳他",
        "MU": "毛里求斯",
        "MV": "马尔代夫",
        "MW": "马拉维",
        "MX": "墨西哥",
        "MY": "马来西亚",
        "MZ": "莫桑比克",
        "FM": "密克罗尼西亚联邦",
        "YT": "马约特岛",
        "NA": "纳米比亚",
        "NC": "新喀里多尼亚",
        "NE": "尼日尔",
        "NF": "诺福克岛",
        "NG": "尼日利亚",
        "NI": "尼加拉瓜",
        "NL": "荷兰",
        "NO": "挪威",
        "NP": "尼泊尔",
        "NR": "瑙鲁",
        "NU": "纽埃",
        "NZ": "新西兰",
        "MP": "北马里亚纳群岛",
        "OM": "阿曼",
        "PA": "巴拿马",
        "PE": "秘鲁",
        "PG": "巴布亚新几内亚",
        "PH": "菲律宾",
        "PK": "巴基斯坦",
        "PL": "波兰",
        "PN": "皮特凯恩群岛",
        "PR": "波多黎各",
        "PS": "巴勒斯坦领土",
        "PT": "葡萄牙",
        "PW": "帕劳",
        "PY": "巴拉圭",
        "QA": "卡塔尔",
        "RE": "留尼汪岛",
        "RO": "罗马尼亚",
        "RU": "俄罗斯联邦(非克里米亚地区)",
        "Z8": "俄罗斯联邦(克里米亚地区)",
        "RW": "卢旺达",
        "SA": "沙特阿拉伯",
        "SB": "所罗门群岛",
        "SC": "塞舌尔",
        "SD": "苏丹",
        "SE": "瑞典",
        "SG": "新加坡",
        "SH": "圣赫勒拿",
        "SI": "斯洛文尼亚",
        "SJ": "斯瓦尔巴特和扬马延",
        "SK": "斯洛伐克",
        "SL": "塞拉利昂",
        "SM": "圣马力诺",
        "SN": "塞内加尔",
        "SO": "索马里",
        "SR": "苏里南",
        "SS": "南苏丹",
        "ST": "圣多美和普林西比",
        "SX": "圣马丁",
        "SY": "阿拉伯叙利亚共和国",
        "SZ": "斯威士兰",
        "BL": "加勒比海圣巴特岛",
        "CH": "瑞士",
        "ES": "西班牙",
        "GS": "南佐治亚和南桑威奇岛",
        "KN": "圣基茨和尼维斯",
        "LC": "圣卢西亚",
        "LK": "斯里兰卡",
        "MF": "法属圣马丁",
        "PM": "圣皮埃尔和密克隆群岛",
        "RS": "塞尔维亚",
        "VC": "圣文森特",
        "WS": "萨摩亚",
        "ZA": "南非",
        "TC": "特克斯和凯科斯群岛",
        "TG": "多哥",
        "TH": "泰国",
        "TJ": "塔吉克斯坦",
        "TK": "托克劳群岛",
        "TL": "东帝汶岛",
        "TM": "土库曼斯坦",
        "TN": "突尼斯",
        "TO": "汤加",
        "TR": "土耳其",
        "TT": "特立尼达和多巴哥",
        "TV": "图鲁瓦",
        "TW": "台湾地区",
        "TZ": "坦桑尼亚联合共和国",
        "UA": "乌克兰(非克里米亚地区)",
        "Z9": "乌克兰(克里米亚地区)",
        "UG": "乌干达",
        "UM": "美国本土外小岛屿",
        "US": "美国",
        "UY": "乌拉圭",
        "UZ": "乌兹别克斯坦",
        "AE": "阿联酋",
        "GB": "英国",
        "VI": "美属维尔京群岛",
        "VA": "梵蒂冈城国（罗马教廷）",
        "VE": "委内瑞拉",
        "VN": "越南",
        "VU": "瓦努阿图",
        "WF": "瓦利斯和富图纳群岛",
        "EH": "西撒哈拉",
        "YE": "也门",
        "ZM": "赞比亚",
        "ZW": "津巴布韦",
        "XK": "科索沃"
    },
    'en': {
        "CN": "Mainland China",
        "HK": "Hong Kong SAR",
        "MO": "Macau SAR",
        "AD": "Andorra",
        "AF": "Afghanistan",
        "AG": "Antigua And Barbuda",
        "AI": "Anguilla",
        "AL": "Albania",
        "AM": "Armenia",
        "AO": "Angola",
        "AR": "Argentina",
        "AS": "American Samoa",
        "AT": "Austria",
        "AU": "Australia",
        "AW": "Aruba",
        "AX": "Aland Islands",
        "AZ": "Azerbaijan",
        "DZ": "Algeria",
        "BA": "Bosnia And Herzegovina",
        "BB": "Barbados",
        "BD": "Bangladesh",
        "BE": "Belgium",
        "BF": "Burkina Faso",
        "BG": "Bulgaria",
        "BH": "Bahrain",
        "BI": "Burundi",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BN": "Brunei Darussalam",
        "BO": "Bolivia",
        "BQ": "Bonaire, Sint Eustatius and Saba",
        "BR": "Brazil",
        "BS": "Bahamas",
        "BT": "Bhutan",
        "BV": "Bouvet Islands",
        "BW": "Botswana",
        "BY": "Belarus",
        "BZ": "Belize",
        "AQ": "British Antarctic",
        "IO": "British Indian Ocean Territories",
        "VG": "British Virgin Islands",
        "CA": "Canada",
        "CC": "Cocos (Keeling) Islands",
        "CD": "Congo, The Democratic Republic",
        "CF": "Central African Republic",
        "CG": "Congo",
        "CI": "Cote D Ivoire",
        "CK": "Cook Islands",
        "CL": "Chile",
        "CM": "Cameroon",
        "CO": "Colombia",
        "CR": "Costa Rica",
        "CU": "Cuba",
        "CV": "Cape Verde",
        "CW": "Curacao",
        "CX": "Christmas Islands",
        "CY": "Cyprus",
        "CZ": "Czech Republic",
        "HR": "Croatia",
        "KH": "Cambodia",
        "KM": "Comoros",
        "KY": "Cayman Islands",
        "TD": "Chad",
        "DJ": "Djibouti",
        "DK": "Denmark",
        "DM": "Dominica",
        "DO": "Dominican Republic",
        "EC": "Ecuador",
        "EE": "Estonia",
        "EG": "Egypt",
        "ER": "Eritrea",
        "ET": "Ethiopia",
        "GQ": "Equatorial Guinea",
        "SV": "El Salvador",
        "FI": "Finland",
        "FJ": "Fiji",
        "FK": "Falkland Islands",
        "FO": "Faeroe Islands",
        "FR": "France",
        "GF": "French Guiana",
        "PF": "French Polynesia",
        "TF": "French Southern Territories",
        "GA": "Gabon",
        "GD": "Grenada",
        "GE": "Georgia",
        "GG": "Guernsey",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GL": "Greenland",
        "GM": "Gambia",
        "GN": "Guinea",
        "GP": "Guadeloupe",
        "GR": "Greece",
        "GT": "Guatemala",
        "GU": "Guam",
        "GW": "Guinea Bissau",
        "GY": "Guyana",
        "DE": "Germany",
        "HM": "HEARD A MCDONALD ISLANDS",
        "HN": "Honduras",
        "HT": "Haiti",
        "HU": "Hungary",
        "ID": "Indonesia",
        "IE": "Ireland, Republic Of",
        "IL": "Israel",
        "IM": "Isle Of Man",
        "IN": "India",
        "IQ": "Iraq",
        "IR": "Iran, Islamic Republic Of",
        "IS": "Iceland",
        "IT": "Italy",
        "JE": "Jersey",
        "JM": "Jamaica",
        "JO": "Jordan",
        "JP": "Japan",
        "KE": "Kenya",
        "KG": "Kyrgyzstan",
        "KI": "Kiribati Republic",
        "KP": "Korea, Dem Peoples Rep Of(North)",
        "KR": "Korea, Republic Of (South)",
        "KW": "Kuwait",
        "KZ": "Kazakhstan",
        "LA": "Lao Peoples Democratic Republic",
        "LB": "Lebanon",
        "LI": "Liechtenstein",
        "LR": "Liberia",
        "LS": "Lesotho",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "LV": "Latvia",
        "LY": "Libyan Arab Jamahiriya",
        "MA": "Morocco",
        "MC": "Monaco",
        "MD": "Moldova, Republic Of",
        "ME": "Montenegro",
        "MG": "Madagascar",
        "MH": "Marshall Islands",
        "MK": "Macedonia, Former Yugoslav Rep",
        "ML": "Mali",
        "MM": "Myanmar",
        "MN": "Mongolia",
        "MQ": "Martinique",
        "MR": "Mauritania",
        "MS": "Montserrat",
        "MT": "Malta",
        "MU": "Mauritius",
        "MV": "Maldives",
        "MW": "Malawi",
        "MX": "Mexico",
        "MY": "Malaysia",
        "MZ": "Mozambique",
        "FM": "Micronesia, Federated States Of",
        "YT": "Mayotte",
        "NA": "Namibia",
        "NC": "New Caledonia",
        "NE": "Niger",
        "NF": "Norfolk Islands",
        "NG": "Nigeria",
        "NI": "Nicaragua",
        "NL": "Netherlands",
        "NO": "Norway",
        "NP": "Nepal",
        "NR": "Nauru",
        "NU": "Niue",
        "NZ": "New Zealand",
        "MP": "Northern Mariana Islands",
        "OM": "Oman",
        "PA": "Panama",
        "PE": "Peru",
        "PG": "Papua New Guinea",
        "PH": "Philippines",
        "PK": "Pakistan",
        "PL": "Poland",
        "PN": "Pitcairn Islands",
        "PR": "Puerto Rico",
        "PS": "Palestinian Territory",
        "PT": "Portugal",
        "PW": "Palau",
        "PY": "Paraguay",
        "QA": "Qatar",
        "RE": "Reunion",
        "RO": "Romania",
        "RU": "Russian ,Federation(Non-Crimea area)",
        "Z8": "Russian ,Federation(Crimea region)",
        "RW": "Rwanda",
        "SA": "Saudi Arabia",
        "SB": "Solomon Islands",
        "SC": "Seychelles",
        "SD": "Sudan",
        "SE": "Sweden",
        "SG": "Singapore",
        "SH": "Saint Helena",
        "SI": "Slovenia",
        "SJ": "Svalbard And Jan Mayen",
        "SK": "Slovakia",
        "SL": "Sierra Leone",
        "SM": "San Marino",
        "SN": "Senegal",
        "SO": "Somalia",
        "SR": "Suriname",
        "SS": "South Sudan",
        "ST": "Sao Tome And Prin.",
        "SX": "Sint Maarten",
        "SY": "Syrian Arab Republic",
        "SZ": "Swaziland",
        "BL": "Saint Barthelemy",
        "CH": "Switzerland",
        "ES": "Spain",
        "GS": "SOUTH GEORGIA A SOUTH SANDWICH",
        "KN": "ST KITTS A NEVIS",
        "LC": "Saint Lucia",
        "LK": "Sri Lanka",
        "MF": "Saint Martin",
        "PM": "Saint Pierre And Miquelon",
        "RS": "Serbia",
        "VC": "Saint Vincent",
        "WS": "Samoa",
        "ZA": "South Africa",
        "TC": "TURKSACAICOS ISLANDS",
        "TG": "Togo",
        "TH": "Thailand",
        "TJ": "Tajikistan",
        "TK": "Tokelau",
        "TL": "Timor-Leste",
        "TM": "Turkmenistan",
        "TN": "Tunisia",
        "TO": "Tonga",
        "TR": "Turkey",
        "TT": "TRINIDAD A TOBAGO",
        "TV": "Tuvalu",
        "TW": "Taiwan",
        "TZ": "Tanzania, United Republic Of",
        "UA": "Ukraine(Non-Crimea area)",
        "Z9": "Ukraine(Crimea region)",
        "UG": "Uganda",
        "UM": "US Minor Outlying Islands",
        "US": "United States Of America",
        "UY": "Uruguay",
        "UZ": "Uzbekistan",
        "AE": "United Arab Emirates",
        "GB": "United Kingdom",
        "VI": "U S Virgin Islands",
        "VA": "Vatican City State (Holy See)",
        "VE": "Venezuela",
        "VN": "Vietnam",
        "VU": "Vanuatu",
        "WF": "Wallis And Futuna",
        "EH": "Western Sahara",
        "YE": "Yemen",
        "ZM": "Zambia",
        "ZW": "Zimbabwe",
        "XK": "Kosovo"
    },
    'zh_HK': {
        "CN": "中國內地",
        "HK": "香港特別行政區",
        "MO": "澳門特別行政區",
        "AD": "安道爾",
        "AF": "阿富汗",
        "AG": "安提瓜和巴布達",
        "AI": "安圭拉島",
        "AL": "阿爾巴尼亞",
        "AM": "亞美尼亞",
        "AO": "安哥拉",
        "AR": "阿根廷",
        "AS": "美屬薩摩亞群島",
        "AT": "奧地利",
        "AU": "澳大利亞",
        "AW": "阿魯巴島",
        "AX": "奧蘭群島",
        "AZ": "阿塞拜疆",
        "DZ": "阿爾及利亞",
        "BA": "波斯尼亞和黑塞哥維那",
        "BB": "巴巴多斯",
        "BD": "孟加拉國",
        "BE": "比利時",
        "BF": "布基納法索",
        "BG": "保加利亞",
        "BH": "巴林",
        "BI": "布隆迪",
        "BJ": "貝寧",
        "BM": "百慕大群島",
        "BN": "文萊達魯薩蘭國",
        "BO": "玻利維亞",
        "BQ": "博奈爾島、聖尤斯特歇斯島和薩巴島",
        "BR": "巴西",
        "BS": "巴哈馬",
        "BT": "不丹",
        "BV": "布維島",
        "BW": "博茨瓦納",
        "BY": "白俄羅斯",
        "BZ": "伯利茲",
        "AQ": "南極洲",
        "IO": "英屬印度洋領地",
        "VG": "英屬維爾京群島",
        "CA": "加拿大",
        "CC": "科科斯（基林）群島",
        "CD": "剛果民主共和國",
        "CF": "中非共和國",
        "CG": "剛果",
        "CI": "科特迪亞",
        "CK": "庫克群島",
        "CL": "智利",
        "CM": "喀麥隆",
        "CO": "哥倫比亞",
        "CR": "哥斯達黎加",
        "CU": "古巴",
        "CV": "佛得角",
        "CW": "庫拉索島",
        "CX": "聖誕島",
        "CY": "塞浦路斯",
        "CZ": "捷克共和國",
        "HR": "克羅地亞",
        "KH": "柬埔寨",
        "KM": "科摩羅",
        "KY": "開曼群島",
        "TD": "乍得",
        "DJ": "吉布提",
        "DK": "丹麥",
        "DM": "多米尼克",
        "DO": "多米尼加共和國",
        "EC": "厄瓜多爾",
        "EE": "愛沙尼亞",
        "EG": "埃及",
        "ER": "厄立特裏亞",
        "ET": "埃塞俄比亞",
        "GQ": "赤道幾內亞",
        "SV": "薩爾瓦多",
        "FI": "芬蘭",
        "FJ": "斐濟",
        "FK": "福克蘭群島（馬爾維納斯群島）",
        "FO": "法羅群島",
        "FR": "法國",
        "GF": "法屬圭亞那",
        "PF": "法屬玻裏尼西亞",
        "TF": "法屬南半球領地",
        "GA": "加蓬",
        "GD": "格林納達",
        "GE": "格魯吉亞",
        "GG": "根西島",
        "GH": "加納",
        "GI": "直布羅陀",
        "GL": "格陵蘭",
        "GM": "岡比亞",
        "GN": "幾內亞",
        "GP": "瓜德羅普島",
        "GR": "希臘",
        "GT": "危地馬拉",
        "GU": "關島",
        "GW": "幾內亞比紹",
        "GY": "圭亞那",
        "DE": "德國",
        "HM": "赫德和麥克唐納群島",
        "HN": "洪都拉斯",
        "HT": "海地",
        "HU": "匈牙利",
        "ID": "印度尼西亞",
        "IE": "愛爾蘭共和國",
        "IL": "以色列",
        "IM": "英屬曼島",
        "IN": "印度",
        "IQ": "伊拉克",
        "IR": "伊朗（伊斯蘭共和國）",
        "IS": "冰島",
        "IT": "意大利",
        "JE": "澤西島",
        "JM": "牙買加",
        "JO": "約旦",
        "JP": "日本",
        "KE": "肯尼亞",
        "KG": "吉爾吉斯斯坦",
        "KI": "基裏巴斯共和國",
        "KP": "朝鮮民主主義人民共和國（北）",
        "KR": "大韓民國（南）",
        "KW": "科威特",
        "KZ": "哈薩克斯坦",
        "LA": "老撾人民民主共和國",
        "LB": "黎巴嫩",
        "LI": "列支敦斯登",
        "LR": "利比裏亞",
        "LS": "萊索托",
        "LT": "立陶宛",
        "LU": "盧森堡",
        "LV": "拉脫維亞",
        "LY": "利比亞",
        "MA": "摩洛哥",
        "MC": "摩納哥",
        "MD": "摩爾多瓦共和國",
        "ME": "黑山",
        "MG": "馬達加斯加",
        "MH": "馬紹爾群島",
        "MK": "前南斯拉夫馬其頓共和國",
        "ML": "馬裏",
        "MM": "緬甸",
        "MN": "蒙古",
        "MQ": "馬提尼克",
        "MR": "毛裏塔尼亞",
        "MS": "蒙特塞拉特",
        "MT": "馬耳他",
        "MU": "毛裏求斯",
        "MV": "馬爾代夫",
        "MW": "馬拉維",
        "MX": "墨西哥",
        "MY": "馬來西亞",
        "MZ": "莫桑比克",
        "FM": "密克羅尼西亞聯邦",
        "YT": "馬約特島",
        "NA": "納米比亞",
        "NC": "新喀裏多尼亞",
        "NE": "尼日爾",
        "NF": "諾福克島",
        "NG": "尼日利亞",
        "NI": "尼加拉瓜",
        "NL": "荷蘭",
        "NO": "挪威",
        "NP": "尼泊爾",
        "NR": "瑙魯",
        "NU": "紐埃",
        "NZ": "新西蘭",
        "MP": "北馬裏亞納群島",
        "OM": "阿曼",
        "PA": "巴拿馬",
        "PE": "秘魯",
        "PG": "巴布亞新幾內亞",
        "PH": "菲律賓",
        "PK": "巴基斯坦",
        "PL": "波蘭",
        "PN": "皮特凱恩群島",
        "PR": "波多黎各",
        "PS": "巴勒斯坦領土",
        "PT": "葡萄牙",
        "PW": "帕勞",
        "PY": "巴拉圭",
        "QA": "卡塔爾",
        "RE": "留尼汪島",
        "RO": "羅馬尼亞",
        "RU": "俄羅斯聯邦(非克裏米亞地區)",
        "Z8": "俄羅斯聯邦(克裏米亞地區)",
        "RW": "盧旺達",
        "SA": "沙特阿拉伯",
        "SB": "所羅門群島",
        "SC": "塞舌爾",
        "SD": "蘇丹",
        "SE": "瑞典",
        "SG": "新加坡",
        "SH": "聖赫勒拿",
        "SI": "斯洛文尼亞",
        "SJ": "斯瓦爾巴特和揚馬延",
        "SK": "斯洛伐克",
        "SL": "塞拉利昂",
        "SM": "聖馬力諾",
        "SN": "塞內加爾",
        "SO": "索馬裏",
        "SR": "蘇裏南",
        "SS": "南蘇丹",
        "ST": "聖多美和普林西比",
        "SX": "聖馬丁",
        "SY": "阿拉伯敘利亞共和國",
        "SZ": "斯威士蘭",
        "BL": "加勒比海聖巴特島",
        "CH": "瑞士",
        "ES": "西班牙",
        "GS": "南佐治亞和南桑威奇島",
        "KN": "聖基茨和尼維斯",
        "LC": "聖盧西亞",
        "LK": "斯裏蘭卡",
        "MF": "法屬聖馬丁",
        "PM": "聖皮埃爾和密克隆群島",
        "RS": "塞爾維亞",
        "VC": "聖文森特",
        "WS": "薩摩亞",
        "ZA": "南非",
        "TC": "特克斯和凱科斯群島",
        "TG": "多哥",
        "TH": "泰國",
        "TJ": "塔吉克斯坦",
        "TK": "托克勞群島",
        "TL": "東帝汶島",
        "TM": "土庫曼斯坦",
        "TN": "突尼斯",
        "TO": "湯加",
        "TR": "土耳其",
        "TT": "特立尼達和多巴哥",
        "TV": "圖魯瓦",
        "TW": "臺灣地區",
        "TZ": "坦桑尼亞聯合共和國",
        "UA": "烏克蘭(非克裏米亞地區)",
        "Z9": "烏克蘭(克裏米亞地區)",
        "UG": "烏幹達",
        "UM": "美國本土外小島嶼",
        "US": "美國",
        "UY": "烏拉圭",
        "UZ": "烏茲別克斯坦",
        "AE": "阿聯酋",
        "GB": "英國",
        "VI": "美屬維爾京群島",
        "VA": "梵蒂岡城國（羅馬教廷）",
        "VE": "委內瑞拉",
        "VN": "越南",
        "VU": "瓦努阿圖",
        "WF": "瓦利斯和富圖納群島",
        "EH": "西撒哈拉",
        "YE": "也門",
        "ZM": "贊比亞",
        "ZW": "津巴布韋",
        "XK": "科索沃"
    }
}
