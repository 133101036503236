import globalViewTaggingParams from "./globalTagging/globalViewTaggingParams";
import { loadTagging } from "./loadTagging";
import { useEffect } from "react";

const useTagging = (params, depends = []) => {
    useEffect(() => {
        loadTagging({ ...globalViewTaggingParams, ...params });
    }, depends);
};

export default useTagging;
