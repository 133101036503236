export default {
 
    "en": [
        {value:'Hong Kong SAR',label:'Hong Kong SAR'},
        {value:'China',label:'China'}, 
        {value:'Afghanistan',label:'Afghanistan'},
        {value:'Aland Islands',label:'Aland Islands'},
        {value:'Albania',label:'Albania'},
        {value:'Algeria',label:'Algeria'},
        {value:'American Samoa',label:'American Samoa'},
        {value:'Andorra',label:'Andorra'},
        {value:'Angola',label:'Angola'},
        {value:'Anguilla',label:'Anguilla'},
        {value:'Antarctica',label:'Antarctica'},
        {value:'Antigua and Barbuda',label:'Antigua and Barbuda'},
        {value:'Argentina',label:'Argentina'},
        {value:'Armenia',label:'Armenia'},
        {value:'Aruba',label:'Aruba'},
        {value:'Australia',label:'Australia'},
        {value:'Austria',label:'Austria'},
        {value:'Azerbaijan',label:'Azerbaijan'},
        {value:'Bahamas',label:'Bahamas'},
        {value:'Bahrain',label:'Bahrain'},
        {value:'Bangladesh',label:'Bangladesh'},
        {value:'Barbados',label:'Barbados'},
        {value:'Belarus',label:'Belarus'},
        {value:'Belgium',label:'Belgium'},
        {value:'Belize',label:'Belize'},
        {value:'Benin',label:'Benin'},
        {value:'Bermuda',label:'Bermuda'},
        {value:'Bhutan',label:'Bhutan'},
        {value:'Bolivia, Plurinational State of',label:'Bolivia, Plurinational State of'},
        {value:'Bonaire, Sint Eustatius and Saba',label:'Bonaire, Sint Eustatius and Saba'},
        {value:'Bosnia and Herzegovina',label:'Bosnia and Herzegovina'},
        {value:'Botswana',label:'Botswana'},
        {value:'Bouvet Islands',label:'Bouvet Islands'},
        {value:'Brazil',label:'Brazil'},
        {value:'British Indian Ocean Territory',label:'British Indian Ocean Territory'},
        {value:'British Virgin Islands',label:'British Virgin Islands'},
        {value:'Brunei Darussalam',label:'Brunei Darussalam'},
        {value:'Bulgaria',label:'Bulgaria'},
        {value:'Burkina Faso',label:'Burkina Faso'},
        {value:'Burundi',label:'Burundi'},
        {value:'Cabo Verde',label:'Cabo Verde'},
        {value:'Cambodia',label:'Cambodia'},
        {value:'Cameroon',label:'Cameroon'},
        {value:'Canada',label:'Canada'},
        {value:'Canton and Enderbury Islands',label:'Canton and Enderbury Islands'},
        {value:'Cayman Islands',label:'Cayman Islands'},
        {value:'Central African Republic',label:'Central African Republic'},
        {value:'Chad',label:'Chad'},
        {value:'Chile',label:'Chile'},
        {value:'Christmas Island',label:'Christmas Island'},
        {value:'Cocos (Keeling) Islands',label:'Cocos (Keeling) Islands'},
        {value:'Colombia',label:'Colombia'},
        {value:'Comoros',label:'Comoros'},
        {value:'Congo',label:'Congo'},
        {value:'Congo, Democratic Republic of the',label:'Congo, Democratic Republic of the'},
        {value:'Cook Islands',label:'Cook Islands'},
        {value:'Costa Rica',label:'Costa Rica'},
        {value:"Cote D'Ivoire",label:"Cote D'Ivoire"},
        {value:'Croatia',label:'Croatia'},
        {value:'Cuba',label:'Cuba'},
        {value:'Curacao',label:'Curacao'},
        {value:'Cyprus',label:'Cyprus'},
        {value:'Czech Republic',label:'Czech Republic'},
        {value:'Denmark',label:'Denmark'},
        {value:'Djibouti',label:'Djibouti'},
        {value:'Dominica',label:'Dominica'},
        {value:'Dominican Republic',label:'Dominican Republic'},
        {value:'East Timor',label:'East Timor'},
        {value:'Ecuador',label:'Ecuador'},
        {value:'Egypt',label:'Egypt'},
        {value:'El Salvador',label:'El Salvador'},
        {value:'Equatorial Guinea',label:'Equatorial Guinea'},
        {value:'Eritrea',label:'Eritrea'},
        {value:'Estonia',label:'Estonia'},
        {value:'Eswatini',label:'Eswatini'},
        {value:'Ethiopia',label:'Ethiopia'},
        {value:'Falkland Islands (Malvinas)',label:'Falkland Islands (Malvinas)'},
        {value:'Faroe Islands',label:'Faroe Islands'},
        {value:'Fiji',label:'Fiji'},
        {value:'Finland',label:'Finland'},
        {value:'France',label:'France'},
        {value:'French Guiana',label:'French Guiana'},
        {value:'French Polynesia',label:'French Polynesia'},
        {value:'French Southern Territories',label:'French Southern Territories'},
        {value:'Gabon',label:'Gabon'},
        {value:'Gambia',label:'Gambia'},
        {value:'Georgia',label:'Georgia'},
        {value:'Germany',label:'Germany'},
        {value:'Ghana',label:'Ghana'},
        {value:'Gibraltar',label:'Gibraltar'},
        {value:'Greece',label:'Greece'},
        {value:'Greenland',label:'Greenland'},
        {value:'Grenada',label:'Grenada'},
        {value:'Guadeloupe',label:'Guadeloupe'},
        {value:'Guam',label:'Guam'},
        {value:'Guatemala',label:'Guatemala'},
        {value:'Guernsey',label:'Guernsey'},
        {value:'Guinea',label:'Guinea'},
        {value:'Guinea Bissau',label:'Guinea Bissau'},
        {value:'Guyana',label:'Guyana'},
        {value:'Haiti',label:'Haiti'},
        {value:'Heard and Mcdonald Islands',label:'Heard and Mcdonald Islands'},
        {value:'Holy See (Vatican City State)',label:'Holy See (Vatican City State)'},
        {value:'Honduras',label:'Honduras'},
        {value:'Hungary',label:'Hungary'},
        {value:'Iceland',label:'Iceland'},
        {value:'India',label:'India'},
        {value:'Indonesia',label:'Indonesia'},
        {value:'Iran, Islamic Republic Of',label:'Iran, Islamic Republic Of'},
        {value:'Iraq',label:'Iraq'},
        {value:'Ireland',label:'Ireland'},
        {value:'Isle Of Man',label:'Isle Of Man'},
        {value:'Israel',label:'Israel'},
        {value:'Italy',label:'Italy'},
        {value:'Jamaica',label:'Jamaica'},
        {value:'Japan',label:'Japan'},
        {value:'Jersey',label:'Jersey'},
        {value:'Jordan',label:'Jordan'},
        {value:'Kazakhstan',label:'Kazakhstan'},
        {value:'Kenya',label:'Kenya'},
        {value:'Kiribati',label:'Kiribati'},
        {value:'Korea, Dem Peoples Rep Of (North)',label:'Korea, Dem Peoples Rep Of (North)'},
        {value:'Korea, Republic Of (South)',label:'Korea, Republic Of (South)'},
        {value:'Kosovo',label:'Kosovo'},
        {value:'Kuwait',label:'Kuwait'},
        {value:'Kyrgyzstan',label:'Kyrgyzstan'},
        {value:'Lao Peoples Democratic Republic',label:'Lao Peoples Democratic Republic'},
        {value:'Latvia',label:'Latvia'},
        {value:'Lebanon',label:'Lebanon'},
        {value:'Lesotho',label:'Lesotho'},
        {value:'Liberia',label:'Liberia'},
        {value:'Libya',label:'Libya'},
        {value:'Liechtenstein',label:'Liechtenstein'},
        {value:'Lithuania',label:'Lithuania'},
        {value:'Luxembourg',label:'Luxembourg'},
        {value:'Macau SAR',label:'Macau SAR'},
        {value:'Macedonia, Former Yugoslav Rep',label:'Macedonia, Former Yugoslav Rep'},
        {value:'Madagascar',label:'Madagascar'},
        {value:'Malawi',label:'Malawi'},
        {value:'Malaysia',label:'Malaysia'},
        {value:'Maldives',label:'Maldives'},
        {value:'Mali',label:'Mali'},
        {value:'Malta',label:'Malta'},
        {value:'Marshall Islands',label:'Marshall Islands'},
        {value:'Martinique',label:'Martinique'},
        {value:'Mauritania',label:'Mauritania'},
        {value:'Mauritius',label:'Mauritius'},
        {value:'Mayotte',label:'Mayotte'},
        {value:'Mexico',label:'Mexico'},
        {value:'Micronesia, Federated States Of',label:'Micronesia, Federated States Of'},
        {value:'Moldova, Republic Of',label:'Moldova, Republic Of'},
        {value:'Monaco',label:'Monaco'},
        {value:'Mongolia',label:'Mongolia'},
        {value:'Montenegro',label:'Montenegro'},
        {value:'Montserrat',label:'Montserrat'},
        {value:'Morocco',label:'Morocco'},
        {value:'Mozambique',label:'Mozambique'},
        {value:'Myanmar',label:'Myanmar'},
        {value:'Namibia',label:'Namibia'},
        {value:'Nauru',label:'Nauru'},
        {value:'Nepal',label:'Nepal'},
        {value:'Netherlands',label:'Netherlands'},
        {value:'Netherlands Antilles',label:'Netherlands Antilles'},
        {value:'New Caledonia',label:'New Caledonia'},
        {value:'New Zealand',label:'New Zealand'},
        {value:'Nicaragua',label:'Nicaragua'},
        {value:'Niger',label:'Niger'},
        {value:'Nigeria',label:'Nigeria'},
        {value:'Niue',label:'Niue'},
        {value:'Norfolk Island',label:'Norfolk Island'},
        {value:'Northern Mariana Islands',label:'Northern Mariana Islands'},
        {value:'Norway',label:'Norway'},
        {value:'Oman',label:'Oman'},
        {value:'Pakistan',label:'Pakistan'},
        {value:'Palau',label:'Palau'},
        {value:'Palestine, State of',label:'Palestine, State of'},
        {value:'Panama',label:'Panama'},
        {value:'Papua New Guinea',label:'Papua New Guinea'},
        {value:'Paraguay',label:'Paraguay'},
        {value:'Peru',label:'Peru'},
        {value:'Philippines',label:'Philippines'},
        {value:'Pitcairn',label:'Pitcairn'},
        {value:'Poland',label:'Poland'},
        {value:'Portugal',label:'Portugal'},
        {value:'Puerto Rico',label:'Puerto Rico'},
        {value:'Qatar',label:'Qatar'},
        {value:'Reunion',label:'Reunion'},
        {value:'Romania',label:'Romania'},
        {value:'Russian Federation',label:'Russian Federation'},
        {value:'Rwanda',label:'Rwanda'},
        {value:'Saint Barthelemy',label:'Saint Barthelemy'},
        {value:'Saint Helena, Ascension and Tristan da Cunha',label:'Saint Helena, Ascension and Tristan da Cunha'},
        {value:'Saint Kitts and Nevis',label:'Saint Kitts and Nevis'},
        {value:'Saint Lucia',label:'Saint Lucia'},
        {value:'Saint Martin (French part)',label:'Saint Martin (French part)'},
        {value:'Saint Pierre and Miquelon',label:'Saint Pierre and Miquelon'},
        {value:'Saint Vincent and the Grenadines',label:'Saint Vincent and the Grenadines'},
        {value:'Samoa',label:'Samoa'},
        {value:'San Marino',label:'San Marino'},
        {value:'Sao Tome and Principe',label:'Sao Tome and Principe'},
        {value:'Saudi Arabia',label:'Saudi Arabia'},
        {value:'Senegal',label:'Senegal'},
        {value:'Serbia',label:'Serbia'},
        {value:'Serbia and Montenegro',label:'Serbia and Montenegro'},
        {value:'Seychelles',label:'Seychelles'},
        {value:'Sierra Leone',label:'Sierra Leone'},
        {value:'Singapore',label:'Singapore'},
        {value:'Sint Maarten (Dutch part)',label:'Sint Maarten (Dutch part)'},
        {value:'Slovakia',label:'Slovakia'},
        {value:'Slovenia',label:'Slovenia'},
        {value:'Solomon Islands',label:'Solomon Islands'},
        {value:'Somalia',label:'Somalia'},
        {value:'South Africa',label:'South Africa'},
        {value:'South Georgia and South Sandwich Islands',label:'South Georgia and South Sandwich Islands'},
        {value:'South Sudan',label:'South Sudan'},
        {value:'Spain',label:'Spain'},
        {value:'Sri Lanka',label:'Sri Lanka'},
        {value:'Sudan',label:'Sudan'},
        {value:'Suriname',label:'Suriname'},
        {value:'Svalbard and Jan Mayen',label:'Svalbard and Jan Mayen'},
        {value:'Sweden',label:'Sweden'},
        {value:'Switzerland',label:'Switzerland'},
        {value:'Syrian Arab Republic',label:'Syrian Arab Republic'},
        {value:'Taiwan',label:'Taiwan'},
        {value:'Tajikistan',label:'Tajikistan'},
        {value:'Tanzania, United Republic Of',label:'Tanzania, United Republic Of'},
        {value:'Thailand',label:'Thailand'},
        {value:'Timor-Leste',label:'Timor-Leste'},
        {value:'Togo',label:'Togo'},
        {value:'Tokelau',label:'Tokelau'},
        {value:'Tonga',label:'Tonga'},
        {value:'Trinidad and Tobago',label:'Trinidad and Tobago'},
        {value:'Tunisia',label:'Tunisia'},
        {value:'Turkey',label:'Turkey'},
        {value:'Turkmenistan',label:'Turkmenistan'},
        {value:'Turks and Caicos Islands',label:'Turks and Caicos Islands'},
        {value:'Tuvalu',label:'Tuvalu'},
        {value:'Uganda',label:'Uganda'},
        {value:'Ukraine',label:'Ukraine'},
        {value:'United Arab Emirates',label:'United Arab Emirates'},
        {value:'United Kingdom',label:'United Kingdom'},
        {value:'United States Of America',label:'United States Of America'},
        {value:'Uruguay',label:'Uruguay'},
        {value:'US Minor Outlying Islands',label:'US Minor Outlying Islands'},
        {value:'US Virgin Islands',label:'US Virgin Islands'},
        {value:'Uzbekistan',label:'Uzbekistan'},
        {value:'Vanuatu',label:'Vanuatu'},
        {value:'Venezuela, Bolivarian Republic of',label:'Venezuela, Bolivarian Republic of'},
        {value:'Viet Nam',label:'Viet Nam'},
        {value:'Wallis and Futuna',label:'Wallis and Futuna'},
        {value:'Western Sahara',label:'Western Sahara'},
        {value:'Yemen',label:'Yemen'},
        {value:'Yugoslavia',label:'Yugoslavia'},
        {value:'Zambia',label:'Zambia'},
        {value:'Zimbabwe',label:'Zimbabwe'},
       ],

    "zh_HK":[ 
        {value:'Hong Kong SAR',label:'香港特別行政區'},
        {value:'China',label:'中國'}, 
        {value:'Afghanistan',label:'阿富汗'},
        {value:'Aland Islands',label:'奧蘭群島'},
        {value:'Albania',label:'阿爾巴尼亞'},
        {value:'Algeria',label:'阿爾及利亞'},
        {value:'American Samoa',label:'美屬薩摩亞'},
        {value:'Andorra',label:'安道爾'},
        {value:'Angola',label:'安哥拉'},
        {value:'Anguilla',label:'安圭拉'},
        {value:'Antarctica',label:'南極洲'},
        {value:'Antigua and Barbuda',label:'安提瓜和巴布達'},
        {value:'Argentina',label:'阿根廷'},
        {value:'Armenia',label:'亞美尼亞'},
        {value:'Aruba',label:'阿魯巴'},
        {value:'Australia',label:'澳洲'},
        {value:'Austria',label:'奧地利'},
        {value:'Azerbaijan',label:'阿塞拜彊'},
        {value:'Bahamas',label:'巴哈馬'},
        {value:'Bahrain',label:'巴林'},
        {value:'Bangladesh',label:'孟加拉'},
        {value:'Barbados',label:'巴巴多斯'},
        {value:'Belarus',label:'白俄羅斯'},
        {value:'Belgium',label:'比利時'},
        {value:'Belize',label:'伯利茲'},
        {value:'Benin',label:'貝寧'},
        {value:'Bermuda',label:'百慕達'},
        {value:'Bhutan',label:'不丹'},
        {value:'Bolivia, Plurinational State of',label:'玻利維亞'},
        {value:'Bonaire, Sint Eustatius and Saba',label:'波奈、聖尤斯特歇斯及薩巴'},
        {value:'Bosnia and Herzegovina',label:'波斯尼亞和黑塞哥維那'},
        {value:'Botswana',label:'博茨瓦納'},
        {value:'Bouvet Islands',label:'布韋島'},
        {value:'Brazil',label:'巴西'},
        {value:'British Indian Ocean Territory',label:'英屬印度洋領地'},
        {value:'British Virgin Islands',label:'英屬處女群島'},
        {value:'Brunei Darussalam',label:'汶萊'},
        {value:'Bulgaria',label:'保加利亞'},
        {value:'Burkina Faso',label:'布基納法索'},
        {value:'Burundi',label:'布隆迪'},
        {value:'Cabo Verde',label:'佛得角群島'},
        {value:'Cambodia',label:'柬埔寨'},
        {value:'Cameroon',label:'喀麥隆'},
        {value:'Canada',label:'加拿大'},
        {value:'Canton and Enderbury Islands',label:'坎頓及恩德伯里群島'},
        {value:'Cayman Islands',label:'開曼群島'},
        {value:'Central African Republic',label:'中非共和國'},
        {value:'Chad',label:'乍得'},
        {value:'Chile',label:'智利'},
        {value:'Christmas Island',label:'聖誕島'},
        {value:'Cocos (Keeling) Islands',label:'科科斯（基林）群島'},
        {value:'Colombia',label:'哥倫比亞'},
        {value:'Comoros',label:'科摩羅'},
        {value:'Congo',label:'剛果'},
        {value:'Congo, Democratic Republic of the',label:'剛果民主共和國'},
        {value:'Cook Islands',label:'庫克群島'},
        {value:'Costa Rica',label:'哥斯達黎加'},
        {value:"Cote D'Ivoire",label:'科特迪瓦'},
        {value:'Croatia',label:'克羅地亞'},
        {value:'Cuba',label:'古巴'},
        {value:'Curacao',label:'庫拉索'},
        {value:'Cyprus',label:'塞浦路斯'},
        {value:'Czech Republic',label:'捷克'},
        {value:'Denmark',label:'丹麥'},
        {value:'Djibouti',label:'吉布提'},
        {value:'Dominica',label:'多米尼克'},
        {value:'Dominican Republic',label:'多明尼加共和國'},
        {value:'East Timor',label:'東帝汶'},
        {value:'Ecuador',label:'厄瓜多爾'},
        {value:'Egypt',label:'埃及'},
        {value:'El Salvador',label:'薩爾瓦多'},
        {value:'Equatorial Guinea',label:'赤道幾內亞'},
        {value:'Eritrea',label:'厄立特里亞'},
        {value:'Estonia',label:'愛沙尼亞'},
        {value:'Eswatini',label:'斯威士蘭'},
        {value:'Ethiopia',label:'埃塞俄比亞'},
        {value:'Falkland Islands (Malvinas)',label:'福克蘭群島（馬爾維納斯群島）'},
        {value:'Faroe Islands',label:'法羅群島'},
        {value:'Fiji',label:'斐濟'},
        {value:'Finland',label:'芬蘭'},
        {value:'France',label:'法國'},
        {value:'French Guiana',label:'法屬圭亞那'},
        {value:'French Polynesia',label:'法屬波利尼西亞'},
        {value:'French Southern Territories',label:'法屬南方領地'},
        {value:'Gabon',label:'加蓬'},
        {value:'Gambia',label:'岡比亞'},
        {value:'Georgia',label:'格魯吉亞'},
        {value:'Germany',label:'德國'},
        {value:'Ghana',label:'加納'},
        {value:'Gibraltar',label:'直布羅陀'},
        {value:'Greece',label:'希臘'},
        {value:'Greenland',label:'格陵蘭'},
        {value:'Grenada',label:'格林納達'},
        {value:'Guadeloupe',label:'瓜德羅普島'},
        {value:'Guam',label:'關島'},
        {value:'Guatemala',label:'危地馬拉'},
        {value:'Guernsey',label:'根西'},
        {value:'Guinea',label:'幾內亞'},
        {value:'Guinea Bissau',label:'幾內亞比紹'},
        {value:'Guyana',label:'圭亞那'},
        {value:'Haiti',label:'海地'},
        {value:'Heard and Mcdonald Islands',label:'赫德島和麥克唐納群島'},
        {value:'Holy See (Vatican City State)',label:'教廷（梵蒂岡城國）'},
        {value:'Honduras',label:'洪都拉斯'},
        {value:'Hungary',label:'匈牙利'},
        {value:'Iceland',label:'冰島'},
        {value:'India',label:'印度'},
        {value:'Indonesia',label:'印尼'},
        {value:'Iran, Islamic Republic Of',label:'伊朗伊斯蘭共和國'},
        {value:'Iraq',label:'伊拉克'},
        {value:'Ireland',label:'愛爾蘭共和國'},
        {value:'Isle Of Man',label:'英屬曼島'},
        {value:'Israel',label:'以色列'},
        {value:'Italy',label:'意大利'},
        {value:'Jamaica',label:'牙買加'},
        {value:'Japan',label:'日本'},
        {value:'Jersey',label:'澤西島'},
        {value:'Jordan',label:'約旦'},
        {value:'Kazakhstan',label:'哈薩克斯坦'},
        {value:'Kenya',label:'肯尼亞'},
        {value:'Kiribati',label:'基里巴斯'},
        {value:'Korea, Dem Peoples Rep Of (North)',label:'朝鮮民主主義人民共和國'},
        {value:'Korea, Republic Of (South)',label:'大韓民國'},
        {value:'Kosovo',label:'科索沃'},
        {value:'Kuwait',label:'科威特'},
        {value:'Kyrgyzstan',label:'吉爾吉斯坦'},
        {value:'Lao Peoples Democratic Republic',label:'老撾'},
        {value:'Latvia',label:'拉脫維亞'},
        {value:'Lebanon',label:'黎巴嫩'},
        {value:'Lesotho',label:'萊索托'},
        {value:'Liberia',label:'利比里亞'},
        {value:'Libya',label:'利比亞'},
        {value:'Liechtenstein',label:'列支敦士登'},
        {value:'Lithuania',label:'立陶宛'},
        {value:'Luxembourg',label:'盧森堡'},
        {value:'Macau SAR',label:'澳門特別行政區'},
        {value:'Macedonia, Former Yugoslav Rep',label:'馬其頓（前南斯拉夫共和國）'},
        {value:'Madagascar',label:'馬達加斯加'},
        {value:'Malawi',label:'馬拉維'},
        {value:'Malaysia',label:'馬來西亞'},
        {value:'Maldives',label:'馬爾代夫'},
        {value:'Mali',label:'馬里'},
        {value:'Malta',label:'馬耳他'},
        {value:'Marshall Islands',label:'馬紹爾群島'},
        {value:'Martinique',label:'馬丁尼克島'},
        {value:'Mauritania',label:'毛里塔尼亞'},
        {value:'Mauritius',label:'毛里求斯'},
        {value:'Mayotte',label:'馬約特'},
        {value:'Mexico',label:'墨西哥'},
        {value:'Micronesia, Federated States Of',label:'密克羅尼西亞'},
        {value:'Moldova, Republic Of',label:'摩爾達維亞'},
        {value:'Monaco',label:'摩納哥'},
        {value:'Mongolia',label:'蒙古'},
        {value:'Montenegro',label:'黑山共和國'},
        {value:'Montserrat',label:'蒙特塞拉特'},
        {value:'Morocco',label:'摩洛哥'},
        {value:'Mozambique',label:'莫桑比克'},
        {value:'Myanmar',label:'緬甸'},
        {value:'Namibia',label:'納米比亞'},
        {value:'Nauru',label:'瑙魯'},
        {value:'Nepal',label:'尼泊爾'},
        {value:'Netherlands',label:'荷蘭'},
        {value:'Netherlands Antilles',label:'荷屬安的列斯'},
        {value:'New Caledonia',label:'新喀里多尼亞'},
        {value:'New Zealand',label:'新西蘭'},
        {value:'Nicaragua',label:'尼加拉瓜'},
        {value:'Niger',label:'尼日爾'},
        {value:'Nigeria',label:'尼日利亞'},
        {value:'Niue',label:'紐埃'},
        {value:'Norfolk Island',label:'諾福克島'},
        {value:'Northern Mariana Islands',label:'北馬里安納群島'},
        {value:'Norway',label:'挪威'},
        {value:'Oman',label:'阿曼'},
        {value:'Pakistan',label:'巴基斯坦'},
        {value:'Palau',label:'帕勞'},
        {value:'Palestine, State of',label:'巴勒斯坦'},
        {value:'Panama',label:'巴拿馬'},
        {value:'Papua New Guinea',label:'巴布亞新幾內亞'},
        {value:'Paraguay',label:'巴拉圭'},
        {value:'Peru',label:'秘魯'},
        {value:'Philippines',label:'菲律賓'},
        {value:'Pitcairn',label:'皮特凱恩島'},
        {value:'Poland',label:'波蘭'},
        {value:'Portugal',label:'葡萄牙'},
        {value:'Puerto Rico',label:'波多黎各'},
        {value:'Qatar',label:'卡塔爾'},
        {value:'Reunion',label:'留尼旺'},
        {value:'Romania',label:'羅馬尼亞'},
        {value:'Russian Federation',label:'俄羅斯聯邦'},
        {value:'Rwanda',label:'盧旺達'},
        {value:'Saint Barthelemy',label:'法屬聖巴泰勒米'},
        {value:'Saint Helena, Ascension and Tristan da Cunha',label:'聖赫勒拿、阿森松及特里斯坦達庫尼亞'},
        {value:'Saint Kitts and Nevis',label:'聖基茨和尼維斯'},
        {value:'Saint Lucia',label:'聖盧西亞'},
        {value:'Saint Martin (French part)',label:'聖馬丁島（法屬）'},
        {value:'Saint Pierre and Miquelon',label:'聖皮埃爾島和密克隆島'},
        {value:'Saint Vincent and the Grenadines',label:'聖文森特和格林納丁斯'},
        {value:'Samoa',label:'薩摩亞'},
        {value:'San Marino',label:'聖馬力諾'},
        {value:'Sao Tome and Principe',label:'聖多美和普林西比'},
        {value:'Saudi Arabia',label:'沙特阿拉伯'},
        {value:'Senegal',label:'塞內加爾'},
        {value:'Serbia',label:'塞爾維亞共和國'},
        {value:'Serbia and Montenegro',label:'塞爾維亞與蒙特內哥羅'},
        {value:'Seychelles',label:'塞舌爾'},
        {value:'Sierra Leone',label:'塞拉利昂'},
        {value:'Singapore',label:'新加坡'},
        {value:'Sint Maarten (Dutch part)',label:'聖馬丁島（荷屬）'},
        {value:'Slovakia',label:'斯洛伐克'},
        {value:'Slovenia',label:'斯洛文尼亞'},
        {value:'Solomon Islands',label:'所羅門群島'},
        {value:'Somalia',label:'索馬里'},
        {value:'South Africa',label:'南非'},
        {value:'South Georgia and South Sandwich Islands',label:'南喬治亞島和南桑威奇群島'},
        {value:'South Sudan',label:'南蘇丹'},
        {value:'Spain',label:'西班牙'},
        {value:'Sri Lanka',label:'斯里蘭卡'},
        {value:'Sudan',label:'蘇丹'},
        {value:'Suriname',label:'蘇里南'},
        {value:'Svalbard and Jan Mayen',label:'斯瓦巴和揚馬延'},
        {value:'Sweden',label:'瑞典'},
        {value:'Switzerland',label:'瑞士'},
        {value:'Syrian Arab Republic',label:'敘利亞'},
        {value:'Taiwan',label:'台灣'},
        {value:'Tajikistan',label:'塔吉克斯坦'},
        {value:'Tanzania, United Republic Of',label:'坦桑尼亞'},
        {value:'Thailand',label:'泰國'},
        {value:'Timor-Leste',label:'東帝汶'},
        {value:'Togo',label:'多哥'},
        {value:'Tokelau',label:'托克勞群島'},
        {value:'Tonga',label:'湯加'},
        {value:'Trinidad and Tobago',label:'特立尼達和多巴哥'},
        {value:'Tunisia',label:'突尼斯'},
        {value:'Turkey',label:'土耳其'},
        {value:'Turkmenistan',label:'土庫曼斯坦'},
        {value:'Turks and Caicos Islands',label:'特克斯和凱科斯群島'},
        {value:'Tuvalu',label:'圖瓦盧'},
        {value:'Uganda',label:'烏干達'},
        {value:'Ukraine',label:'烏克蘭'},
        {value:'United Arab Emirates',label:'阿拉伯聯合酋長國'},
        {value:'United Kingdom',label:'英國'},
        {value:'United States Of America',label:'美國'},
        {value:'Uruguay',label:'烏拉圭'},
        {value:'US Minor Outlying Islands',label:'美國邊遠島嶼'},
        {value:'US Virgin Islands',label:'美屬維爾京群島'},
        {value:'Uzbekistan',label:'烏茲別克斯坦'},
        {value:'Vanuatu',label:'瓦努阿圖'},
        {value:'Venezuela, Bolivarian Republic of',label:'委內瑞拉'},
        {value:'Viet Nam',label:'越南'},
        {value:'Wallis and Futuna',label:'瓦利斯群島和富圖納群島'},
        {value:'Western Sahara',label:'西撤哈拉'},
        {value:'Yemen',label:'也門'},
        {value:'Yugoslavia',label:'南斯拉夫'},
        {value:'Zambia',label:'贊比亞'},
        {value:'Zimbabwe',label:'津巴布韋'},
    ],
    "zh_CN": [
        {value:'Hong Kong SAR',label:'香港特别行政区'},
        {value:'China',label:'中国'},
        {value:'Afghanistan',label:'阿富汗'},
        {value:'Aland Islands',label:'奥兰群岛'},
        {value:'Albania',label:'阿尔巴尼亚'},
        {value:'Algeria',label:'阿尔及利亚'},
        {value:'American Samoa',label:'美属萨摩亚'},
        {value:'Andorra',label:'安道尔'},
        {value:'Angola',label:'安哥拉'},
        {value:'Anguilla',label:'安圭拉'},
        {value:'Antarctica',label:'南极洲'},
        {value:'Antigua and Barbuda',label:'安提瓜和巴布达'},
        {value:'Argentina',label:'阿根廷'},
        {value:'Armenia',label:'亚美尼亚'},
        {value:'Aruba',label:'阿鲁巴'},
        {value:'Australia',label:'澳大利亚'},
        {value:'Austria',label:'奥地利'},
        {value:'Azerbaijan',label:'阿塞拜疆'},
        {value:'Bahamas',label:'巴哈马'},
        {value:'Bahrain',label:'巴林'},
        {value:'Bangladesh',label:'孟加拉'},
        {value:'Barbados',label:'巴巴多斯'},
        {value:'Belarus',label:'白俄罗斯'},
        {value:'Belgium',label:'比利时'},
        {value:'Belize',label:'伯利兹'},
        {value:'Benin',label:'贝宁'},
        {value:'Bermuda',label:'百慕大'},
        {value:'Bhutan',label:'不丹'},
        {value:'Bolivia, Plurinational State of',label:'玻利维亚'},
        {value:'Bonaire, Sint Eustatius and Saba',label:'博内尔岛、圣尤斯特歇斯岛和萨巴岛'},
        {value:'Bosnia and Herzegovina',label:'波斯尼亚和黑塞哥维那'},
        {value:'Botswana',label:'博茨瓦纳'},
        {value:'Bouvet Islands',label:'布韦岛'},
        {value:'Brazil',label:'巴西'},
        {value:'British Indian Ocean Territory',label:'英属印度洋领地'},
        {value:'British Virgin Islands',label:'英属维尔京群岛'},
        {value:'Brunei Darussalam',label:'文莱'},
        {value:'Bulgaria',label:'保加利亚'},
        {value:'Burkina Faso',label:'布基纳法索'},
        {value:'Burundi',label:'布隆迪'},
        {value:'Cabo Verde',label:'佛得角群岛'},
        {value:'Cambodia',label:'柬埔寨'},
        {value:'Cameroon',label:'喀麦隆'},
        {value:'Canada',label:'加拿大'},
        {value:'Canton and Enderbury Islands',label:'坎顿和恩德伯里群岛'},
        {value:'Cayman Islands',label:'开曼群岛'},
        {value:'Central African Republic',label:'中非共和国'},
        {value:'Chad',label:'乍得'},
        {value:'Chile',label:'智利'},
        {value:'Christmas Island',label:'圣诞岛'},
        {value:'Cocos (Keeling) Islands',label:'科科斯（基林）群岛'},
        {value:'Colombia',label:'哥伦比亚'},
        {value:'Comoros',label:'科摩罗'},
        {value:'Congo',label:'刚果'},
        {value:'Congo, Democratic Republic of the',label:'刚果民主共和国'},
        {value:'Cook Islands',label:'库克群岛'},
        {value:'Costa Rica',label:'哥斯达黎加'},
        {value:"Cote D'Ivoire",label:'科特迪瓦'},
        {value:'Croatia',label:'克罗地亚'},
        {value:'Cuba',label:'古巴'},
        {value:'Curacao',label:'库拉索'},
        {value:'Cyprus',label:'塞浦路斯'},
        {value:'Czech Republic',label:'捷克'},
        {value:'Denmark',label:'丹麦'},
        {value:'Djibouti',label:'吉布提'},
        {value:'Dominica',label:'多米尼克'},
        {value:'Dominican Republic',label:'多米尼加共和国'},
        {value:'East Timor',label:'东帝汶'},
        {value:'Ecuador',label:'厄瓜多尔'},
        {value:'Egypt',label:'埃及'},
        {value:'El Salvador',label:'萨尔瓦多'},
        {value:'Equatorial Guinea',label:'赤道几内亚'},
        {value:'Eritrea',label:'厄立特里亚'},
        {value:'Estonia',label:'爱沙尼亚'},
        {value:'Eswatini',label:'斯威士兰'},
        {value:'Ethiopia',label:'埃塞俄比亚'},
        {value:'Falkland Islands (Malvinas)',label:'福克兰群岛（马尔维纳斯群岛）'},
        {value:'Faroe Islands',label:'法罗群岛'},
        {value:'Fiji',label:'斐济'},
        {value:'Finland',label:'芬兰'},
        {value:'France',label:'法国'},
        {value:'French Guiana',label:'法属圭亚那'},
        {value:'French Polynesia',label:'法属波利尼西亚'},
        {value:'French Southern Territories',label:'法属南方领地'},
        {value:'Gabon',label:'加蓬'},
        {value:'Gambia',label:'冈比亚'},
        {value:'Georgia',label:'格鲁吉亚'},
        {value:'Germany',label:'德国'},
        {value:'Ghana',label:'加纳'},
        {value:'Gibraltar',label:'直布罗陀'},
        {value:'Greece',label:'希腊'},
        {value:'Greenland',label:'格陵兰'},
        {value:'Grenada',label:'格林纳达'},
        {value:'Guadeloupe',label:'瓜德鲁普岛'},
        {value:'Guam',label:'关岛'},
        {value:'Guatemala',label:'危地马拉'},
        {value:'Guernsey',label:'根西'},
        {value:'Guinea',label:'几内亚'},
        {value:'Guinea Bissau',label:'几内亚比绍'},
        {value:'Guyana',label:'圭亚那'},
        {value:'Haiti',label:'海地'},
        {value:'Heard and Mcdonald Islands',label:'赫德岛和麦克唐纳群岛'},
        {value:'Holy See (Vatican City State)',label:'教廷（梵蒂冈城国）'},
        {value:'Honduras',label:'洪都拉斯'},
        {value:'Hungary',label:'匈牙利'},
        {value:'Iceland',label:'冰岛'},
        {value:'India',label:'印度'},
        {value:'Indonesia',label:'印度尼西亚'},
        {value:'Iran, Islamic Republic Of',label:'伊朗伊斯兰共和国'},
        {value:'Iraq',label:'伊拉克'},
        {value:'Ireland',label:'爱尔兰共和国'},
        {value:'Isle Of Man',label:'马恩岛'},
        {value:'Israel',label:'以色列'},
        {value:'Italy',label:'意大利'},
        {value:'Jamaica',label:'牙买加'},
        {value:'Japan',label:'日本'},
        {value:'Jersey',label:'泽西岛'},
        {value:'Jordan',label:'约旦'},
        {value:'Kazakhstan',label:'哈萨克斯坦'},
        {value:'Kenya',label:'肯尼亚'},
        {value:'Kiribati',label:'基里巴斯'},
        {value:'Korea, Dem Peoples Rep Of (North)',label:'朝鲜民主主义人民共和国'},
        {value:'Korea, Republic Of (South)',label:'大韩民国'},
        {value:'Kosovo',label:'科索沃'},
        {value:'Kuwait',label:'科威特'},
        {value:'Kyrgyzstan',label:'吉尔吉斯斯坦'},
        {value:'Lao Peoples Democratic Republic',label:'老挝'},
        {value:'Latvia',label:'拉脱维亚'},
        {value:'Lebanon',label:'黎巴嫩'},
        {value:'Lesotho',label:'莱索托'},
        {value:'Liberia',label:'利比里亚'},
        {value:'Libya',label:'利比亚'},
        {value:'Liechtenstein',label:'列支敦士登'},
        {value:'Lithuania',label:'立陶宛'},
        {value:'Luxembourg',label:'卢森堡'},
        {value:'Macau SAR',label:'澳门特别行政区'},
        {value:'Macedonia, Former Yugoslav Rep',label:'马其顿（前南斯拉夫共和国）'},
        {value:'Madagascar',label:'马达加斯加 '},
        {value:'Malawi',label:'马拉维'},
        {value:'Malaysia',label:'马来西亚'},
        {value:'Maldives',label:'马尔代夫'},
        {value:'Mali',label:'马里'},
        {value:'Malta',label:'马耳他'},
        {value:'Marshall Islands',label:'马绍尔群岛'},
        {value:'Martinique',label:'马丁尼克岛'},
        {value:'Mauritania',label:'毛里塔尼亚'},
        {value:'Mauritius',label:'毛里求斯'},
        {value:'Mayotte',label:'马约特'},
        {value:'Mexico',label:'墨西哥'},
        {value:'Micronesia, Federated States Of',label:'密克罗尼西亚'},
        {value:'Moldova, Republic Of',label:'摩尔达维亚'},
        {value:'Monaco',label:'摩纳哥'},
        {value:'Mongolia',label:'蒙古'},
        {value:'Montenegro',label:'黑山共和国'},
        {value:'Montserrat',label:'蒙特塞拉特'},
        {value:'Morocco',label:'摩洛哥'},
        {value:'Mozambique',label:'莫桑比克'},
        {value:'Myanmar',label:'缅甸'},
        {value:'Namibia',label:'纳米比亚'},
        {value:'Nauru',label:'瑙鲁'},
        {value:'Nepal',label:'尼泊尔'},
        {value:'Netherlands',label:'荷兰'},
        {value:'Netherlands Antilles',label:'荷属安的列斯'},
        {value:'New Caledonia',label:'新喀里多尼亚'},
        {value:'New Zealand',label:'新西兰'},
        {value:'Nicaragua',label:'尼加拉瓜'},
        {value:'Niger',label:'尼日尔'},
        {value:'Nigeria',label:'尼日利亚'},
        {value:'Niue',label:'纽埃'},
        {value:'Norfolk Island',label:'诺福克岛'},
        {value:'Northern Mariana Islands',label:'北马里安纳群岛'},
        {value:'Norway',label:'挪威'},
        {value:'Oman',label:'阿曼'},
        {value:'Pakistan',label:'巴基斯坦'},
        {value:'Palau',label:'帕劳'},
        {value:'Palestine, State of',label:'巴勒斯坦'},
        {value:'Panama',label:'巴拿马'},
        {value:'Papua New Guinea',label:'巴布亚新几内亚'},
        {value:'Paraguay',label:'巴拉圭'},
        {value:'Peru',label:'秘鲁'},
        {value:'Philippines',label:'菲律宾'},
        {value:'Pitcairn',label:'皮特凯恩岛'},
        {value:'Poland',label:'波兰'},
        {value:'Portugal',label:'葡萄牙'},
        {value:'Puerto Rico',label:'波多黎各'},
        {value:'Qatar',label:'卡塔尔'},
        {value:'Reunion',label:'留尼汪'},
        {value:'Romania',label:'罗马尼亚'},
        {value:'Russian Federation',label:'俄罗斯联邦'},
        {value:'Rwanda',label:'卢旺达'},
        {value:'Saint Barthelemy',label:'法属圣巴泰勒米'},
        {value:'Saint Helena, Ascension and Tristan da Cunha',label:'圣赫勒拿、阿森松和特里斯坦达库尼亚'},
        {value:'Saint Kitts and Nevis',label:'圣基茨和尼维斯'},
        {value:'Saint Lucia',label:'圣卢西亚'},
        {value:'Saint Martin (French part)',label:'圣马丁岛（法属）'},
        {value:'Saint Pierre and Miquelon',label:'圣皮埃尔岛和密克隆岛'},
        {value:'Saint Vincent and the Grenadines',label:'圣文森特和格林纳丁斯'},
        {value:'Samoa',label:'萨摩亚'},
        {value:'San Marino',label:'圣马力诺'},
        {value:'Sao Tome and Principe',label:'圣多美和普林西比'},
        {value:'Saudi Arabia',label:'沙特阿拉伯'},
        {value:'Senegal',label:'塞内加尔'},
        {value:'Serbia',label:'塞尔维亚共和国'},
        {value:'Serbia and Montenegro',label:'塞尔维亚和黑山'},
        {value:'Seychelles',label:'塞舌尔'},
        {value:'Sierra Leone',label:'塞拉利昂'},
        {value:'Singapore',label:'新加坡'},
        {value:'Sint Maarten (Dutch part)',label:'圣马丁岛（荷属）'},
        {value:'Slovakia',label:'斯洛伐克'},
        {value:'Slovenia',label:'斯洛文尼亚'},
        {value:'Solomon Islands',label:'所罗门群岛'},
        {value:'Somalia',label:'索马里'},
        {value:'South Africa',label:'南非'},
        {value:'South Georgia and South Sandwich Islands',label:'南乔治亚岛和南桑威奇群岛'},
        {value:'South Sudan',label:'南苏丹'},
        {value:'Spain',label:'西班牙'},
        {value:'Sri Lanka',label:'斯里兰卡'},
        {value:'Sudan',label:'苏丹'},
        {value:'Suriname',label:'苏里南'},
        {value:'Svalbard and Jan Mayen',label:'斯瓦巴和扬马延'},
        {value:'Sweden',label:'瑞典'},
        {value:'Switzerland',label:'瑞士'},
        {value:'Syrian Arab Republic',label:'叙利亚'},
        {value:'Taiwan',label:'台湾'},
        {value:'Tajikistan',label:'塔吉克斯坦'},
        {value:'Tanzania, United Republic Of',label:'坦桑尼亚'},
        {value:'Thailand',label:'泰国'},
        {value:'Timor-Leste',label:'东帝汶'},
        {value:'Togo',label:'多哥'},
        {value:'Tokelau',label:'托克劳群岛'},
        {value:'Tonga',label:'汤加'},
        {value:'Trinidad and Tobago',label:'特立尼达和多巴哥'},
        {value:'Tunisia',label:'突尼斯'},
        {value:'Turkey',label:'土耳其'},
        {value:'Turkmenistan',label:'土库曼斯坦'},
        {value:'Turks and Caicos Islands',label:'特克斯和凱科斯群島'},
        {value:'Tuvalu',label:'图瓦卢'},
        {value:'Uganda',label:'乌干达'},
        {value:'Ukraine',label:'乌克兰'},
        {value:'United Arab Emirates',label:'阿拉伯联合酋长国'},
        {value:'United Kingdom',label:'英国'},
        {value:'United States Of America',label:'美国'},
        {value:'Uruguay',label:'乌拉圭'},
        {value:'US Minor Outlying Islands',label:'美国本土外小岛屿'},
        {value:'US Virgin Islands',label:'美属维尔京群岛'},
        {value:'Uzbekistan',label:'乌兹别克斯坦'},
        {value:'Vanuatu',label:'瓦努阿图'},
        {value:'Venezuela, Bolivarian Republic of',label:'委内瑞拉'},
        {value:'Viet Nam',label:'越南'},
        {value:'Wallis and Futuna',label:'瓦利斯和富图纳群岛'},
        {value:'Western Sahara',label:'西撒哈拉'},
        {value:'Yemen',label:'也门'},
        {value:'Yugoslavia',label:'南斯拉夫'},
        {value:'Zambia',label:'赞比亚'},
        {value:'Zimbabwe',label:'津巴布韦'},       
    ]


}