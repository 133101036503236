export default [
    {
        value: 'You',
        en: 'From yourself',
        zh_CN: '您本人',
        zh_HK: '您本人',
        children: [
            {
                value: 'NONHSBC_ACCOUNT_IN_MY_NAME',
                en: 'Transfer from your other account',
                zh_CN: '由您的其他账户转入',
                zh_HK: '由您的其他戶口轉入'
            },
            {
                value: 'WITHIN_ANOTHER_FINANCIAL_INSTITUTION',
                en: 'Personal cash savings held within another financial institution',
                zh_CN: '在其他金融机构持有的纸币或硬币个人储蓄',
                zh_HK: '在其他金融機構持有的紙幣或硬幣個人儲蓄'
            },
            {
                value: 'OUTSIDE_OF_A_FINANCIAL_INSTITUTION',
                en: 'Personal cash savings held outside of a financial institution',
                zh_CN: '在非金融机构持有的纸币或硬币个人储蓄',
                zh_HK: '在非金融機構持有的紙幣或硬幣個人儲蓄'
            }
        ]
    },
    {
        value: 'A third party',
        en: 'A third party',
        zh_CN: '第三方',
        zh_HK: '第三方',
        children: [
            {
                value: 'TRANSFER_FROM_EMPLOYER',
                en: 'Transfer from employer',
                zh_CN: '由雇主转入',
                zh_HK: '由僱主轉入'
            },
            {
                value: 'TRANSFER_FROM_3RD_PARTY_ACCOUNT',
                en: 'Transfer from third party accounts',
                zh_CN: '由第三方账户转入',
                zh_HK: '由第三方戶口轉入',
                isCountry: true
            },
            {
                value: 'SOCIAL_SECURITY_BENEFITS',
                en: 'Social security benefits',
                zh_CN: '社会保障福利',
                zh_HK: '社會保障福利'
            },
            {
                value: 'EMPLOYER_WAGES_GRATUITIES',
                en: 'Cash from employer/ wages/ gratuity',
                zh_CN: '雇主发放的现⾦／⼯资／酬⾦',
                zh_HK: '僱主發放的現⾦／⼯資／酬⾦',
            },
            {
                value: 'GIFT_SALE_OF_PERSONAL_GOODS',
                en: 'Cash received from gifts / sale of personal goods',
                zh_CN: '售卖礼品或个人物品所得的现金',
                zh_HK: '售賣禮品或個人物品所得的現金',
            },
            {
                value: 'ANOTHER_SOURCE',
                en: 'Cash from another source',
                zh_CN: '其他资金来源',
                zh_HK: '其他資金來源',
                isOther: true
            }
        ]
    }
]