export default {
    personal: {
        email: '',
        phoneNumber: '',
        gender: '',
        title: '',
        firstName: '',
        lastName: '',
        fullName: '',
        dateOfBirth: '',
        countryOfBirth: '',
        hasOtherNationality: '',
        nationality: 'China',
        nationality2: '',
        nationality3: '',
        documentType: '',
        placeOfIssue: '',
        permitNumber: ''
    }
}
