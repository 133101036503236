import React from 'react';
import {useTranslation} from "react-i18next";
import {Heading, Image, Paragraph, ProductBasket} from "@piggybank/core";
import TypeOneImage from "../../assets/image/one.png";
import TypePremierImage from "../../assets/image/premier.png";
import { getUserSessionDataByKey } from 'storage/userStorage';


const ProductBasketComponent = () => {
    const isPremier = sessionStorage.getItem('AC_TYPE') === 'PREMIER' || getUserSessionDataByKey('global').isPremier;
    const {t} = useTranslation();
    return (
        <ProductBasket imageSlot={
            <Image alt={isPremier ? t('ICO.TYPE.PREMIER_TITLE') : t('ICO.TYPE.ONE_TITLE')} intrinsicSize="200x126" marginBottom={0}
                   src={isPremier ? TypePremierImage : TypeOneImage}/>}>
            <Paragraph marginBottom={0}>
                {t('ICO.COMMON.BASKET_LABEL')}
            </Paragraph>
            <Heading level={3} marginBottom={0}>
                {isPremier ? t('ICO.TYPE.PREMIER_TITLE') : t('ICO.TYPE.ONE_TITLE')}
            </Heading>
        </ProductBasket>
    );
}

export default ProductBasketComponent;
