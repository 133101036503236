
export default {
    financial: {
        employmentStatus: '',
        employmentRole: '',
        occupation: '',
        industry: {},
        own: '',
        employerName: '',
        addressCountry: '',
        gross: '',
        average:'',
        amount: '',
        allDisplayStatus: false,
        fullStatus: 0,
        additionalNationalities: []
    }
}
