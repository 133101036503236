/* eslint-env browser */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/number';

import React, { Suspense } from 'react';

import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import { Loading } from '@piggybank/core';
import '@piggybank/core/lib/styles.css';
import '@piggybank/form/lib/styles.css';

const Loader = () => (
  <Loading
    show={true}
    getApplicationNode={() => document.getElementById('root')}
  ></Loading>
);

ReactDOM.render(<Suspense fallback={<Loader />}><App /></Suspense>, document.getElementById('root'));
