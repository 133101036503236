
export default {
    creditCard: {
        isApplyCreditCard: '',
        creditCardType: '',
        agreeTC: false,
        settlment: '',
        facillity: '',
        accounat: '',
        settlmentValue: '',
        facillityValue: '',
        balanceValue:'',
        accounatValue: '',
        grpTermsAndConditions:'',
        statementPreferencesOptions:'',
        current3: '',
        relative: '',
        relative1: '',
        relative2: {},
        relative3:'',
        relative4:'',
        agree: false,
        pageType:0,
    }
}
