import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductBasket from '../../components/ProdectBasket';
import Page from "../../components/Page";
import { getUserSessionDataByKey, clearUserSessionData } from 'storage/userStorage';
import {
  Section,
  Heading,
  Paragraph,
  IconStatusInformationOnlight,
  FormLayout,
  Button
} from '@piggybank/core';
import { useHistory } from 'react-router-dom';
import { useTagging, linkTagging } from 'tealiumTrack';

const Timeout = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isPremier, lang, accountType } = getUserSessionDataByKey('global');
  const [cardType] = useState(accountType);

  useEffect(() => {
    setTimeout(() => {
      clearUserSessionData();
    }, 16)
  })

  const returnPage = () => {
    linkTagging({
      'page_url': '/forms/timeout-error',
      'page_language': lang,
      'event_category': 'content',
      'event_action': 'button',
      'event_content': 'start again',
      'page_name': 'pws:forms:timeout error',
      'raw_datalayer': '5745v39'
    });

    if (cardType === 'HSBC Premier') {
      history.replace('/?accountType=PREMIER');
    } else {
      history.replace('/');
    }
  }

  useTagging({
    'page_url': '/forms/timeout-error',
    'page_language': lang,
    'page_type': 'error',
    'event_action': 'system',
    'event_category': 'error',
    'event_content': 'timeout',
    'page_name': 'pws:forms:timeout error',
    'page_subcategory': 'timeout error',
    'raw_datalayer': '5745v38',
    'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
    'funnel_step_name': 'timeout error'
  });
  return (
    <Page hasMargin={0}>
      <ProductBasket />
      <FormLayout>
        <Section marginBottom={3}>
          <div
            style={{
              color: 'var(--sapphire)'
            }}
          >
            <IconStatusInformationOnlight width="2.5em" />
          </div>
          <Heading level={1}>
            {t('ICO.HAPPENS.TIMEOUT')}
          </Heading>
        </Section>
        <Section >
          <Paragraph>
            {t('ICO.HAPPENS.SECURITY')}
          </Paragraph>
          <Paragraph>
            {t('ICO.HAPPENS.DELETED')}
          </Paragraph>
        </Section>
        <Button onClick={returnPage}>{t('ICO.HAPPENS.STARTAGAIN')}</Button>
      </FormLayout>
    </Page>
  );
}
Timeout.displayName = 'Timeout';
export default Timeout;
