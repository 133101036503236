export const loadScript = (url) => {
    const script = document.createElement('script');
    script.id = url;
    if (!document.getElementById(url)) {
        script.src = url;
        document.body.appendChild(script);
    }

    script.onload = script.onreadystatechange = function () {
        script.onload = script.onreadystatechange = null;
    }
}
