import React from "react";
import * as yup from 'yup';
import Title from "../../../components/Title/Title";
import { Country, CountryOfPhone, Gender, DataTitle, IdType } from 'referenceData';
import {
    FormLayout,
    Heading,
    Paragraph,
    Section,
} from "@piggybank/core";
import {
    Form,
    Field,
    Legend,
    Fieldset,
    TelephoneInput,
    Label,
    Select,
    TextInput,
    YesNoRadio,
    AddAnother,
    DateInput,
    FieldFeedback,
    FormFeedback
} from "@piggybank/form";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { WithI18nProgressIndicator } from "components/WithI18nPiggybank";
import { ButtonWithBack } from "components/ButtonWithBack";
import i18n from 'i18next';
import { timeAtLeast18Years } from "utils/date";
import { rePrevious } from 'utils/util';
import { getUserSessionDataByKey, setUserSessionDataByKey } from 'storage/userStorage';
import { linkTagging, useTagging, validationTagging } from "tealiumTrack";
let addAnotherCount = 1;

const CHARACTERS_AT_MOST = 'ICO.PERSONAL.CHARACTERS_AT_MOST';
const PHONE_NUMBER_INVALID = 'ICO.PERSONAL.PHONE_NUMBER_INVALID';
const COMMON_SPECIAL_ERROR = 'ICO.COMMON.SPECIAL_ERROR';
const COMMON_SELECT = 'ICO.COMMON.SELECT';
const DATE_OF_BIRTH = 'ICO.PERSONAL.DATE_OF_BIRTH';
const TAG_PAGE_URL = '/forms/personal-information';
const TAG_PAGE_NAME = 'pws:forms:personal information';

const schema = yup.object().shape({
    email: yup
        .string()
        .email(() => i18n.t('ICO.PERSONAL.EMAIL_ADDRESS_FORMAT_ERROR'))
        .max(35, ({ max }) => i18n.t(CHARACTERS_AT_MOST, { filedText: i18n.t('ICO.PERSONAL.EMAIL_ADDRESS'), max: max }))
        .required(() => i18n.t('ICO.PERSONAL.EMAIL_ADDRESS_ERROR'))
        .matches(/^[\u4300-\u9fa50-9a-zA-Z!#$%*\-_.@]{1,36}$/, () => i18n.t('ICO.COMMON.SPECIAL_MAIL_ERROR')),
    phoneNumber: yup
        .object().shape({
            raw: yup
                .string(),
            value: yup
                .string()
                .notOneOf(['invalid', 'incomplete'], () => i18n.t(PHONE_NUMBER_INVALID))
                .when('raw', {
                    is: (raw = '') => raw.length === 0,
                    then: yup
                        .string()
                        .test(
                            'empty',
                            () => i18n.t('ICO.PERSONAL.PHONE_NUMBER_ERROR'),
                            () => false
                        )
                })
                // .matches(/^[0-9]*$/, () => i18n.t(PHONE_NUMBER_INVALID))
                .when(['raw', 'countryCode'], {
                    is: (raw = '', countryCode = '') => raw.length > 0 && raw.length !== 11 && /^[0-9]*$/.test(raw) && countryCode === 'CN',
                    then: yup
                        .string()
                        .test(
                            'length',
                            () => i18n.t(PHONE_NUMBER_INVALID),
                            () => false
                        )
                })
                .when(['raw', 'countryCode'], {
                    is: (raw = '', countryCode = '') => raw.length > 0 && !/^[0-9]*$/.test(raw) && countryCode === 'CN',
                    then: yup
                        .string()
                        .test(
                            'length',
                            () => i18n.t(PHONE_NUMBER_INVALID),
                            () => false
                        )
                })
                .required(() => i18n.t('ICO.PERSONAL.PHONE_NUMBER_ERROR')),
            countryCode: yup
                .string(),
        }),
    gender: yup
        .string()
        .matches(/(Male|Female)/)
        .required(() => i18n.t('ICO.PERSONAL.GENDER_ERROR')),
    title: yup
        .string()
        .matches(/(Mr|Mrs|Ms|Miss|Others)/)
        .required(() => i18n.t('ICO.PERSONAL.ABOUT_YOU_TITLE_ERROR')),
    firstName: yup
        .string()
        .max(15, ({ max }) => i18n.t(CHARACTERS_AT_MOST, { filedText: i18n.t('ICO.PERSONAL.FIRST_NAME'), max: max }))
        .required(() => i18n.t('ICO.PERSONAL.FIRST_NAME_ERROR'))
        .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(COMMON_SPECIAL_ERROR)),
    lastName: yup
        .string()
        .max(15, ({ max }) => i18n.t(CHARACTERS_AT_MOST, { filedText: i18n.t('ICO.PERSONAL.LAST_NAME'), max: max }))
        .required(() => i18n.t('ICO.PERSONAL.LAST_NAME_ERROR'))
        .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(COMMON_SPECIAL_ERROR)),
    dateOfBirth: yup
        .date()
        .transform(function (value, originalValue) {
            if (this.isType(originalValue)) {
                return value;
            }
            return DateTime.fromISO(originalValue).isValid ? value : new Date('');
        })
        .typeError(() => i18n.t('ICO.PERSONAL.DATE_OF_BIRTH_TYPEERROR'))
        .min('1900-01-01', () => i18n.t('ICO.PERSONAL.DATE_OF_BIRTH_TYPEERROR'))
        .required(() => i18n.t('ICO.PERSONAL.DATE_OF_BIRTH_ERROR')),
    countryOfBirth: yup
        .string()
        .required(() => i18n.t('ICO.PERSONAL.COUNTRY_OF_BIRTH_ERROR')),
    documentType: yup
        .string()
        .required(() => i18n.t('ICO.PERSONAL.PERMIT_TYPE_ERROR')),
    placeOfIssue: yup
        .string()
        .when("documentType", {
            is: val => val === 'PASSPORT',
            then: yup.string().required(() => i18n.t('ICO.PERSONAL.PERMIT_COUNTRY_ERROR'))
        }),
    permitNumber: yup
        .string()
        .when("documentType", {
            is: val => val,
            then: yup
                .string()
                .required(() => i18n.t('ICO.PERSONAL.PERMIT_NUMBER_INVALID'))
                .matches(/^[A-Za-z0-9]{9}$/, () => i18n.t('ICO.PERSONAL.PERMIT_NUMBER_INVALID'))
        }),
    hasOtherNationality: yup
        .string()
        .matches(/(yes|no)/)
        .required(() => i18n.t('ICO.PERSONAL.NATIONALITY_ERROR')),
    nationality2: yup
        .string()
        .when("hasOtherNationality", {
            is: val => val === 'no',
            then: yup.string().required(() => i18n.t('ICO.PERSONAL.OTHER_NATIONALITY_ERROR'))
        }),
    nationality3: yup
        .string()
        .when("hasOtherNationality", {
            is: val => val === 'no' && addAnotherCount === 2,
            then: yup.string().required(() => i18n.t('ICO.PERSONAL.OTHER_NATIONALITY_ERROR'))
        })
});
const PersonalDetails = () => {
    const initialValues = getUserSessionDataByKey('personal');
    const { t } = useTranslation();
    const lan = i18n.language;
    const titleOptions = DataTitle[lan];
    let tmpTitle = [];
    const history = useHistory();
    const countryList = Country[lan];
    const nonChinaCountryList = countryList.filter((value) => {
        return value.value !== 'China';
    });
    const { isPremier, lang } = getUserSessionDataByKey('global');
    useTagging({
        'page_url': TAG_PAGE_URL,
        'page_language': lang,
        'page_name': TAG_PAGE_NAME,
        'page_subcategory': 'personal information',
        'raw_datalayer': '5745v6',
        'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
        'funnel_step': '2',
        'funnel_step_name': 'personal information'
    });

    const taggingParams = {
        'page_url': TAG_PAGE_URL,
        'page_language': lang,
        'event_category': 'error',
        'event_action': 'field validation',
        'page_name': TAG_PAGE_NAME,
        'raw_datalayer': '5745v8'
    };

    return (
        <>
            <FormLayout>
                <WithI18nProgressIndicator current={1} />
                <Title heading={t('ICO.PERSONAL.TITLE')} legend={t('ICO.PERSONAL.DESC')} />
                <Form
                    initialValues={initialValues}
                    validationSchema={schema}
                    validate={({ dateOfBirth }) => {
                        const errors = {};
                        if (dateOfBirth !== 'incomplete' && timeAtLeast18Years(dateOfBirth)) {
                            errors.dateOfBirth = t('ICO.PERSONAL.DATE_OF_BIRTH_PAST');
                        }
                        return errors;
                    }}
                    onSubmit={({ values }) => {
                        setUserSessionDataByKey('personal', values);
                        history.push('/info/address');
                    }}
                    onChange={rePrevious}
                >
                    {({ values, isSubmitting, isValid, errors }) => {
                        //pre-fill title
                        tmpTitle = titleOptions.filter((val) => {
                            return values.gender !== 'Male' ? val.value !== 'Mr' : val.value === 'Mr' || val.value === 'Others';
                        });
                        if (isSubmitting && !isValid && JSON.stringify(errors) !== '{}') {
                            validationTagging({ errors, taggingParams });
                        }
                        return (
                            <>
                                <FormFeedback textMap={{ title: t('ICO.COMMON.CORRECT'), of: '/' }} />
                                <Heading level={2} accentBar>{t('ICO.PERSONAL.CONTACT_DETAILS')}</Heading>
                                <Field
                                    marginBottom={5}
                                    name="email"
                                >
                                    <Label marginBottom={1}>
                                        {t('ICO.PERSONAL.EMAIL_ADDRESS')}
                                    </Label>
                                    <Paragraph id="email_address_desc" hint>{t('ICO.PERSONAL.EMAIL_ADDRESS_DESC')}</Paragraph>
                                    <TextInput aria-describedby="email_address_desc" fullWidth />
                                    <FieldFeedback />
                                </Field>
                                <Field
                                    marginBottom={5}
                                    name="phoneNumber"
                                >
                                    <Label marginBottom={1}>
                                        {t('ICO.PERSONAL.PHONE_NUMBER')}
                                    </Label>
                                    <Paragraph id="phone_number_desc" hint>{t('ICO.PERSONAL.PHONE_NUMBER_DESC')}</Paragraph>
                                    <TelephoneInput
                                        id="phoneNumber-field"
                                        aria-describedby="phone_number_desc"
                                        countryCode="CN"
                                        maxLength={values.phoneNumber.countryCode === 'CN' ? 11 : 20}
                                        extendedValues
                                        locale={lan !== 'en' ? 'zh' : lan}
                                        textMap={{
                                            selectCountry: "Select area code",
                                            countryList: CountryOfPhone[lan]
                                        }}
                                        fullWidth />
                                    <FieldFeedback />
                                </Field>
                                <Section>
                                    <Heading level={2} accentBar>{t('ICO.PERSONAL.ABOUT_YOU')}</Heading>
                                    <Field name="gender" onChange={(next, { value, ...rest }) => {
                                        tmpTitle = titleOptions.filter((val) => {
                                            return value !== 'Male' ? val.value !== 'Mr' : val.value === 'Mr' || val.value === 'Others';
                                        });
                                        next({ value, ...rest });
                                    }}>
                                        <Label>{t('ICO.PERSONAL.GENDER')}</Label>
                                        <Select
                                            placeholder={t(COMMON_SELECT)}
                                            options={Gender[lan]}
                                            fullWidth />
                                        <FieldFeedback />
                                    </Field>
                                    <Field name="title">
                                        <Label>
                                            {t('ICO.PERSONAL.ABOUT_YOU_TITLE')}
                                        </Label>
                                        <Select
                                            placeholder={t(COMMON_SELECT)}
                                            options={tmpTitle}
                                            fullWidth />
                                        <FieldFeedback />
                                    </Field>
                                    <Field name='firstName'
                                        onBlur={(next, { value, ...rest }) => {
                                            values.fullName = `${values.lastName}${values.firstName}`
                                            next({ value, ...rest });
                                        }}>
                                        <Label>
                                            {t('ICO.PERSONAL.FIRST_NAME')}
                                        </Label>
                                        <Paragraph id="first_name_desc" hint>{t('ICO.PERSONAL.FIRST_NAME_DESC')}</Paragraph>
                                        <TextInput aria-describedby="first_name_desc" fullWidth />
                                        <FieldFeedback />
                                    </Field>
                                    <Field name='lastName'
                                        onBlur={(next, { value, ...rest }) => {
                                            values.fullName = `${values.lastName}${values.firstName}`
                                            next({ value, ...rest });
                                        }}>
                                        <Label>
                                            {t('ICO.PERSONAL.LAST_NAME')}
                                        </Label>
                                        <Paragraph id="last_name_desc" hint>{t('ICO.PERSONAL.LAST_NAME_DESC')}</Paragraph>
                                        <TextInput aria-describedby="last_name_desc" fullWidth />
                                        <FieldFeedback />
                                    </Field>
                                    <Field name="dateOfBirth"
                                        marginBottom={5}>
                                        <Legend>
                                            {t(DATE_OF_BIRTH)}
                                        </Legend>
                                        <Paragraph hint>
                                            {t('ICO.PERSONAL.DATE_OF_BIRTH_DESC')}
                                        </Paragraph>
                                        <DateInput
                                            textMap={{
                                                dayAriaLabel: `${t(DATE_OF_BIRTH)} ${t('ICO.PERSONAL.DATE_OF_BIRTH_DAY')}`,
                                                monthAriaLabel: `${t(DATE_OF_BIRTH)} ${t('ICO.PERSONAL.DATE_OF_BIRTH_MONTH')}`,
                                                yearAriaLabel: `${t(DATE_OF_BIRTH)} ${t('ICO.PERSONAL.DATE_OF_BIRTH_YEAR')}`,
                                                day: t('ICO.PERSONAL.DATE_OF_BIRTH_DAY'),
                                                month: t('ICO.PERSONAL.DATE_OF_BIRTH_MONTH'),
                                                year: t('ICO.PERSONAL.DATE_OF_BIRTH_YEAR')
                                            }}
                                        />
                                        <FieldFeedback />
                                    </Field>
                                    <Field name="countryOfBirth">
                                        <Label>
                                            {t('ICO.PERSONAL.COUNTRY_OF_BIRTH')}
                                        </Label>
                                        <Select
                                            placeholder={t('ICO.PERSONAL.COUNTRY_OF_BIRTH_PLACEHOLDER')}
                                            options={countryList}
                                            fullWidth
                                        />
                                        <FieldFeedback />
                                    </Field>
                                </Section>
                                <Section>
                                    <Heading level={2} accentBar>{t('ICO.PERSONAL.IDENTITY_DETAILS')}</Heading>
                                    <Field name="documentType">
                                        <Label>
                                            {t('ICO.PERSONAL.PERMIT_TYPE')}
                                        </Label>
                                        <Select
                                            placeholder={t(COMMON_SELECT)}
                                            options={IdType[lan]}
                                            fullWidth />
                                        <FieldFeedback />
                                    </Field>
                                    {values.documentType === 'PASSPORT' &&
                                        <Field name="placeOfIssue">
                                            <Label>
                                                {t('ICO.PERSONAL.PERMIT_COUNTRY')}
                                            </Label>
                                            <Select
                                                placeholder={t('ICO.PERSONAL.COUNTRY_OF_BIRTH_PLACEHOLDER')}
                                                options={countryList}
                                                fullWidth
                                            />
                                            <FieldFeedback />
                                        </Field>
                                    }
                                    {values.documentType &&
                                        <Field name="permitNumber">
                                            <Label marginBottom={1}>
                                                {t('ICO.PERSONAL.PERMIT_NUMBER')}
                                            </Label>
                                            <Paragraph id="permit_number_desc" hint>{t('ICO.PERSONAL.PERMIT_NUMBER_DESC')}</Paragraph>
                                            <TextInput aria-describedby="permit_number_desc" fullWidth />
                                            <FieldFeedback />
                                        </Field>
                                    }
                                    <Fieldset
                                        name="hasOtherNationality"
                                        marginBottom={3}
                                        onChange={(next, { value }) => {
                                            if (value === 'yes') {
                                                const reset = {
                                                    nationality2: '',
                                                    nationality3: ''
                                                }
                                                Object.assign(values, reset);
                                            }
                                            linkTagging({
                                                'page_url': TAG_PAGE_URL,
                                                'page_language': lang,
                                                'event_category': 'content',
                                                'event_action': 'button',
                                                'event_content': `do you hold any other nationality than chinese: ${value === 'yes' ? 'no' : 'yes'}`,
                                                'page_name': TAG_PAGE_NAME,
                                                'raw_datalayer': '5745v7'
                                            });
                                            next({ value })
                                        }}>
                                        <Label>
                                            {t('ICO.PERSONAL.NATIONALITY_QUESTION')}
                                        </Label>
                                        <YesNoRadio textMap={{
                                            yes: t('ICO.COMMON.NO'),
                                            no: t('ICO.COMMON.YES')
                                        }} />
                                        <FieldFeedback />
                                    </Fieldset>
                                    {values.hasOtherNationality === 'no' && <AddAnother
                                        itemInitialValue=""
                                        max={2}
                                        min={1}
                                        minOnMount={1}
                                        name="otherNationality"
                                        renderAddButtonText={() => t('ICO.PERSONAL.ADD_ANOTHER_NATIONALITY_ADD')}
                                        renderRemoveButtonText={() => t('ICO.PERSONAL.ADD_ANOTHER_NATIONALITY_REMOVE')}
                                        onAdd={() => {
                                            addAnotherCount += 1;
                                            return addAnotherCount
                                        }}
                                        onRemove={(even) => {
                                            const selectName = even.currentTarget.parentElement.parentElement.getElementsByTagName('select')[0].getAttribute('name');
                                            const index = parseInt(selectName.charAt(selectName.length - 1));
                                            if (index === 2) {
                                                values.nationality2 = values.nationality3;
                                                values.nationality3 = '';
                                            } else {
                                                values[selectName] = '';
                                            }
                                            addAnotherCount -= 1;
                                        }}
                                        fullWidth
                                    >
                                        {({ path }) => (
                                            <Field name={`nationality${parseInt(path.charAt(path.length - 1)) + 2}`} marginBottom={0}>
                                                <Label>{t('ICO.PERSONAL.NATIONALITY')}</Label>
                                                <Paragraph id="nationality_desc" hint>{t('ICO.PERSONAL.NATIONALITY_DESC')}</Paragraph>
                                                <Select
                                                    aria-describedby="nationality_desc"
                                                    placeholder={t('ICO.PERSONAL.NATIONALITY_PLACEHOLDER')}
                                                    options={nonChinaCountryList}
                                                    fullWidth
                                                />
                                                <FieldFeedback />
                                            </Field>
                                        )}
                                    </AddAnother>}
                                </Section>
                                <ButtonWithBack />
                            </>
                        );
                    }}
                </Form>
            </FormLayout>
        </>
    );
}

export default PersonalDetails;
