export const cardData = {
    zh_CN: {
        settlmentList: [
            {
                value: 'Minimum payment due', label: '最低还款额',
            },
            {
                value: '% of statement balance', label: '账单余额 %',
            }
        ],
        statementPreferencesList: [
            {
                value: '1', label: '本人希望通过香港汇丰流动理财应用程序（HSBC HK App）及／或网上理财收取电子结单，并同意电子结单及电子通知书服务条款及细则<sup>1</sup>。',
            },
            {
                value: '2', label: '本人希望收取邮寄结单',
            }
        ],
        termsConditionsPreferencesList: [
            {
                value: '1', label: '通过电子邮件收取迎新小册子，包括主卡和附属卡账户的条款及细则<sup>2</sup>',
            },
            {
                value: '2', label: '以邮寄方式收取迎新小册子，包括主卡和附属卡账户的条款及细则',
            }
        ],
        cardList: [
            {
                value: 'Integrated Savings (HSBC Premier)', label: '综合储蓄（汇丰卓越理财）',
            },
            {
                value: 'Integrated Current (HSBC Premier)', label: '综合往来（汇丰卓越理财）',
            }
        ],
    },
    zh_HK: {
        settlmentList: [
            {
                value: 'Minimum payment due', label: '最低還款額',
            },
            {
                value: '% of statement balance', label: '結單結餘 %',
            }
        ],
        statementPreferencesList: [
            {
                value: '1', label: '本人欲透過香港滙豐流動理財應用程式（HSBC HK App）及／或網上理財收取電子結單，並同意電子結單及電子通知書服務條款及細則<sup>1</sup>。',
            },
            {
                value: '2', label: '本人欲收取郵寄結單',
            }
        ],
        termsConditionsPreferencesList: [
            {
                value: '1', label: '透過電郵收取迎新小冊子，包括主卡及附屬卡戶口的條款及細則<sup>2</sup>',
            },
            {
                value: '2', label: '以郵寄方式收取迎新小冊子，包括主卡及附屬卡戶口的條款及細則',
            }
        ],
        cardList: [
            {
                value: 'Integrated Savings (HSBC Premier)', label: '綜合儲蓄（滙豐卓越理財）',
            },
            {
                value: 'Integrated Current (HSBC Premier)', label: '綜合往來（滙豐卓越理財）',
            }
        ],
    },
    en: {
        settlmentList: [
            {
                value: 'Minimum payment due', label: 'Minimum payment due',
            },
            {
                value: '% of statement balance', label: '% of statement balance',
            }
        ],
        statementPreferencesList: [
            {
                value: '1', label: 'I want to receive eStatements via the HSBC HK Mobile Banking app (HSBC HK App) and/or online banking, and agree to the eStatement & eAdvice service terms & conditions<sup>1</sup>.',
            },
            {
                value: '2', label: 'I want to receive paper statements',
            }
        ],
        termsConditionsPreferencesList: [
            {
                value: '1', label: 'Email me any welcome packs including the terms and conditions of my primary and supplementary card accounts<sup>2</sup>',
            },
            {
                value: '2', label: 'Send me any welcome packs including the terms and conditions of my primary and supplementary card accounts by post',
            }
        ],
        cardList: [
            {
                value: 'Integrated Savings (HSBC Premier)', label: 'Integrated Savings (HSBC Premier)',
            },
            {
                value: 'Integrated Current (HSBC Premier)', label: 'Integrated Current (HSBC Premier)',
            }
        ],
    }

}
