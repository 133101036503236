import React from 'react';
import * as yup from 'yup';
import {
  Section,
  Heading,
  Paragraph,
  HorizontalRule,
  FormLayout,
  Reveal,
  Callout,
  UnorderedList,
  ListItem,
} from '@piggybank/core';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import {
  Form,
  FormFeedback,
  Field,
  Label,
  Select,
  TextInput,
  FieldFeedback,
  YesNoRadio,
  AddAnother,
  Fieldset,
  Hint,
  Radio,
} from '@piggybank/form';
import { useTranslation } from 'react-i18next';
import { getUserSessionDataByKey, setUserSessionDataByKey } from 'storage/userStorage';
import { Country, ReasonForOpening as ReasonForOpeningList, CartAddress as CartAddressList } from 'referenceData';
import { WithI18nProgressIndicator } from 'components/WithI18nPiggybank';
import { ButtonWithBack } from 'components/ButtonWithBack';
import { linkTagging, useTagging, validationTagging } from 'tealiumTrack';
import { rePrevious } from 'utils/util';
import './custom.css';

const HKSAR = 'Hong Kong SAR';
const TAG_PAGE_URL = '/forms/your-address';
const TAG_PAGE_NAME = 'pws:forms:your address';

const LINE_ERROR_MESSAGE = 'ICO.ADDRESS.LINE_ERROR_MESSAGE';
const LINE_ERROR_MESSAGE_LT = 'ICO.ADDRESS.LINE_ERROR_MESSAGE_LT';
const SPECIAL_ERROR = 'ICO.COMMON.SPECIAL_ERROR';
const LINE_ERROR_MESSAGE1 = 'ICO.ADDRESS.LINE_ERROR_MESSAGE1';
const LINE_ERROR_MESSAGE_LT1 = 'ICO.ADDRESS.LINE_ERROR_MESSAGE_LT1';
const LINE_ERROR_MESSAGE_LT2 = 'ICO.ADDRESS.LINE_ERROR_MESSAGE_LT2';
const LINE_ERROR_MESSAGE2 = 'ICO.ADDRESS.LINE_ERROR_MESSAGE2';
const POSTALCODEERROR = 'ICO.ADDRESS.POSTALCODEERROR';
const POSTALCODE_ERROE = 'ICO.ADDRESS.POSTALCODE_ERROE';
const CURRENT_ERRORS = 'ICO.ADDRESS.CURRENT_ERRORS';
const COUNTRY_ERROR_MESSAGE = 'ICO.ADDRESS.COUNTRY_ERROR_MESSAGE';

const schema = yup.object().shape({
  currentResidentialAddress: yup.object().shape({
    addressLine1: yup
      .string()
      .required(() => i18n.t(LINE_ERROR_MESSAGE))
      .max(35, ({ max }) => i18n.t(LINE_ERROR_MESSAGE_LT, { count: max }))
      .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR)),
    addressLine2: yup
      .string()
      .required(() => i18n.t(LINE_ERROR_MESSAGE1))
      .max(35, ({ max }) => i18n.t(LINE_ERROR_MESSAGE_LT1, { count: max }))
      .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR)),
    addressLine3: yup
      .string()
      .required(() => i18n.t(LINE_ERROR_MESSAGE2))
      .max(24, ({ max }) => i18n.t(LINE_ERROR_MESSAGE_LT2, { count: max }))
      .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR)),
    postalCode: yup
      .string()
      .required(() => i18n.t(POSTALCODEERROR))
      .max(10, ({ max }) => i18n.t(POSTALCODE_ERROE, { count: max }))
      .matches(/^[A-Za-z 0-9_-]*$/, () => i18n.t(CURRENT_ERRORS)),
    addressCountry: yup
      .string()
      .required(() => i18n.t(COUNTRY_ERROR_MESSAGE)),
  }),
  correspondenceAddressDifferent: yup
    .string()
    .matches(/(yes|no)/)
    .required(() => i18n.t('ICO.ADDRESS.CORRESPONDENCE_SUB_TITLE')),
  correspondenceAddress: yup.array().of(
    yup.object().shape({
      addressLine1: yup
        .string()
        .required(() => i18n.t(LINE_ERROR_MESSAGE))
        .max(35, ({ max }) => i18n.t(LINE_ERROR_MESSAGE_LT, { count: max }))
        .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR)),
      addressLine2: yup
        .string()
        .required(() => i18n.t(LINE_ERROR_MESSAGE1))
        .max(35, ({ max }) => i18n.t(LINE_ERROR_MESSAGE_LT1, { count: max }))
        .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR)),
      addressLine3: yup
        .string()
        .required(() => i18n.t(LINE_ERROR_MESSAGE2))
        .max(24, ({ max }) => i18n.t(LINE_ERROR_MESSAGE_LT2, { count: max }))
        .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR)),
      postalCode: yup
        .string()
        .required(() => i18n.t(POSTALCODEERROR))
        .max(10, ({ max }) => i18n.t(POSTALCODE_ERROE, { count: max }))
        .matches(/^[A-Za-z 0-9_-]*$/, () => i18n.t(CURRENT_ERRORS)),
      addressCountry: yup
        .string()
        .required(() => i18n.t(COUNTRY_ERROR_MESSAGE)),
    })
  ),
  cartAddress: yup.string().required(() => i18n.t('ICO.ADDRESS.CARDADDRESS_ERROR_MESSAGE')),
  atmCountry: yup.string().when('cartAddress', {
    is: val => val === '2',
    then: yup.string()
      .required(() => i18n.t('ICO.ADDRESS.ATMCARD_ERROR_MESSAGE'))
      .max(35, ({ max }) => i18n.t('ICO.ADDRESS.ATMCARD_ERROR_MESSAGE_LT', { count: max }))
      .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR))
  }),
  selectReasonsForOpeningAccount: yup.string().when('currentResidentialAddress', {
    is: (val) => val.addressCountry !== '' && val.addressCountry !== HKSAR,
    then: yup.string()
      .required(() => i18n.t('ICO.ADDRESS.APPLICATION_SUB_TITLE_ERROR_MESSAGE')),
  }),
  otherReasonForOpeningAccountInAnotherCountryText: yup.string().when('selectReasonsForOpeningAccount', {
    is: (val) => val === 'SOMETHING_ELSE',
    then: yup.string()
      .required(() => i18n.t('ICO.ADDRESS.OTHER_REASON_OPENING_ERROR_MESSAGE'))
      .max(60, ({ max }) => i18n.t('ICO.ADDRESS.OTHER_REASON_OPENING_ERROR_MESSAGE_TL', { count: max }))
      .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(SPECIAL_ERROR))
  })
});

const Address = () => {
  const initialValues = getUserSessionDataByKey('address');
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { isPremier, lang } = getUserSessionDataByKey('global');
  const lan = i18n.language;
  const countryList = Country[lan];
  const ReasonForOpeningSelectOptions = ReasonForOpeningList.map(item => {
    return {
      value: item.value,
      label: item[lan]
    }
  });

  useTagging({
    'page_url': TAG_PAGE_URL,
    'page_language': lang,
    'page_name': TAG_PAGE_NAME,
    'page_subcategory': 'your address',
    'raw_datalayer': '5745v9',
    'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
    'funnel_step': '3',
    'funnel_step_name': 'your address'
  });

  const taggingParams = {
    'page_url': TAG_PAGE_URL,
    'page_language': lang,
    'event_category': 'error',
    'event_action': 'field validation',
    'page_name': TAG_PAGE_NAME,
    'raw_datalayer': '5745v13'
  };

  const AddressFields = (path, values, setFieldValue, i18nText) => (
    <>
      {path !== 'currentResidentialAddress' && <Heading level={5} >{t(i18nText)}</Heading>}
      {['addressLine1', 'addressLine2', 'addressLine3'].map((name, index) => (
        <Field name={`${path}.${name}`} key={index}>
          <Label>{t(`ICO.ADDRESS.LINE${index}`)}</Label>
          <TextInput />
          <FieldFeedback />
        </Field>
      ))}
      <Field name={`${path}.postalCode`}>
        <Label>{t('ICO.ADDRESS.POSTALCODE')}</Label>
        <TextInput />
        <FieldFeedback />
      </Field>
      <Field name={`${path}.addressCountry`}
        onChange={(next, { value, ...rest }) => {
          if (path === 'currentResidentialAddress' && value === HKSAR) {
            setFieldValue('selectReasonsForOpeningAccount', '');
            if (value !== 'SOMETHING_ELSE') {
              setFieldValue('otherReasonForOpeningAccountInAnotherCountryText', '');
            }
          }

          linkTagging({
            'page_url': TAG_PAGE_URL,
            'page_language': lang,
            'event_category': 'content',
            'event_action': 'dropdown',
            'event_subcategory': `${path}.addressCountry`,
            'event_content': `country/region: ${value}`,
            'page_name': TAG_PAGE_NAME,
            'raw_datalayer': '5745v10'
          });
          next({ value, ...rest });
        }}
      >
        <Label>{t('ICO.ADDRESS.COUNTRY')}</Label>
        <Select
          options={countryList}
          placeholder={t('ICO.COMMON.SELECT')}
        />
        <FieldFeedback />
      </Field>
    </>
  );

  function cartAddressHanld({ next, value, rest }, { setFieldValue }) {
    if (value !== '2') {
      setFieldValue('atmCountry', '');
    }
    linkTagging({
      'page_url': TAG_PAGE_URL,
      'page_language': lang,
      'event_category': 'content',
      'event_action': 'radio',
      'event_content': `atm delivery: ${value !== '2' ? 'by mail' : 'collect personally'}`,
      'page_name': TAG_PAGE_NAME,
      'raw_datalayer': '5745v11'
    });
    next({ value, ...rest });
  }

  function selectReasonsForOpeningAccountHandle({ next, value, rest, setFieldValue }) {
    if (value !== 'SOMETHING_ELSE') {
      setFieldValue('otherReasonForOpeningAccountInAnotherCountryText', '');
    }
    linkTagging({
      'page_url': TAG_PAGE_URL,
      'page_language': lang,
      'event_category': 'content',
      'event_action': 'dropdown',
      'event_content': `reason for opening account: ${value}`,
      'page_name': TAG_PAGE_NAME,
      'raw_datalayer': '5745v12'
    });
    next({ value, ...rest });
  }

  return (
    <>
      <FormLayout>
        <WithI18nProgressIndicator current={2} />
        <Section>
          <Heading level={1}>{t('ICO.ADDRESS.TITLE')}</Heading>
          <Paragraph lead>{t('ICO.ADDRESS.SUB_TITLE')}</Paragraph>
          <HorizontalRule />
        </Section>
        <Form
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={({ values }) => {
            setUserSessionDataByKey('address', values);
            history.push('/info/financial');
          }}
          onChange={rePrevious}
        >
          {({ values, setFieldValue, isSubmitting, isValid, errors }) => {
            // tagging
            if (isSubmitting && !isValid && JSON.stringify(errors) !== '{}') {
              validationTagging({ errors, taggingParams });
            }
            return (
              <>
                <FormFeedback textMap={{ title: t('ICO.COMMON.CORRECT'), of: '/' }} />
                <Section>
                  <Heading level={2} accentBar>
                    {t('ICO.ADDRESS.CURRENT_ADRESS_TITLE')}
                  </Heading>
                  <Paragraph>{t('ICO.ADDRESS.CURRENT_ADRESS_SUB_TITLE')}</Paragraph>
                  <Paragraph>{t('ICO.ADDRESS.CURRENT_ADRESS_NOTE')}</Paragraph>
                  {AddressFields('currentResidentialAddress', values, setFieldValue)}
                  <Heading level={2} accentBar>{t('ICO.ADDRESS.CORRESPONDENCE_TITLE')}</Heading>
                  <Fieldset name="correspondenceAddressDifferent"
                    onChange={(next, { value, ...rest }) => {
                      const correspondenceAddress = values.correspondenceAddress;
                      if (value === 'no') {
                        correspondenceAddress.push({
                          addressLine1: '',
                          addressLine2: '',
                          addressLine3: '',
                          postalCode: '',
                          addressCountry: '',
                        });
                        setFieldValue('correspondenceAddress', correspondenceAddress);
                      } else {
                        correspondenceAddress.splice(0);
                      }
                      next({ value, ...rest });
                    }}>
                    <Label>
                      {t('ICO.ADDRESS.CORRESPONDENCE_SUB_TITLE')}
                    </Label>
                    <Hint dangerouslySetInnerHTML={{ __html: t('ICO.ADDRESS.CORRESPONDENCE_REMIND') }}></Hint>
                    <YesNoRadio textMap={{ yes: t('ICO.COMMON.YES'), no: t('ICO.COMMON.NO') }} />
                    <FieldFeedback marginTop={1} />
                  </Fieldset>
                  {values.correspondenceAddressDifferent === 'no' && (
                    <AddAnother
                      name="correspondenceAddress"
                      itemInitialValue={{
                        addressLine1: '',
                        addressLine2: '',
                        addressLine3: '',
                        postalCode: '',
                        addressCountry: ''
                      }}
                      min={1}
                      max={1}
                      showAdd={false}
                      fullWidth
                    >
                      {({ path }) => AddressFields(path, values, setFieldValue, 'ICO.ADDRESS.CORRESPONDENCE_FORM_TITLE')}
                    </AddAnother>
                  )}
                  <Callout type="information" marginBottom={6}>
                    <Heading level={3}>{t('ICO.ADDRESS.EADVICESERXICE')}</Heading>
                    <UnorderedList>
                      <ListItem>{t('ICO.ADDRESS.EADVICESERXICE2')}</ListItem>
                      <ListItem>{t('ICO.ADDRESS.EADVICESERXICE3')}</ListItem>
                      <ListItem>{t('ICO.ADDRESS.EADVICESERXICE4')}</ListItem>
                    </UnorderedList>
                    <Paragraph>{t('ICO.ADDRESS.EADVICESERXICE5')}</Paragraph>
                  </Callout>
                  <Heading level={2} accentBar>{t('ICO.ADDRESS.CARDADDRESS_TITLE')}</Heading>
                  <Fieldset name="cartAddress"
                    onChange={(next, { value, ...rest }) => cartAddressHanld({ next, value, rest }, { setFieldValue })}>
                    <Label>
                      {t('ICO.ADDRESS.CARDADDRESS_SUB_TITLE')}
                    </Label>
                    {
                      CartAddressList.map(item => <Radio key={item.value} value={item.value}>{item[lang]}</Radio>)
                    }
                    <FieldFeedback marginTop={1} />
                  </Fieldset>
                  {
                    values.cartAddress === '2' &&
                    <Reveal className='customReveal' accentBar marginBottom={0}>
                      <Field name="atmCountry">
                        <Label>{t('ICO.ADDRESS.ATMCARD')}</Label>
                        <TextInput marginBottom={2} />
                        <Hint>{t('ICO.ADDRESS.ATMCARD_REMIND')}</Hint>
                        <FieldFeedback />
                      </Field>
                    </Reveal>
                  }
                  {
                    (values.currentResidentialAddress.addressCountry !== '' && values.currentResidentialAddress.addressCountry !== HKSAR) && (
                      <>
                        <Heading level={2} accentBar>{t('ICO.ADDRESS.APPLICATION_TITLE')}</Heading>
                        <Fieldset
                          name="selectReasonsForOpeningAccount"
                          onChange={(next, { value, ...rest }) => selectReasonsForOpeningAccountHandle({ next, value, rest, setFieldValue })}
                        >
                          <Label>{t('ICO.ADDRESS.APPLICATION_SUB_TITLE')}</Label>
                          <Select
                            options={ReasonForOpeningSelectOptions}
                            placeholder={t('ICO.COMMON.SELECT')}
                          />
                          <FieldFeedback marginTop={1} />
                        </Fieldset>

                        {
                          values.selectReasonsForOpeningAccount === 'SOMETHING_ELSE' &&
                          <Reveal className='customReveal' accentBar marginBottom={0}>
                            <Field name="otherReasonForOpeningAccountInAnotherCountryText">
                              <Label>{t('ICO.ADDRESS.OTHER_REASON_OPENING')}</Label>
                              <TextInput marginBottom={2} />
                              <FieldFeedback />
                            </Field>
                          </Reveal>
                        }
                      </>
                    )
                  }
                </Section>
                <ButtonWithBack />
              </>
            )
          }}
        </Form>
      </FormLayout>
    </>
  );
}

export default Address;
