export default [
    { value: 'MOVING_TO_HK_FOR_WORK_IN_THE_NEXT_6_MONTHS', en: 'You\'re moving to Hong Kong for work in the next 6 months', zh_CN: '准备在6个月内到香港工作', zh_HK: '準備於6個月內到香港工作' },
    { value: 'MOVING_TO_HK_FOR_STUDY_IN_THE_NEXT_6_MONTHS', en: 'You\'re moving to Hong Kong to study in the next 6 months', zh_CN: '准备在6个月内到香港读书', zh_HK: '準備於6個月內到香港讀書' },
    { value: 'PLAN_TO_SAVE_OR_INVEST_IN_HK', en: 'You plan to save/invest in Hong Kong', zh_CN: '打算在香港储蓄／投资', zh_HK: '打算在香港儲蓄／投資' },
    { value: 'PLAN_TO_BUT_PROPERTY_YOUR_OWN_IN_HK_IN_NEXT_6_MONTHS', en: 'You plan to buy a property in Hong Kong in the next 6 months', zh_CN: '打算于6个月内在香港置业', zh_HK: '打算於6個月內在香港置業' },
    { value: 'RECEIVE_INCOME_FROM_PROPERTY_YOU_OWN_IN_HK', en: 'You receive income from a property you own in Hong Kong', zh_CN: '在香港因出租物业而收取租金', zh_HK: '在香港因出租物業而收取租金' },
    { value: 'TRAVEL_REGULARLY_TO_HK_FOR_WORK', en: 'You travel regularly to Hong Kong for work', zh_CN: '经常到香港工作', zh_HK: '經常到香港工作' },
    { value: 'TRAVEL_REGULARLY_TO_HK_FOR_VACATION', en: 'You travel regularly to Hong Kong for vacation', zh_CN: '经常到香港度假', zh_HK: '經常到香港度假' },
    { value: 'MOVING_TO_HK_TO_LIVE_IN_THE_NEXT_6_MONTHS', en: 'You are moving to Hong Kong to live in the next 6 months', zh_CN: '准备在6个月内移居至香港', zh_HK: '準備於6個月內移居至香港' },
    { value: 'SOMETHING_ELSE', en: 'Something else', zh_CN: '其他', zh_HK: '其他' },
]
