import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import PersonalDetails from './pages/PersonalDetails';
import FinancialDetails from './pages/FinancialDetails';
import Address from './pages/Address';
import Declaration from './pages/Declaration';
import CreditCard from './pages/CreditCard';
import UsingAccount from './pages/UsingAccount';
import Review from "./pages/Review";
import ProductBasket from 'components/ProdectBasket'
import ScrollToTop from 'components/ScrollToTop';
import { getUserSessionData, getUserSessionDataByKey } from 'storage/userStorage';


const Info = () => {
  const { accountType, isPremier, enableCreditCard } = getUserSessionDataByKey('global');
  const history = useHistory();
  const isViewCreditCardPage = isPremier && enableCreditCard;

  useEffect(() => {
    if (JSON.stringify(getUserSessionData()) === '{}') {
      history.push('/');
    }
  }, [history]);

  return (
    <Page hasMargin={accountType ? 0 : null}>
      <ProductBasket />
      <Switch>
        <ScrollToTop>
          <Route path="/info/personal" component={PersonalDetails} />
          <Route path="/info/address" component={Address} />
          <Route path="/info/financial" component={FinancialDetails} />
          <Route path="/info/using" component={UsingAccount} />
          {isViewCreditCardPage && <Route path="/info/creditCard" component={CreditCard} />}
          <Route path="/info/review" component={Review} />
          <Route path="/info/declaration" component={Declaration} />
          {/* <Redirect exact from="/info" to="/info/personal" /> */}
        </ScrollToTop>
      </Switch>
    </Page>
  )
};

export default Info;
