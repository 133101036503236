import { default as initAddress} from './address';
import { default as initFinancial} from './financial';
import { default as initHappens} from './happens';
import { default as initUsing} from './using';
import { default as initCreditCard } from './creditCard';
import { default as initGlobal} from './language';
import { default as initDeclaration } from './declaration';
import { default as initPersonal } from './personal';
import { default as initReview } from './review';



export const initFormData = {
    ...initAddress,
    ...initUsing,
    ...initCreditCard,
    ...initGlobal,
    ...initFinancial,
    ...initHappens,
    ...initDeclaration,
    ...initPersonal,
    ...initReview
};
