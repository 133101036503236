import React, {Component} from "react";
import Language from "./pages/Language";
import Page from "../../components/Page";

class Guide extends Component{
    render(){
        return (
           <Page>
               <Language/>
           </Page>
        );
    }
}

export default Guide;
