
import React, { useState, useEffect } from 'react';
import { Paragraph, Button, ModalDialog, ButtonRow, Heading } from '@piggybank/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTagging, linkTagging } from 'tealiumTrack';
import { getUserSessionDataByKey } from 'storage/userStorage';

const TimeoutDialog = ({ continueApply }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [second, setSecond] = useState(60);
    const { isPremier, lang } = getUserSessionDataByKey('global');

    useEffect(() => {
        const timer = setInterval(() => {
            if (second !== 1) {
                setSecond(second - 1);
            } else {
                continueApply();
                history.replace('/timeout');
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        }
    });

    const ContinueApp = () => {
        linkTagging({
            'page_url': '/forms/timeout-popup',
            'page_language': lang,
            'event_category': 'content',
            'event_action': 'button',
            'event_content': 'continue application',
            'page_name': 'pws:forms:timeout popup',
            'raw_datalayer': '5745v37'
        });
        continueApply();
    }

    useTagging({
        'page_url': '/forms/timeout-popup',
        'page_language': lang,
        'page_type': 'application',
        'page_name': 'pws:forms:timeout popup',
        'page_subcategory': 'timeout popup',
        'raw_datalayer': '5745v36',
        'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
        'funnel_step_name': 'timeout popup'
    });
    return (
        <ModalDialog tile={t('ICO.HAPPENS.TIMEOUTS')} show={true} dismissible={false}>
            <Heading level={2}>
                {t('ICO.HAPPENS.TIMEOUTS')}
            </Heading>
            <Paragraph marginBottom={6}>
                {t('ICO.HAPPENS.WAIT')}
            </Paragraph>
            <Paragraph marginBottom={6}>
                {t('ICO.HAPPENS.REMAINING')}<span style={{ fontFamily: 'bold' }}>{second}{t('ICO.HAPPENS.SECONDS')}</span>
            </Paragraph>
            <Paragraph marginBottom={6}>
                {t('ICO.HAPPENS.TEN')}
            </Paragraph>
            <ButtonRow>
                <Button
                    type="button"
                    onClick={ContinueApp}
                >
                    {t('ICO.HAPPENS.TEN1')}
                </Button>
            </ButtonRow>
        </ModalDialog>
    )
}

export default TimeoutDialog;
