import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './industryView.css';
import { getCountrLikeData, getSearchCountryList } from 'utils/customerNameFormat';
import { financialDetailsData } from '../../referenceData/financialDetailsData/financialDetailsData';
import { useTranslation } from 'react-i18next';
import searchPng from '../../assets/image/search.png'
import clearPng from '../../assets/image/clear.png'
import { setUserSessionDataByKey, getUserSessionDataByKey } from 'storage/userStorage';
import {
  FormLayout,
} from '@piggybank/core';
const IndustryView = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const { t, i18n } = useTranslation();
  const { industryList } = financialDetailsData[i18n.language];
  const AllList = getCountrLikeData(industryList);
  const newAllList = searchValue ? getSearchCountryList(AllList, searchValue, i18n.language) : AllList;
  const selectValue = (v) => {
    setUserSessionDataByKey('financial', {
      ...getUserSessionDataByKey('financial'),
      industry: v
    });
    history.replace('/info/financial');
    history.goBack();
  }
  const changeValue = (e) => {
    setSearchValue(e.target.value)
  }
  const clear = () => {
    setSearchValue('')
  }
  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    }
  }
  return (
    <FormLayout>
      <div className="searchElement">
        <div className="inputkeyWord">
          <div className="search"></div>
          <img src={searchPng} className="search" alt="search icon" aria-hidden="true" />
          <input
            className="inputClasss"
            type="text"
            tabIndex="-1"
            role="searchbox"
            aria-label={searchValue || t('ICO.COMMON.SEARCH')}
            placeholder={t('ICO.COMMON.SEARCH')}
            onChange={changeValue}
            value={searchValue} />
          {searchValue && <img className="clear" src={clearPng} onClick={clear} alt="clear icon" role="button" tabIndex="0" aria-label={t('ICO.FINANCIAL.CLEARBUTTON')} />}
        </div>
        {searchValue &&
          <div className="sr-only" role="status">{newAllList.length}{t('ICO.FINANCIAL.FOUND')}</div>
        }
        {newAllList.map((item, index) => {
          return (
            item.groupId ?
              <div className="charElementBg" key={index}>
                <div className="charElement" id={item.groupId}>
                  {item.groupId}
                </div>
                {item.ctryList.map((element) => {
                  return (
                    <div className="country" key={element.value}>
                      <div role="button" tabIndex="0" className="name" onClick={() => selectValue(element)}>{element.label}</div>
                    </div>
                  )
                })
                }
              </div >
              :
              <>
                <div className="country" key={index}>
                  <div className="seachName" onClick={() => selectValue(item)} role="button" tabIndex="0">{item.label}</div>
                </div >
              </>
          )
        })}
        {
          newAllList.length === 0 &&
          <div className="error" >
            <div className="errorInfo" aria-label={t('ICO.FINANCIAL.SEARCHERRORINFO')}> {t('ICO.FINANCIAL.SEARCHERRORINFO')}</div >
          </div >}
        <ul className="charContent">
          {newAllList.map((item, index) => {
            return (
              <div key={index}>
                <li onClick={() => scrollToAnchor(item.groupId)} role="button" tabIndex="0">{item.groupId}</li>
              </div >
            )
          })}
        </ul >
      </div >
    </FormLayout>
  );
}

IndustryView.displayName = 'IndustryView';
export default IndustryView;
