import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Page as PiggybankPage,
    Masthead,
    LogoHsbcGlobal,
    LogoHsbcUk
} from '@piggybank/core';

import styles from './page.module.css';

const Page = ({ logoName, hasMargin = 5, ...rest }) => (
  <div className={`${styles.pageWrapper} ${hasMargin > 0 ? '': styles.nonBorder}`}>
    <PiggybankPage
      headerSlot={
        <Masthead
          marginBottom={hasMargin}
          logoSlot={
            <Link className={styles.homeLink} to="/">
              {logoName === 'hsbc-uk' ? <LogoHsbcUk /> : <LogoHsbcGlobal />}
            </Link>
          }
        />
      }
      {...rest}
    />
  </div>
);

Page.propTypes = {
  logoName: PropTypes.string,
};

Page.displayName = 'Page';

export default Page;
