import React from "react";
import {
    FormLayout,
    Image,
    Section,
    ListItem,
    Paragraph,
    Heading,
    UnorderedList
} from '@piggybank/core';
import Page from "../../../components/Page";
import TypeOne from "../../../assets/image/one.png";
import TypePremier from '../../../assets/image/premier.png';
import styles from './AccountType.module.css';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonWithBack } from "components/ButtonWithBack";
import { getUserSessionDataByKey } from 'storage/userStorage';
import { useTagging } from "tealiumTrack";
const AccountType = () => {
    const { t } = useTranslation();
    const { isPremier, lang } = getUserSessionDataByKey('global');
    let item1 = [], item2 = [], item3 = [];
    if (isPremier) {
        item1 = ['PREMIER_ITEM1_TXT1', 'PREMIER_ITEM1_TXT2', 'PREMIER_ITEM1_TXT3'];
        item2 = ['PREMIER_ITEM2_TXT1', 'PREMIER_ITEM2_TXT2'];
        item3 = ['PREMIER_ITEM3_TXT1'];
    } else {
        item1 = ['ONE_ITEM1_TXT1', 'ONE_ITEM1_TXT2', 'ONE_ITEM1_TXT3'];
        item2 = ['ONE_ITEM2_TXT1', 'ONE_ITEM2_TXT2'];
        item3 = ['ONE_ITEM3_TXT1'];
    }
    const history = useHistory();

    useTagging({
        'page_url': '/forms/account-type',
        'page_type': 'setting',
        'page_language': lang,
        'page_name': 'pws:forms:account type',
        'page_subcategory': 'account type',
        'raw_datalayer': '5745v2',
        'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
    });

    return (
        <Page>
            <FormLayout>
                <div className={styles.typeImg}>
                    <Image alt="HSBC One" src={isPremier ? TypePremier : TypeOne} width={'70%'} marginBottom={6} />
                </div>
                <Section>
                    <Heading
                        level={1}>{isPremier ? t('ICO.TYPE.PREMIER_TITLE') : t('ICO.TYPE.ONE_TITLE')}</Heading>
                    <Paragraph lead>{isPremier ? t('ICO.TYPE.PREMIER_DESC') : t('ICO.TYPE.ONE_DESC')}</Paragraph>
                    <Heading level={2}
                        accentBar>{isPremier ? t('ICO.TYPE.PREMIER_ITEM1') : t('ICO.TYPE.ONE_ITEM1')}</Heading>
                    <UnorderedList type={"tick"}>
                        {
                            item1.map((item, index) => (
                                <ListItem key={index}>{t(`ICO.TYPE.${item}`)}</ListItem>
                            ))
                        }
                    </UnorderedList>
                    <Heading level={2}
                        accentBar>{isPremier ? t('ICO.TYPE.PREMIER_ITEM2') : t('ICO.TYPE.ONE_ITEM2')}</Heading>
                    <UnorderedList type={"tick"}>
                        {
                            item2.map((item, index) => (
                                <ListItem key={index}>{t(`ICO.TYPE.${item}`)}</ListItem>
                            ))
                        }
                    </UnorderedList>
                    <Heading level={2}
                        accentBar>{isPremier ? t('ICO.TYPE.PREMIER_ITEM3') : t('ICO.TYPE.ONE_ITEM3')}</Heading>
                    <UnorderedList type={"tick"}>
                        {
                            item3.map((item, index) => (
                                <ListItem key={index}>{t(`ICO.TYPE.${item}`)}</ListItem>
                            ))
                        }
                    </UnorderedList>
                    {
                        !isPremier &&
                        <Paragraph>{`${t('ICO.TYPE.ONE_NOTE')}: ${t('ICO.TYPE.ONE_NOTE_TXT')}`}</Paragraph>
                    }
                </Section>
                <ButtonWithBack onClick={() => {
                    history.push('/Welcome');
                }} customText={t('ICO.TYPE.BUTTON')} />
            </FormLayout>
        </Page>
    );
}

export default AccountType;
