import { MockUserFetch } from "__mock__/mock";
import { getUserSessionData, setUserSessionDataByKey } from "storage/userStorage";
// 重新计时 timer out
export const rePrevious = () => {
    setUserSessionDataByKey('previous', +new Date());
}

export const throttle = (fn, delay) => {
    let previous = 0;
    return (params) => {
        const now = +new Date();
        if (now - previous > delay) {
            fn.call(null, params);
            previous = now;
        }
    }
}

// 复选框 -> 数组值
export const formatFormCheckboxArray = (checbox) => {
    if (JSON.stringify(checbox) === '{}') {
        return [];
    }
    const arrays = [];
    for (const key in checbox) {
        if (checbox[key]) {
            arrays.push(key);
        }
    }
    return arrays;
}

export const formatFormValueRequestBody = () => {
    const {
        global,
    } = getUserSessionData();

    return {
        applicationData: {
            productDetails: {
                productType: global.accountType,                                      // 账户类型 HSBC One /HSBC Premier  **(原accountType)**
            },
            customerLanguage: global.lang,
            accountUsage: getAccountUsage()
        },
        applicant: {
            personalDetails: getPersonalDetails(),
            contactDetails: getContactDetails(),
            employmentDetails: getEmploymentDetails(),
            financialDetails: getFinancialDetails(),
        },
        preferences: getPreferences(),
    }
}

function getPersonalDetails() {
    const {
        personal: {
            firstName,
            lastName,
            fullName,
            gender,
            title,
            nationality2,
            nationality3,
            dateOfBirth,
            documentType,
            placeOfIssue,
            permitNumber,
            countryOfBirth
        }
    } = getUserSessionData();

    const customerNames = () => {
        return [
            {
                nameType: "LOCAL", // 姓名类型 [PRIMARY-拼音, LOCAL-中文,  ALIAS-拼音曾用名1, ALIAS_LOCAL-中文曾用名1]
                firstName, // 名 len: 35
                lastName,  // 姓 len: 35
                fullName,  // 全名 len:70
            }
        ];
    }

    return {
        customerNames: customerNames(),
        gender,
        title,
        dateOfBirth: new Date(dateOfBirth),
        nationalityDetails: {              // 国籍(国家/地区)
            nationality: "China",          // 第一国籍(国家/地区) [CN, US, JP, GB, ...]
            nationality2: nationality2,    // 第二国籍(国家/地区) [CN, US, JP, GB, ...]
            nationality3: nationality3     // 第三国籍(国家/地区) [CN, US, JP, GB, ...]
        },
        identificationDocument: {
            documentType: documentType,
            placeOfIssue: placeOfIssue,
            documentReference: permitNumber //出入境许可证号码
        },
        countryOfBirth: countryOfBirth
    };
}

function getContactDetails() {
    const {
        personal: {
            email,
            phoneNumber: {
                extendedValues
            }
        },
        address: {
            currentResidentialAddress,
            correspondenceAddressDifferent,
            correspondenceAddress,
            cartAddress,
            atmCountry,
        },
    } = getUserSessionData();

    // addresses
    currentResidentialAddress.addressType = 'RESIDENTIAL_ADDRESS';

    const addresses = [currentResidentialAddress];

    if (correspondenceAddressDifferent === 'no') {
        const corrAddress = correspondenceAddress[0];
        corrAddress.addressType = 'CORRESPONDENCE_ADDRESS';
        addresses.push(corrAddress);
    }

    if (cartAddress === '2') {
        addresses.push({
            addressType: 'DELIVERY_ADDRESS',
            addressLine3: atmCountry
        });
    }

    return {
        telephoneNumbers: [{
            number: (extendedValues && extendedValues.phone),
            countryCode: (extendedValues && extendedValues.countryCallingCode)
        }],
        emailAddressList: [{
            emailAddress: email
        }],
        addresses
    }
}

function getEmploymentDetails() {
    const {
        financial: {
            employmentStatus,
            employmentRole,
            fullStatus,
            occupation,
            industry,
            employerName,
            own,
            addressCountry,
        },
    } = getUserSessionData();

    const employmentDetails = {};
    const employerAddress = {};
    employmentDetails.employmentStatus = employmentStatus;
    employmentDetails.employmentRole = employmentRole;
    employmentDetails.occupation = occupation;
    employmentDetails.industry = industry.value;
    if (fullStatus === 0) {
        employmentDetails.employerName = '';
    } else if (fullStatus === 1) {
        employmentDetails.employerName = employerName;
    } else if (fullStatus === 3) {
        employmentDetails.employerName = own;
    }
    employerAddress.addressCountry = addressCountry
    employmentDetails.employerAddress = employerAddress;

    return employmentDetails;
}

function getFinancialDetails() {
    const {
        financial: {
            employmentRole,
            fullStatus,
            amount,
            gross,
            additionalNationalities
        },
    } = getUserSessionData();

    // 客户经济状况
    const grossIncome = {};
    const otherIncome = {};
    //客户年收入
    if (employmentRole && employmentRole !== 'Employee') {
        otherIncome.amount = amount;
        otherIncome.currency = 'HKD'
    }
    //客户月收入总额
    if (employmentRole && employmentRole !== 'Sole Trader' && fullStatus !== 3) {
        grossIncome.amount = gross;
        grossIncome.currency = 'HKD'
    }

    const taxResidencyInfo = {};
    const taxResidencyDetails = [];
    additionalNationalities.forEach(item => {
        if (item.countryOfTaxResidency && item.agree) {
            taxResidencyDetails.push({
                countryOfTaxResidency: item.countryOfTaxResidency,
                reasonForNoTIN: item.reasonForNoTIN,
                explainForNotEligibleApply: item.explainForNotEligibleApply,
            })
        }
        if (item.countryOfTaxResidency && !item.agree) {
            taxResidencyDetails.push({
                countryOfTaxResidency: item.countryOfTaxResidency,
                reasonForNoTIN: item.reasonForNoTIN,
                taxDocumentIdNumber: item.taxDocumentIdNumber,
                explainForNotEligibleApply: item.explainForNotEligibleApply,
            })
        }
    })
    taxResidencyDetails.forEach(item => {
        if (item.reasonForNoTIN === 'EXPLANATION1' || item.reasonForNoTIN === 'EXPLANATION2') {
            item.explanationForNoTIN = item.reasonForNoTIN
            item.reasonForNoTIN = 'REASON3'
        }
    })
    taxResidencyInfo.taxResidencyDetails = taxResidencyDetails;

    return {
        grossIncome,
        otherIncome,
        taxResidencyInfo
    }
}

function getReasonForAccountOpeningObj(selectAccountPurpose, reasonForAccountOpening) {
    if (selectAccountPurpose.OTHER) {
        return { reasonForAccountOpening };
    }
    return {};
}

function getFundsDetailsObj(detailsObj) {
    const fundsDetails = {
        initialFundsSourceList: [],
        fundsOriginationCountry: []
    };

    for (const key in detailsObj) {
        if (!detailsObj[key].value) {
            continue;
        }
        const children = detailsObj[key].children;
        for (const childKey in children) {
            if (!children[childKey].value) {
                continue;
            }
            fundsDetails.initialFundsSourceList.push(childKey);
            if (childKey === 'TRANSFER_FROM_3RD_PARTY_ACCOUNT') {
                fundsDetails.fundsOriginationCountry = children[childKey].country;
                fundsDetails.initialDepositAmount = {
                    currency: 'HKD',
                    amount: children[childKey].depositAmount
                };
            }
            if (childKey === 'ANOTHER_SOURCE') {
                fundsDetails.initialFundsSourceRemark = children[childKey].other;
            }

        }
    }

    return fundsDetails;
}

function getFirstDepositDetails(sourceSame, firstDepositDetailsObj) {
    const firstDepositDetails = {
        firstDepositSameAsRegularFundsSource: sourceSame === 'yes'
    };

    if (sourceSame === 'yes') {
        return firstDepositDetails;
    }
    firstDepositDetails.initialFundsSourceList = [];
    firstDepositDetails.fundsOriginationCountry = [];
    for (const key in firstDepositDetailsObj) {
        if (!firstDepositDetailsObj[key].value) {
            continue;
        }
        const children = firstDepositDetailsObj[key].children;
        for (const childKey in children) {
            if (!children[childKey].value) {
                continue;
            }
            firstDepositDetails.initialFundsSourceList.push(childKey);
            if (childKey === 'TRANSFER_FROM_3RD_PARTY_ACCOUNT') {
                firstDepositDetails.fundsOriginationCountry = children[childKey].country;
                firstDepositDetails.initialDepositAmount = {
                    currency: 'HKD',
                    amount: children[childKey].depositAmount
                };
            }
            if (childKey === 'ANOTHER_SOURCE') {
                firstDepositDetails.initialFundsSourceRemark = children[childKey].other;
            }
        }
    }

    return firstDepositDetails;
}

function getConnectedPartiesDetailsObj(receivingMoney, approximateDepositAmount) {
    if (receivingMoney === 'yes') {
        return {
            connectedPartiesDetails: {
                approximateDepositAmount: {
                    amount: approximateDepositAmount,
                    currency: 'HKD'
                }
            }
        };
    }
    return {};
}

function getAccountUsage() {
    const {
        address: {
            currentResidentialAddress: {
                addressCountry
            },
            selectReasonsForOpeningAccount,
            otherReasonForOpeningAccountInAnotherCountryText
        },
        using: {
            selectAccountPurpose,
            reasonForAccountOpening,
            regularFundsDetailsObj,
            sourceSame,
            firstDepositDetailsObj,
            receivingMoney,
            approximateDepositAmount
        }
    } = getUserSessionData();

    const reasonForAccountOpeningObj = getReasonForAccountOpeningObj(
        selectAccountPurpose,
        reasonForAccountOpening
    );

    const connectedPartiesDetailsObj = getConnectedPartiesDetailsObj(
        receivingMoney,
        approximateDepositAmount
    );

    return {
        accountPurpose: formatFormCheckboxArray(selectAccountPurpose),
        ...reasonForAccountOpeningObj,
        regularFundsDetails: getFundsDetailsObj(regularFundsDetailsObj),
        firstDepositDetails: getFirstDepositDetails(sourceSame, firstDepositDetailsObj),
        hasConnectedParties: receivingMoney === 'yes',
        ...connectedPartiesDetailsObj,
        ...(addressCountry !== 'Hong Kong SAR' ? {
            reasonForOpeningAccountInAnotherCountry: selectReasonsForOpeningAccount
        } : null),
        ...(selectReasonsForOpeningAccount === 'SOMETHING_ELSE' ? {
            otherReasonForOpeningAccountInAnotherCountryText
        } : null),
    };
}

function setRelationshipWithHSBC(current3, relative2, relative3, relative4) {
    if (current3 !== 'yes' || !relative2.one) {
        return {
            hasRelationship: relative2.one ? '1' : '2'
        };
    }

    return {
        staffNo: relative3,
        totalAmountOfUnsecured: {
            amount: relative4,                                                                         // 数值
            currency: "HKD"                                                                            // 币种 [CNY, HKD, USD, ...] Hardcode HKD in FE is need
        },
        hasRelationship: relative2.one ? '1' : '2'
    }
}

function setRelationshipDetails(current3, relative, relative1, relative2) {
    if (current3 !== 'yes' || !relative2.two) {
        return {};
    }

    return {
        relatives: [{
            relationship: relative1,
            fullname: relative
        }]
    }
}

// Credit Card
function getPreferences() {
    const {
        global,
        address: {
            cartAddress
        },
        declaration: { preferredChannels, dontwant },
        creditCard: {
            isApplyCreditCard,
            creditCardType,
            statementPreferencesOptions,
            grpTermsAndConditions,
            settlment,
            settlmentValue,
            facillity,
            facillityValue,
            balanceValue,
            accounat,
            current3,
            relative,
            relative1,
            relative2,
            relative3,
            relative4
        }
    } = getUserSessionData();

    const debitCardPreferences = {
        deliveryAddressType: cartAddress
    }

    const relationshipDetails = setRelationshipDetails(current3, relative, relative1, relative2);
    relationshipDetails.relationshipWithHSBC = setRelationshipWithHSBC(current3, relative2, relative3, relative4);

    const creditCardData = {
        isAppliedCreditCard: isApplyCreditCard === 'yes' ? '1' : '2',
        creditCardType: creditCardType,                                        // [Visa Signature, HSBC Premier MasterCard]
        usingFreeATPOrEPSFacility: facillity === 'yes' ? '1' : '2',
        directDebitInstruction: settlment === 'yes' ? '1' : '2',              // [1: required, 2: not required]
        outOfLimitFacility: accounat === 'yes' ? '1' : '2',
        ...(settlment === 'yes' && { debitAccount: settlmentValue }),
        ...(settlmentValue && { amountOfMonthlyPayment: facillityValue !== '% of statement balance' ? '1' : '2' }),   // [1: Minimum payment due, 2: Monthly payment]
        ...(facillityValue === '% of statement balance' && { percentageOfMonthlyPayment: balanceValue }),
        relationshipDetails,
        statementPreferencesOptions,
        grpTermsAndConditions
    };

    // HSBC Premier
    const enableCreditCard = global.accountType === 'HSBC Premier' && global.enableCreditCard;
    return {
        ...(enableCreditCard && { creditCardPreference: creditCardData }),
        marketingPreferences: {
            preferredChannels: dontwant ? ['NO_NEED'] : preferredChannels
        },
        debitCardPreferences,

    };
}

export const userFetch = (url = process.env.submitApi) => {

    /* NOSONAR */
    if (process.env.MOCK === 'on') {
        if (sessionStorage.getItem('dummy') === 'on') {
            return MockUserFetch();
        }
    }

    return fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formatFormValueRequestBody())
    }).then(response => {
        const headers = new Headers();
        headers.set('Strict-Transport-Security', 'max-age=31536000; includeSubDomains');
        return new Response(response.body, {
            status: response.status,
            statusText: response.statusText,
            headers: headers
        });
    }).then(async (responseWithSTS) => {

        const result = await responseWithSTS.json();
        if (responseWithSTS.ok) {
            return result;
        }
    }).catch(() => {
        throw new Error('Network response was not ok.');
    });
}
