import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  Section,
  Heading,
  HorizontalRule,
  Paragraph,
  Reveal,
  FormLayout,
  UnorderedList,
  Expander,
  Callout,
  ListItem, Link, IconChevronLeftThick,
} from '@piggybank/core';
import {
  Form,
  FormFeedback,
  Fieldset,
  YesNoRadio,
  FieldFeedback,
  Label,
  Field,
  CurrencyInput,
  Radio,
  Select,
  TextInput,
  Checkbox,
  Legend, WizardPage, Navigation, Wizard, Hint
} from '@piggybank/form';
import i18n from "i18next";
import { WithI18nProgressIndicator } from 'components/WithI18nPiggybank';
import { getUserSessionDataByKey, setUserSessionDataByKey } from 'storage/userStorage';
import { relativeData } from '../../../referenceData/card/relative';
import { cardData } from '../../../referenceData/card/card';
import CreditCardTCs from "./CreditCardTCs";
import "./CreditCard.css";
import { rePrevious } from 'utils/util';
import { loadTagging, linkTagging, globalViewTaggingParams } from 'tealiumTrack';
import { default as initData } from '../../../storage/page/creditCard';

const BALANCE = '% of statement balance';
const COMMON_YES = 'ICO.COMMON.YES';
const COMMON_NO = 'ICO.COMMON.NO';
const COMMON_CONTINUE = 'ICO.COMMON.CONTINUE';
const COMMON_BACK = 'ICO.COMMON.BACK';
const COMMON_CORRECT = 'ICO.COMMON.CORRECT';
const TAG_PAGE_URL = '/forms/credit-card-mortgages-and-relationship';
const TAG_PAGE_NAME = 'pws:forms:credit card mortgages and relationship';

const schema1 = yup.object().shape({
  isApplyCreditCard: yup
    .string()
    .matches(/(yes|no)/)
    .required(() => i18n.t('ICO.CREDIT.APPLICATION_Q1_ERROR')),
});

const schema2 = yup.object().shape({
  agreeTC: yup.boolean().oneOf([true], () => i18n.t('ICO.CREDIT.LTA_CHECKBOX_ERROR'))
});

const schema3 = yup.object().shape({
  settlment: yup.string().required(() => i18n.t('ICO.CREDIT.SELDATAS')),
  settlmentValue: yup.string().when('settlment', {
    is: (val) => val === 'yes',
    then: yup.string().required(() => i18n.t('ICO.CREDIT.SELDATAS1')),
    otherwise: yup.string(),
  }),
  facillityValue: yup.string().when('settlmentValue', {
    is: (val) => val,
    then: yup.string().required(() => i18n.t('ICO.CREDIT.SELDATAS2')),
    otherwise: yup.string(),
  }),
  balanceValue: yup.string().when('facillityValue', {
    is: (val) => val === BALANCE,
    then: yup.string()
      .matches(/^([1-9]\d{0,1}|100)$/, () => i18n.t('ICO.CREDIT.ERROEMAX2'))
      .required(() => i18n.t('ICO.CREDIT.SELPAYMENT2')),
    otherwise: yup.string(),
  }),
  facillity: yup.string().required(() => i18n.t('ICO.CREDIT.SELDATAS3')),
  statementPreferencesOptions: yup.string().required(() => i18n.t('ICO.CREDIT.SELSTATEMENT_1')),
  grpTermsAndConditions: yup.string().required(() => i18n.t('ICO.CREDIT.SELSTATEMENT_2')),
  accounat: yup.string().required(() => i18n.t('ICO.CREDIT.SELDATAS4')),
  current3: yup.string().required(() => i18n.t('ICO.CREDIT.SELDATAS7')),
  relative2: yup.object().when('current3', {
    is: (val) => val === 'yes',
    then: yup.object().test(
      'relative2',
      () => i18n.t('ICO.CREDIT.SELDATAS9'),
      values => {
        for (const key in values) {
          if (values[key]) {
            return true;
          }
        }
        return false;
      }
    ),
  }),
  relative3: yup.string().when('relative2', {
    is: (val) => val.one,
    then: yup.string().max(8, ({ max }) => i18n.t('ICO.PERSONAL.CHARACTERS_AT_MOST', { filedText: i18n.t('ICO.CREDIT.ERROEMAX1'), max: max }))
      .required(() => i18n.t('ICO.CREDIT.SELDATAS8'))
      .matches(/^[0-9]*$/, () => i18n.t('ICO.CREDIT.STAFFERROR')),
  }),
  relative4: yup.string().when('relative2', {
    is: (val) => val.one,
    then: yup.string().required(() => i18n.t('ICO.CREDIT.RELATIVE20')),
  }),
  relative: yup.string().when('relative2', {
    is: val => val.two,
    then: yup.string().max(35, ({ max }) => i18n.t('ICO.PERSONAL.CHARACTERS_AT_MOST', { filedText: i18n.t('ICO.CREDIT.RELATIVE1'), max: max }))
      .required(() => i18n.t('ICO.CREDIT.SELRELATIVE1'))
      .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t('ICO.COMMON.SPECIAL_ERROR'))
  }),
  relative1: yup.string().when('relative2', {
    is: (val) => val.two,
    then: yup.string().required(() => i18n.t('ICO.CREDIT.SELRELATIVE')),
  }),
  agree: yup.boolean().oneOf([true], () => i18n.t('ICO.CREDIT.LTA_CHECKBOX_ERROR'))
});


const CreditCard = () => {
  const initialValues = getUserSessionDataByKey('creditCard');
  const { isPremier, lang } = getUserSessionDataByKey('global');
  const { t } = useTranslation();
  const history = useHistory();
  const { relative } = relativeData[lang];
  const { cardList, settlmentList, statementPreferencesList, termsConditionsPreferencesList } = cardData[lang];

  const creditGobalParams = {
    ...globalViewTaggingParams,
    'page_language': lang,
    'page_type': 'application',
    'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
  }
  const creditParams = [
    {
      'page_url': '/forms/credit-card-selection',
      'page_name': 'pws:forms:credit card selection',
      'page_subcategory': 'credit card selection',
      'raw_datalayer': '5745v40',
      'funnel_step_name': 'credit card selection'
    },
    {
      'page_url': '/forms/credit-card-terms-and-conditions',
      'page_name': 'pws:forms:credit card terms and conditions',
      'page_subcategory': 'credit card terms and conditions',
      'raw_datalayer': '5745v43',
      'funnel_step_name': 'credit card terms and conditions'
    },
    {
      'page_url': TAG_PAGE_URL,
      'page_name': TAG_PAGE_NAME,
      'page_subcategory': 'credit card mortgages and relationship',
      'raw_datalayer': '5745v44',
      'funnel_step_name': 'credit card mortgages and relationship'
    }
  ];

  function settlmentHandle({ next, value, rest }, { setFieldValue }) {
    if (value === 'no') {
      setFieldValue('settlmentValue', '');
      setFieldValue('facillityValue', '');
      setFieldValue('balanceValue', '');
    }
    linkTagging({
      'page_url': TAG_PAGE_URL,
      'page_language': lang,
      'event_category': 'content',
      'event_action': 'radio',
      'event_content': `direct debit instruction set-up: ${value}`,
      'page_name': TAG_PAGE_NAME,
      'raw_datalayer': '5745v45'
    });
    next({ value, ...rest });
  }

  function settlmentValueHandle({ next, value, rest }) {
    linkTagging({
      'page_url': TAG_PAGE_URL,
      'page_language': lang,
      'event_category': 'content',
      'event_action': 'radio',
      'event_content': `debit account: ${value === 'Integrated Savings (HSBC Premier)' ? 'yes' : 'no'}`,
      'page_name': TAG_PAGE_NAME,
      'raw_datalayer': '5745v46'
    });
    next({ value, ...rest });
  }

  function facillityValueHandle({ next, value, rest }, { setFieldValue }){
    if (value !== BALANCE) {
      setFieldValue('balanceValue', '');
    }
    linkTagging({
      'page_url': TAG_PAGE_URL,
      'page_language': lang,
      'event_category': 'content',
      'event_action': 'radio',
      'event_content': `monthly payment: ${value.toLocaleLowerCase()}`,
      'page_name': TAG_PAGE_NAME,
      'raw_datalayer': '5745v47'
    });
    next({ value, ...rest });
  }

  return (
    <FormLayout>
      <WithI18nProgressIndicator current={5} />
      <Wizard currentPage={1}
        onNavigate={({ currentPageIndex }) => {
          const taggingParams = {
            ...creditGobalParams,
            ...creditParams[currentPageIndex]
          }
          loadTagging(taggingParams);
        }}
        onSubmit={(values) => {
          setUserSessionDataByKey('creditCard', values);
          history.push('/info/review');
        }}>
        <WizardPage>
          {({ navigateTo }) => {
            const newGetUserSessionDataByKey = getUserSessionDataByKey('creditCard')
            if (newGetUserSessionDataByKey.pageType === 2) {
              navigateTo(2);
              setUserSessionDataByKey('creditCard', {
                ...getUserSessionDataByKey('creditCard'),
                pageType: 0
              })
            }
            return <Form
              initialValues={initialValues}
              validationSchema={schema1}
              onChange={rePrevious}
            >
              {({ values, setFieldValue }) => {
                return (
                  <Section marginBottom={6}>
                    <FormFeedback textMap={{ title: t(COMMON_CORRECT), of: '/' }} />
                    <Section>
                      <Heading level={1}>
                        {t('ICO.CREDIT.CARD')}
                      </Heading>
                      <Paragraph lead>{t('ICO.CREDIT.AS')}</Paragraph>
                      <HorizontalRule />
                    </Section>
                    <Heading level={2} accentBar>{t('ICO.CREDIT.APPLICATION')}</Heading>
                    <Fieldset name="isApplyCreditCard"
                      onChange={(next, { value, ...rest }) => {
                        linkTagging({
                          'page_url': '/forms/credit-card-selection',
                          'page_language': lang,
                          'event_category': 'content',
                          'event_action': 'radio',
                          'event_content': `apply for a credit card: ${value}`,
                          'page_name': 'pws:forms:credit card selection',
                          'raw_datalayer': '5745v41'
                        });
                        if (value === 'yes') {
                          setFieldValue('creditCardType', 'HSBC Premier MasterCard')
                        } else {
                          setFieldValue('creditCardType', '')
                        }
                        next({ value, ...rest });
                      }}
                    >
                      <Label id="application_q1">{t('ICO.CREDIT.APPLICATION_Q1')}</Label>
                      <YesNoRadio
                        aria-describedby="application_q1"
                        textMap={{
                          yes: t(COMMON_YES),
                          no: t(COMMON_NO)
                        }} />
                      <FieldFeedback />
                    </Fieldset>
                    {
                      values.isApplyCreditCard === 'yes' &&
                      <>
                        <Heading level={2} accentBar>{t('ICO.CREDIT.ACARDS')}</Heading>
                        <Paragraph>{t('ICO.CREDIT.CARDTYPE1')}</Paragraph>
                        <UnorderedList type={"tick"}>
                          <ListItem>{t('ICO.CREDIT.CARDTYPE1_DESC')}</ListItem>
                        </UnorderedList>
                      </>
                    }
                    <Navigation textMap={{ previous: t(COMMON_BACK), next: t(COMMON_CONTINUE) }} onNext={() => {
                      // if select no, reset data
                      if (values.isApplyCreditCard === 'no') {
                        initData.creditCard.isApplyCreditCard = values.isApplyCreditCard;
                        Object.assign(values, initData.creditCard);
                        setUserSessionDataByKey('creditCard', values);
                        history.push('/info/review');
                      }
                    }} />
                    <Link
                      buttonPadding
                      elementType="button"
                      hoverUnderline
                      iconSlotLeft={<span style={{ color: 'var(--red)' }}><IconChevronLeftThick inline /></span>}
                      strong={false}
                      type="button"
                      onClick={(() => {
                        history.goBack();
                      })}>
                      {t(COMMON_BACK)}
                    </Link>
                  </Section>
                )
              }}
            </Form>
          }
          }

        </WizardPage>
        <WizardPage>
          <FormFeedback textMap={{ title: t(COMMON_CORRECT), of: '/' }} />
          <Section>
            <Heading level={1}>
              {t('ICO.CREDIT.CARD')}
            </Heading>
            <Paragraph lead>{t('ICO.CREDIT.AS')}</Paragraph>
            <Heading level={1}>
              {t('ICO.CREDIT.BEFOREWESTART')}
            </Heading>
            <Paragraph lead>{t('ICO.CREDIT.AS1')}</Paragraph>
            <HorizontalRule />
          </Section>
          <CreditCardTCs />
          <Heading level={2} accentBar>{t('ICO.CREDIT.LTA_LABEL')}</Heading>
          <Form
            initialValues={initialValues}
            validationSchema={schema2}
            onChange={rePrevious}
          >
            <Field name="agreeTC" marginBottom={5}>
              <Checkbox>{t('ICO.CREDIT.LTA_CHECKBOX')}</Checkbox>
              <FieldFeedback />
            </Field>
            <Navigation textMap={{ previous: t(COMMON_BACK), next: t(COMMON_CONTINUE) }} />
          </Form>
        </WizardPage>
        <WizardPage>
          <Form
            initialValues={initialValues}
            validationSchema={schema3}
            onChange={rePrevious}
          >
            {({ values, setFieldValue }) => {
              const { settlment, settlmentValue, facillityValue, current3, relative2 } = values;
              return (
                <>
                  <FormFeedback textMap={{ title: t(COMMON_CORRECT), of: '/' }} />
                  <Heading level={2} accentBar>
                    {t('ICO.CREDIT.USING')}
                  </Heading>
                  <Fieldset name='settlment'
                    onChange={(next, { value, ...rest }) => settlmentHandle({ next, value, rest }, { setFieldValue })}>
                    <Label>
                      {t('ICO.CREDIT.SETTLEMENT')}
                    </Label>
                    <YesNoRadio textMap={{
                      yes: t(COMMON_YES),
                      no: t(COMMON_NO)
                    }} />
                    <FieldFeedback />
                  </Fieldset>
                  {settlment === 'yes' &&
                    <Reveal accentBar>
                      <Fieldset name="settlmentValue"
                        onChange={(next, { value, ...rest }) => settlmentValueHandle({ next, value, rest })}
                      >
                        <Legend>{t('ICO.CREDIT.PAYMENT')}</Legend>
                        {cardList.map((item, i) => (
                          <Radio value={item.value} key={i}> {item.label}</Radio>
                        )
                        )}
                        <FieldFeedback />
                      </Fieldset>
                      {settlmentValue &&
                        <Fieldset name="facillityValue"
                          onChange={(next, { value, ...rest }) => facillityValueHandle({ next, value, rest }, { setFieldValue })}
                        >
                          <Legend>{t('ICO.CREDIT.PAYMENT1')}</Legend>
                          {settlmentList.map((item, i) => (
                            <Radio value={item.value} key={i}> {item.label}</Radio>
                          )
                          )}
                          <FieldFeedback />
                        </Fieldset>
                      }
                      {facillityValue === BALANCE &&
                        <Field name="balanceValue"
                          onChange={(next, { value, ...rest }) => {
                            value = value.replace(/^0$/g, '');
                            next({ value, ...rest });
                          }}
                          onBlur={() => {
                            linkTagging({
                              'page_url': TAG_PAGE_URL,
                              'page_language': lang,
                              'event_category': 'content',
                              'event_action': 'textbox',
                              'event_content': `% of statement balance: ${values.balanceValue}%`,
                              'page_name': TAG_PAGE_NAME,
                              'raw_datalayer': '5745v48'
                            });
                          }}
                        >
                          <Label>{t('ICO.CREDIT.PAYMENT2')}</Label>
                          <CurrencyInput symbol="%" maxLength={3} />
                          <FieldFeedback />
                        </Field>
                      }
                    </Reveal>
                  }
                  <Fieldset name='facillity'
                    onChange={(next, { value, ...rest }) => {
                      linkTagging({
                        'page_url': TAG_PAGE_URL,
                        'page_language': lang,
                        'event_category': 'content',
                        'event_action': 'radio',
                        'event_content': `access bank account with credit card: ${value}`,
                        'page_name': TAG_PAGE_NAME,
                        'raw_datalayer': '5745v49'
                      });
                      next({ value, ...rest });
                    }}
                  >
                    <Label>
                      {t('ICO.CREDIT.FACILLITY')}
                    </Label>
                    <YesNoRadio textMap={{
                      yes: t(COMMON_YES),
                      no: t(COMMON_NO)
                    }} />
                    <FieldFeedback />
                  </Fieldset>
                  <Fieldset name='accounat'
                    onChange={(next, { value, ...rest }) => {
                      linkTagging({
                        'page_url': TAG_PAGE_URL,
                        'page_language': lang,
                        'event_category': 'content',
                        'event_action': 'radio',
                        'event_content': `over-the-limit opt-out facility: ${value}`,
                        'page_name': TAG_PAGE_NAME,
                        'raw_datalayer': '5745v50'
                      });
                      next({ value, ...rest });
                    }}
                  >
                    <Label>
                      {t('ICO.CREDIT.ACCOUNAT')}
                    </Label>
                    <YesNoRadio textMap={{
                      yes: t(COMMON_YES),
                      no: t(COMMON_NO)
                    }} />
                    <FieldFeedback />
                  </Fieldset>
                  <Heading level={3} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.STATEMENT_1') }}></Heading>
                  <Field name="statementPreferencesOptions">
                    <Label>{t('ICO.CREDIT.STATEMENT_2')}</Label>
                    {statementPreferencesList.map((item, i) => (
                      <Radio value={item.value} key={i}>
                        <span dangerouslySetInnerHTML={{ __html: item.label }}></span>
                        {item.value === '1' &&
                          <Paragraph hint style={{ 'marginTop': '10px', 'fontSize': '16px' }} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.STATEMENT_5') }}></Paragraph>
                        }
                      </Radio>
                    )
                    )}
                    <FieldFeedback />
                  </Field>
                  <Paragraph style={{ 'marginTop': '-20px', 'marginBottom': '25px' }}>
                    <span dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.STATEMENT_14') }}></span>
                    <Link
                      buttonPadding={false}
                      elementType="a"
                      style={lang === 'en' ? { 'position': 'relative', 'top': '-2px', 'left': '5px' } : { 'position': 'relative', 'top': '-1px' }}
                      external
                      href={t('ICO.CREDIT.STATEMENT_LINK')}
                      strong={false}
                      textMap={{
                        newWindow: ''
                      }}

                    >{t('ICO.CREDIT.STATEMENT_4')}
                    </Link>
                  </Paragraph>
                  <Paragraph style={{ 'fontSize': '16px' }} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.STATEMENT_7') }}></Paragraph>
                  <Paragraph style={{ 'fontSize': '16px' }}>{t('ICO.CREDIT.STATEMENT_15')}</Paragraph>
                  <Paragraph style={{ 'fontSize': '16px', 'marginBottom': '25px' }}>{t('ICO.CREDIT.STATEMENT_16')}</Paragraph>
                  <Heading level={3} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.STATEMENT_8') }}></Heading>
                  <Field name="grpTermsAndConditions">
                    <Label>{t('ICO.CREDIT.STATEMENT_9')}</Label>
                    {termsConditionsPreferencesList.map((item, i) => (
                      <Radio value={item.value} key={i}>
                        <span dangerouslySetInnerHTML={{ __html: item.label }}></span>
                      </Radio>)
                    )}
                    <FieldFeedback />
                  </Field>
                  <Paragraph hint style={{ 'fontSize': '16px' }} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.STATEMENT_10') }}></Paragraph>
                  <Paragraph hint style={{ 'marginBottom': '20px', 'fontSize': '16px' }} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.STATEMENT_11') }}></Paragraph>
                  <Paragraph hint style={{ 'fontSize': '16px' }} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.STATEMENT_12') }}></Paragraph>
                  <Paragraph hint style={{ 'fontSize': '16px' }} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.STATEMENT_13') }}></Paragraph>
                  <Heading level={2} accentBar>
                    {t('ICO.CREDIT.CURRENT4')}
                  </Heading>
                  <Fieldset name='current3'
                    onChange={(next, { value, ...rest }) => {
                      if (value === 'no') {
                        setFieldValue('relative', '');
                        setFieldValue('relative1', '');
                        setFieldValue('relative2', {});
                        setFieldValue('relative3', '');
                        setFieldValue('relative4', '');
                      }
                      next({ value, ...rest });
                    }}>
                    <Label>
                      {t('ICO.CREDIT.CURRENT5')}
                    </Label>
                    <Paragraph id="credit_child" hint>
                      {t('ICO.CREDIT.CURRENTCHILD')}
                    </Paragraph>
                    <YesNoRadio aria-describedby="credit_child" textMap={{
                      yes: t(COMMON_YES),
                      no: t(COMMON_NO)
                    }} />
                    <FieldFeedback />
                  </Fieldset>
                  {current3 === 'yes' &&
                    <>
                      <Fieldset
                        marginBottom={5}
                        name='relative2'
                      >
                        <Legend>
                          {t('ICO.CREDIT.RELATIVE2')}
                        </Legend>
                        <Field
                          marginBottom={0}
                          name="relative2.one"
                          onChange={(next, { value, ...rest }) => {
                            if (!value) {
                              setFieldValue('relative3', '');
                              setFieldValue('relative4', '');
                            }
                            next({ value, ...rest });
                          }}
                        >
                          <Checkbox>
                            {t('ICO.CREDIT.YOU')}
                          </Checkbox>
                        </Field>
                        <Field
                          marginBottom={0}
                          name="relative2.two"
                          onChange={(next, { value, ...rest }) => {
                            if (!value) {
                              setFieldValue('relative', '');
                              setFieldValue('relative1', '');
                            }
                            next({ value, ...rest });
                          }}
                        >
                          <Checkbox>
                            {t('ICO.CREDIT.TAS')}
                          </Checkbox>
                        </Field>
                        <FieldFeedback />
                      </Fieldset>
                      {relative2.one &&
                        <>
                          <Heading level={3}>{t('ICO.REVIEW.APP2')}</Heading>
                          <Field name="relative3"
                            onChange={(next, { value, ...rest }) => {
                              value = value.replace(/[^0-9]/g, '');
                              next({ value, ...rest });
                            }}
                          >
                            <Label>{t('ICO.CREDIT.RELATIVE3')}</Label>
                            <Paragraph id="credit_relative7" hint>
                              {t('ICO.CREDIT.RELATIVE7')}
                            </Paragraph>
                            <TextInput aria-describedby="credit_relative7" />
                            <FieldFeedback />
                          </Field>
                          <Field name="relative4"
                            onChange={(next, { value, ...rest }) => {
                              value = value.replace(/^0$/g, '');
                              next({ value, ...rest });
                            }}
                          >
                            <Label>{t('ICO.CREDIT.RELATIVE4')}</Label>
                            <Paragraph id="credit_relative18" hint>{t('ICO.CREDIT.RELATIVE18')}</Paragraph>
                            <CurrencyInput aria-describedby="credit_relative18" symbol={t('ICO.FINANCIAL.HKD1')} maxLength={7} />
                            <FieldFeedback />
                          </Field>
                          <Expander
                            marginBottom={2}
                            name="notesInfo"
                            startExpanded={false}
                            style={{ maxWidth: '394px' }}
                            textMap={{
                              closeButton: `${t('ICO.FINANCIAL.CLOSE')}`
                            }}
                            title={t('ICO.CREDIT.RELATIVE5')}
                          >
                            <Paragraph>
                              {t('ICO.CREDIT.RELATIVE6')}
                            </Paragraph>
                          </Expander>
                          <Hint style={{ paddingBottom: '10px' }}>
                            {t('ICO.CREDIT.RELATIVE19')}
                          </Hint>
                        </>
                      }
                      {relative2.two &&
                        <>
                          <Heading level={3}>{t('ICO.CREDIT.RELATIVE9')}</Heading>
                          <Field name="relative1">
                            <Label>{t('ICO.CREDIT.RELATIVE')}</Label>
                            <Paragraph id="credit_relative7" hint>
                              {t('ICO.CREDIT.RELATIVE7')}
                            </Paragraph>
                            <Select
                              aria-describedby="credit_relative7"
                              options={relative}
                              placeholder={t('ICO.COMMON.SELECT')}
                            />
                            <FieldFeedback />
                          </Field>
                          <Field name="relative">
                            <Label>{t('ICO.CREDIT.RELATIVE1')}</Label>
                            <TextInput />
                            <FieldFeedback />
                          </Field>
                          <Hint style={{ paddingBottom: '10px' }}>
                            {t('ICO.CREDIT.RELATIVE19')}
                          </Hint>
                        </>
                      }
                    </>
                  }
                  <Callout type="information">
                    <Paragraph>{t('ICO.CREDIT.RELATIVE13')}</Paragraph>
                    <Paragraph>
                      <Link
                        buttonPadding={false}
                        elementType="a"
                        external
                        href='https://www.hsbc.com.hk/content/dam/hsbc/hk/docs/general-notes/relationship-declaration.pdf'
                        strong={false}
                        textMap={{
                          newWindow: ''
                        }}>
                        {t('ICO.CREDIT.RELATIVE14')}
                      </Link>
                    </Paragraph>
                    <Paragraph>
                      {t('ICO.CREDIT.RELATIVE8')}
                    </Paragraph>
                  </Callout>
                  <Field name="agree" >
                    <Checkbox>
                      {t('ICO.CREDIT.RELATIVE15')}
                      <Link
                        buttonPadding={false}
                        elementType="a"
                        external
                        href={t('ICO.DECLARATION.PDF2')}
                        strong={false}
                        textMap={{
                          newWindow: ''
                        }}
                      >
                        &nbsp;{t('ICO.CREDIT.RELATIVE16')}
                      </Link>
                      {t('ICO.CREDIT.RELATIVE17')}
                    </Checkbox>
                    <FieldFeedback />
                  </Field>
                  <Navigation textMap={{ previous: t(COMMON_BACK), submit: t(COMMON_CONTINUE) }} />
                </>
              )
            }}
          </Form>
        </WizardPage>
      </Wizard>
    </FormLayout>
  );
}

export default CreditCard;
