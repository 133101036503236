import { throttle } from '../utils/util';
import linkTagging from './linkTagging';
const validationTagging = throttle(({ errors, taggingParams }) => {
  const field = []
  const parseValidations = (err) => {
    if (typeof err === 'string') {
      linkTagging({
        ...taggingParams,
        'event_subcategory': field.join('.'),
        'event_content': `field validation error: ${err}`
      });
      return;
    }

    for (const key in err) {
      field.push(key);
      parseValidations(err[key]);
      field.pop();
    }
  }

  parseValidations(errors);
}, 3000);

export default validationTagging;
