export default {
    address: {
        currentResidentialAddress: {
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            postalCode: '',
            addressCountry: '',
        },
        correspondenceAddress: [],
        correspondenceAddressDifferent: '',
        cartAddress: '',
        atmCountry: '',
        selectReasonsForOpeningAccount: '',
        otherReasonForOpeningAccountInAnotherCountryText: ''
    },
}
