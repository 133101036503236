import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { financialDetailsData } from '../../../referenceData/financialDetailsData/financialDetailsData';
import { Country } from 'referenceData';
import { useHistory } from 'react-router-dom';
import { getUserSessionDataByKey, setUserSessionDataByKey } from 'storage/userStorage';
import {
  Section,
  Heading,
  Expander,
  Paragraph,
  IconChevronDown,
  Reveal,
  HorizontalRule,
  Link,
  FormLayout,
} from '@piggybank/core';
import i18n from 'i18next';
import {
  Form,
  FormFeedback,
  Field,
  Label,
  AddAnother,
  Select,
  TextInput,
  FieldFeedback,
  Checkbox,
  CurrencyInput,
  Radio
} from '@piggybank/form';
import { WithI18nProgressIndicator } from 'components/WithI18nPiggybank';
import { ButtonWithBack } from 'components/ButtonWithBack';
import './financialDetails.css';
import { rePrevious } from 'utils/util';
import { validationTagging, useTagging, linkTagging } from 'tealiumTrack';

const CHARACTERS_AT_MOST = 'ICO.PERSONAL.CHARACTERS_AT_MOST';
const FINANCIAL_SELECTCOUNTRY = 'ICO.FINANCIAL.SELECTCOUNTRY';
const TAG_PAGE_URL = '/forms/financial-details';
const TAG_PAGE_NAME = 'pws:forms:financial details';
const COMMON_SELECT = 'ICO.COMMON.SELECT';
const COMMON_SPECIAL_ERROR = 'ICO.COMMON.SPECIAL_ERROR';
const CONST_SOLE_TRADER = 'Sole Trader';

const schema = yup.object().shape({
  employmentStatus: yup.string().required(() => i18n.t('ICO.FINANCIAL.SELEMPLOYMENTSTATUS')),
  employmentRole: yup.string().when('allDisplayStatus', {
    is: (val) => val,
    then: yup.string().required(() => i18n.t('ICO.FINANCIAL.SELEMPLOYMENTROLE')),
    otherwise: yup.string(),
  }),
  occupation: yup.string().when('allDisplayStatus', {
    is: (val) => val,
    then: yup.string().required(() => i18n.t('ICO.FINANCIAL.SELECTOCCUPATION')),
    otherwise: yup.string(),
  }),
  industry: yup.string().when('allDisplayStatus', {
    is: (val) => val,
    then: yup.string().required(() => i18n.t('ICO.FINANCIAL.SELINDUSTRY')),
    otherwise: yup.string(),
  }),
  employerName: yup.string().when('fullStatus', {
    is: (val) => val === 1,
    then: yup.string().max(70, ({ max }) => i18n.t(CHARACTERS_AT_MOST, { filedText: i18n.t('ICO.FINANCIAL.EMPLOYERSNAME'), max: max }))
      .required(() => i18n.t('ICO.FINANCIAL.SELEMPLOYERSNAME'))
      .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(COMMON_SPECIAL_ERROR)),
    otherwise: yup.string(),
  }),
  own: yup.string().when('fullStatus', {
    is: (val) => val === 3,
    then: yup.string().max(70, ({ max }) => i18n.t(CHARACTERS_AT_MOST, { filedText: i18n.t('ICO.FINANCIAL.MAXTIN4'), max: max }))
      .required(() => i18n.t('ICO.FINANCIAL.SELOWN'))
      .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(COMMON_SPECIAL_ERROR)),
    otherwise: yup.string(),
  }),
  addressCountry: yup.string().when('allDisplayStatus', {
    is: (val) => val,
    then: yup.string().required(() => i18n.t(FINANCIAL_SELECTCOUNTRY)),
    otherwise: yup.string(),
  }),
  gross: yup.string().when(['fullStatus', 'roleType'], {
    is: (fullStatus, roleType) => fullStatus !== 5 && fullStatus !== 3 && roleType && roleType !== CONST_SOLE_TRADER,
    then: yup.string().required(() => i18n.t('ICO.FINANCIAL.SELHKD'))
      .test('gross', () => i18n.t('ICO.FINANCIAL.AMOUNTERROR'),
        val => !val || val > 0
      ),
    otherwise: yup.string(),
  }),
  amount: yup.string().when(['fullStatus', 'roleType'], {
    is: (fullStatus, roleType) => fullStatus !== 5 && roleType && roleType !== 'Employee',
    then: yup.string().required(() => i18n.t('ICO.FINANCIAL.SELANNUAL')),
    otherwise: yup.string(),
  }),
  additionalNationalities: yup.array().of(
    yup.object().shape({
      countryOfTaxResidency: yup.string().required(() => i18n.t(FINANCIAL_SELECTCOUNTRY)),
      taxDocumentIdNumber: yup.string()
        .when("agree", {
          is: (val) => !val,
          then: yup
            .string().when("countryOfTaxResidency", {
              is: val => val === 'China',
              then: yup.string().required(() => i18n.t('ICO.FINANCIAL.SELENTERTAX2'))
                .matches(/^[A-Za-z0-9]{18}$/, () => i18n.t('ICO.FINANCIAL.SELENTERTAX2')),
              otherwise: yup.string().max(20, ({ max }) => i18n.t(CHARACTERS_AT_MOST, { filedText: i18n.t('ICO.FINANCIAL.MAXTIN2'), max: max }))
                .required(() => i18n.t('ICO.FINANCIAL.SELENTERTAX'))
                .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(COMMON_SPECIAL_ERROR))
            })

        }),
      reasonForNoTIN: yup.string().when(['agree', 'countryOfTaxResidency'], {
        is: (agree, countryOfTaxResidency) => agree && countryOfTaxResidency !== 'China',
        then: yup.string().required(() => i18n.t('ICO.FINANCIAL.TELL')),
        otherwise: yup.string(),
      }),
      explainForNotEligibleApply: yup
        .string()
        .when("reasonForNoTIN", {
          is: (val) => val === 'EXPLANATION2',
          then: yup
            .string()
            .max(300, ({ max }) => i18n.t(CHARACTERS_AT_MOST, { filedText: i18n.t('ICO.FINANCIAL.MAXTIN1'), max: max }))
            .required(() => i18n.t('ICO.FINANCIAL.SELARRLY'))
            .matches(/^[\u4300-\u9fa5A-Za-z0-9.\-/, ]*$/, () => i18n.t(COMMON_SPECIAL_ERROR))
        }),
    })
  ),
});

const allDisplayList = ['STUDENT', 'RETIRED', 'HOMEMAKER', 'UNEMPLOYED'];

const FinancialDetails = () => {
  const history = useHistory();
  const initialValues = getUserSessionDataByKey('financial');
  const { t } = useTranslation();
  const { employmentStatusList, employmentRoleList, occupation } = financialDetailsData[`${i18n.language}`];
  const countryList = Country[`${i18n.language}`];

  const selfEmploymentRoleList = employmentRoleList.filter((value) => {
    return value.value !== 'Employee';
  });

  const newEmploymentRoleList = employmentRoleList.filter((value) => {
    return value.value !== CONST_SOLE_TRADER;
  });

  const tinList = [
    {
      value: "REASON1",
      label: `${t('ICO.FINANCIAL.ISSUE')}`
    },

    {
      value: "REASON2",
      label: `${t('ICO.FINANCIAL.ALLOWTIN')}`
    },
    {
      value: "EXPLANATION1",
      label: `${t('ICO.FINANCIAL.WAITINGS')}`
    },
    {
      value: "EXPLANATION2",
      label: `${t('ICO.FINANCIAL.DONTTIN')}`
    },
  ];

  const goIndustry = (values) => {
    setUserSessionDataByKey('financial', values);
    history.push('/industryView');
  }

  const { isPremier, lang } = getUserSessionDataByKey('global');
  useTagging({
    'page_url': TAG_PAGE_URL,
    'page_language': lang,
    'page_name': TAG_PAGE_NAME,
    'page_subcategory': 'financial details',
    'raw_datalayer': '5745v14',
    'funnel_name': `account opening: ${isPremier ? 'premier' : 'one'}`,
    'funnel_step': '4',
    'funnel_step_name': 'financial details'
  });

  const taggingParams = {
    'page_url': TAG_PAGE_URL,
    'page_language': lang,
    'event_category': 'error',
    'event_action': 'field validation',
    'page_name': TAG_PAGE_NAME,
    'raw_datalayer': '5745v16'
  };

  function employmentStatusHandle({ next, value, rest }, { setFieldValue }) {
    setFieldValue('allDisplayStatus', !allDisplayList.includes(value));
    setFieldValue('fullStatus', 5);
    if (value === 'FULL_TIME_EMPLOYMENT' || value === 'PART_TIME_EMPLOYMENT') {
      setFieldValue('fullStatus', 1);
    } else if (value === 'SELF_EMPLOYED') {
      setFieldValue('fullStatus', 3);
    }
    setFieldValue('occupation', '');
    setFieldValue('employmentRole', '');
    setFieldValue('industry', '');
    setFieldValue('employerName', '');
    setFieldValue('own', '');
    setFieldValue('addressCountry', '');
    setFieldValue('gross', '');
    setFieldValue('average', '');
    setFieldValue('amount', '');

    linkTagging({
      'page_url': TAG_PAGE_URL,
      'page_language': lang,
      'event_category': 'content',
      'event_action': 'dropdown',
      'event_subcategory': 'employmentStatus',
      'event_content': `employment status: ${value}`,
      'page_name': TAG_PAGE_NAME,
      'raw_datalayer': '5745v15'
    });
    next({ value, ...rest });
  }

  function additionalNationalitiesAgreeHandle({ next, value, rest }, { values, index }){
    if (!value) {
      values.additionalNationalities[index].reasonForNoTIN = ''
      values.additionalNationalities[index].explainForNotEligibleApply = ''
    } else {
      values.additionalNationalities[index].taxDocumentIdNumber = ''
    }
    next({ value, ...rest });
  }

  function additionalNationalitiesReasonForNoTINHandle({ next, value, rest }, { values, index }){
    if (value !== 'EXPLANATION2') {
      values.additionalNationalities[index].explainForNotEligibleApply = ''
    }
    next({ value, ...rest });
  }

  return (
    <>
      <FormLayout>
        <WithI18nProgressIndicator current={3} />
        <Section>
          <Heading level={1}>{t('ICO.FINANCIAL.DETAILS')}</Heading>
          <Paragraph lead>{t('ICO.FINANCIAL.NEXT')}</Paragraph>
          <HorizontalRule />
        </Section>
        <Form
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={({ values }) => {
            setUserSessionDataByKey('financial', values);
            history.push('/info/using');
          }}
          onChange={rePrevious}
        >

          {({ values, setFieldValue, isSubmitting, isValid, errors }) => {
            const { fullStatus, allDisplayStatus, roleType } = values;
            if (isSubmitting && !isValid && JSON.stringify(errors) !== '{}') {
              validationTagging({ errors, taggingParams });
            }
            return (
              <>
                <FormFeedback textMap={{ title: t('ICO.COMMON.CORRECT'), of: '/' }} />
                <Heading level={2} accentBar>
                  {t('ICO.FINANCIAL.EMPLOYMENT')}
                </Heading>
                <Field name="employmentStatus"
                  onChange={(next, { value, ...rest }) => employmentStatusHandle({ next, value, rest }, { setFieldValue })}
                >
                  <Label>{t('ICO.FINANCIAL.EMPLOYMENTSTATUS')}</Label>
                  <Select
                    options={employmentStatusList}
                    placeholder={t(COMMON_SELECT)}
                  />
                  <FieldFeedback />
                </Field>
                {allDisplayStatus &&
                  <>
                    <Field name="employmentRole"
                      onChange={(next, { value, ...rest }) => {
                        setFieldValue('roleType', value);
                        next({ value, ...rest });
                      }}
                    >
                      <Label>{t('ICO.FINANCIAL.EMPLOYMENTROLE')}</Label>
                      <Select
                        options={fullStatus === 3 ? selfEmploymentRoleList : newEmploymentRoleList}
                        placeholder={t(COMMON_SELECT)}
                      />
                      <FieldFeedback />
                    </Field>
                    <Field name="occupation">
                      <Label>{t('ICO.FINANCIAL.OCCUPATION')}</Label>
                      <Select
                        options={occupation}
                        placeholder={t(COMMON_SELECT)}
                      />
                      <FieldFeedback />
                    </Field>
                    <Label>{t('ICO.FINANCIAL.INDUSTRY')}</Label>

                    <Field name="industry" onClick={() => goIndustry(values)} tabIndex="-1">
                      {values.industry ? (
                        <div className='industryView' >
                          <div className='industryCenter'>
                            <span
                              className='industryText'
                              aria-label={financialDetailsData[i18n.language].industryList[values.industry?.value]}
                            >{financialDetailsData[i18n.language].industryList[values.industry?.value]}</span>
                            <IconChevronDown />
                          </div>
                        </div>) :
                        <Select
                          options={[]}
                          placeholder={t(COMMON_SELECT)}
                        />
                      }
                      <FieldFeedback />
                    </Field>
                    {fullStatus === 1 &&
                      <>
                        <Field name="employerName">
                          <Label>{t('ICO.FINANCIAL.EMPLOYERSNAME')}</Label>
                          <TextInput />
                          <FieldFeedback />
                        </Field>
                        <label>
                          {t('ICO.FINANCIAL.EMPLOYERSADDRESS')}
                        </label>
                      </>
                    }
                    {fullStatus === 3 &&
                      <>
                        <Field name="own">
                          <Label>{t('ICO.FINANCIAL.OWN')}</Label>
                          <TextInput />
                          <FieldFeedback />
                        </Field>
                        <label>
                          {t('ICO.FINANCIAL.YOURADDRESS')}
                        </label>
                      </>
                    }
                    <Field name="addressCountry">
                      <Label>{t('ICO.FINANCIAL.COUNTRY')}</Label>
                      <Select
                        options={countryList}
                        placeholder={t(FINANCIAL_SELECTCOUNTRY)}
                      />
                      <FieldFeedback />
                    </Field>
                    <Section>
                      {roleType && <Heading level={2} accentBar>
                        {t('ICO.FINANCIAL.INCOME')}
                      </Heading>}
                      {roleType && roleType !== CONST_SOLE_TRADER && fullStatus !== 3 &&
                        <Field name="gross"
                          onChange={(next, { value, ...rest }) => {
                            value = value.replace(/^0$/g, '');
                            next({ value, ...rest });
                          }}>
                          <Label>{t('ICO.FINANCIAL.HKD')}</Label>
                          <CurrencyInput symbol={t('ICO.FINANCIAL.HKD1')} maxLength={13} />
                          <FieldFeedback />
                        </Field>
                      }
                      {roleType && roleType !== 'Employee' &&
                        <Field name="amount"
                          onChange={(next, { value, ...rest }) => {
                            value = value.replace(/^0$/g, '');
                            next({ value, ...rest });
                          }}
                        >
                          <Label>{t('ICO.FINANCIAL.ANNUAL')}</Label>
                          <CurrencyInput
                            maxLength={17}
                            symbol={t('ICO.FINANCIAL.HKD1')}
                          />
                          <FieldFeedback />
                        </Field>
                      }
                    </Section>
                  </>
                }
                <Section>
                  <Heading level={2} accentBar>
                    {t('ICO.FINANCIAL.TAX')}
                  </Heading>
                  <Expander
                    marginBottom={2}
                    name="notesInfo"
                    startExpanded={false}
                    style={{ maxWidth: '394px' }}
                    textMap={{
                      closeButton: `${t('ICO.FINANCIAL.CLOSE')}`
                    }}
                    title={t('ICO.FINANCIAL.INTO')}
                  >
                    <Paragraph>
                      {t('ICO.FINANCIAL.NATIONAL')}
                      <Link
                        buttonPadding={false}
                        elementType="a"
                        external
                        href={t('ICO.FINANCIAL.LIKE2')}
                        strong={false}
                        textMap={{
                          newWindow: ''
                        }}
                        style={{ wordWrap: 'break-word' }}
                      >
                        &nbsp;{t('ICO.FINANCIAL.LIKE1')}</Link>
                    </Paragraph>
                  </Expander>
                  <AddAnother
                    name="additionalNationalities"
                    max={4}
                    min={1}
                    minOnMount={1}
                    itemInitialValue={{
                      countryOfTaxResidency: '',
                      taxDocumentIdNumber: '',
                      agree: '',
                      reasonForNoTIN: '',
                      explainForNotEligibleApply: '',
                    }}
                    textMap={{
                      addButton: `${t('ICO.FINANCIAL.ADD')}`,
                    }}
                    fullWidth
                    renderAddButtonText={() => t('ICO.FINANCIAL.ADD')}
                    renderRemoveButtonText={() => t('ICO.FINANCIAL.REMOVE')}
                  >
                    {({ path, index }) => (
                      <Section marginBottom={0}>
                        <Field name={`${path}.countryOfTaxResidency`}>
                          <Label>{t('ICO.FINANCIAL.PURPOSES')}</Label>
                          <Paragraph id="financial_jurisdictions" hint>{t('ICO.FINANCIAL.JURISDICTIONS')}</Paragraph>
                          <Select
                            aria-describedby="financial_jurisdictions"
                            options={countryList}
                            placeholder={t(FINANCIAL_SELECTCOUNTRY)}
                          />
                          <FieldFeedback />
                        </Field>
                        {
                          (!values.additionalNationalities[index].agree ||
                            values.additionalNationalities[index].countryOfTaxResidency === 'China') &&
                          <Field name={`${path}.taxDocumentIdNumber`}>
                            <Label>{t('ICO.FINANCIAL.ENTERTAX')}</Label>
                            <Paragraph id="financial_example" hint>{t('ICO.FINANCIAL.EXAMPLE')}</Paragraph>
                            <TextInput aria-describedby="financial_example" />
                            <FieldFeedback />
                          </Field>
                        }
                        {
                          values.additionalNationalities[index].agree &&
                          values.additionalNationalities[index].countryOfTaxResidency !== 'China' &&
                          <Field>
                            <Label>{t('ICO.FINANCIAL.ENTERTAX')}</Label>
                            <Paragraph hint>{t('ICO.FINANCIAL.EXAMPLE')}</Paragraph>
                            <TextInput disabled />
                          </Field>
                        }
                        {
                          values.additionalNationalities[index].countryOfTaxResidency !== 'China' &&
                          <Field
                            marginBottom={5}
                            name={`${path}.agree`}
                            onChange={(next, { value, ...rest }) => additionalNationalitiesAgreeHandle({ next, value, rest }, { values, index })}
                          >
                            <Checkbox>
                              {t('ICO.FINANCIAL.UNABLETIN')}
                            </Checkbox>
                            <FieldFeedback />
                          </Field>
                        }
                        {
                          values.additionalNationalities[index].agree &&
                          values.additionalNationalities[index].countryOfTaxResidency !== 'China' &&
                          <Reveal accentBar>
                            <Field name={`${path}.reasonForNoTIN`}
                              onChange={(next, { value, ...rest }) => additionalNationalitiesReasonForNoTINHandle({ next, value, rest }, { values, index })}
                            >
                              <Label>{t('ICO.FINANCIAL.TELL')}</Label>
                              {tinList.map((item, i) => (
                                <Radio value={item.value} key={i}>
                                  {item.label}
                                </Radio>
                              ))}
                              <FieldFeedback />
                            </Field>
                            {values.additionalNationalities[index].reasonForNoTIN === 'EXPLANATION2' &&
                              < Field name={`${path}.explainForNotEligibleApply`}>
                                <Label>{t('ICO.FINANCIAL.ARRLY')}</Label>
                                <TextInput />
                                <FieldFeedback />
                              </Field>
                            }
                          </Reveal>
                        }
                      </Section>
                    )}
                  </AddAnother>
                </Section>
                <ButtonWithBack />
              </>
            )
          }
          }
        </Form >
      </FormLayout >
    </>
  );
}
export default FinancialDetails;
